// ActivitiesComponent.jsx

import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AgriculturalIcon from '@mui/icons-material/Agriculture';
import FlightIcon from '@mui/icons-material/Flight';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import SpaIcon from '@mui/icons-material/Spa';
import { HorizontalActivityList, HorizontalActivityItem, SelectedHorizontalActivityItem } from './ActivitiesComponentStyle';

const activities = [
    { id: 'COSECHA_MECANICA', text: 'Cosecha Mecánica', icon: <AgriculturalIcon /> },
    { id: 'APLICACIONES_AEREAS', text: 'Aplicaciones Aéreas', icon: <FlightIcon /> },
    { id: 'HERBICIDAS', text: 'Herbicidas', icon: <LocalFloristIcon /> },
    { id: 'FERTILIZACION', text: 'Fertilización', icon: <SpaIcon /> },
    { id: 'SIEMBRA', text: 'Siembra', icon: <SpaIcon /> },
];

const colorMap = {
    'COSECHA_MECANICA': '#FFE0B2',
    'APLICACIONES_AEREAS': '#BBDEFB',
    'HERBICIDAS': '#C8E6C9',
    'FERTILIZACION': '#FFF9C4',
    'SIEMBRA': '#D7CCC8',
};

const ActivitiesComponent = ({ onSelectActivity, isSidebarOpen }) => {
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleListItemClick = (event, index, activity) => {
        setSelectedIndex(index);
        onSelectActivity(activity.id);
    };

    return (
        <HorizontalActivityList isSidebarOpen={isSidebarOpen}>
            {activities.map((activity, index) => (
                <React.Fragment key={activity.id}>
                    {index === selectedIndex ? (
                        <SelectedHorizontalActivityItem
                            button
                            selected={index === selectedIndex}
                            onClick={(event) => handleListItemClick(event, index, activity)}
                            bgColor={colorMap[activity.id]}
                        >
                            <ListItemIcon>{activity.icon}</ListItemIcon>
                            <ListItemText primary={activity.text} />
                        </SelectedHorizontalActivityItem>
                    ) : (
                        <HorizontalActivityItem
                            button
                            selected={index === selectedIndex}
                            onClick={(event) => handleListItemClick(event, index, activity)}
                            bgColor={colorMap[activity.id]}
                        >
                            <ListItemIcon>{activity.icon}</ListItemIcon>
                            <ListItemText primary={activity.text} />
                        </HorizontalActivityItem>
                    )}
                </React.Fragment>
            ))}
        </HorizontalActivityList>
    );
};

export default ActivitiesComponent;
