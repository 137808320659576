// DataCard.jsx

import React from 'react';
import {
    DataCardContainer,
    DataCardTitle,
    DataCardValue,
    MinMaxContainer,
    MinMaxValue,
} from './DataCardStyle';
import NoDataMessage from '../NoDataMessage/NoDataMessage';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const DataCard = ({ title, children, min, max }) => {
    const isNoData = children === 'No hay datos';

    return (
        <DataCardContainer>
            <DataCardTitle>{title}</DataCardTitle>
            <DataCardValue color={isNoData ? '#B0B0B0' : undefined}>
                {isNoData ? <NoDataMessage message={children} /> : children}
            </DataCardValue>
            {min !== undefined && max !== undefined && (
                <MinMaxContainer>
                    <div style={{ textAlign: 'center' }}>
                        <ArrowDownwardIcon style={{ color: 'green' }} />
                        <MinMaxValue color="green">
                            {min !== 'N/A' ? min.toFixed(2) : 'N/A'}
                        </MinMaxValue>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <ArrowUpwardIcon style={{ color: 'red' }} />
                        <MinMaxValue color="red">
                            {max !== 'N/A' ? max.toFixed(2) : 'N/A'}
                        </MinMaxValue>
                    </div>
                </MinMaxContainer>
            )}
        </DataCardContainer>
    );
};

export default DataCard;
