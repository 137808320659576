import { useEffect } from 'react';
import L from 'leaflet';

// Función para manejar el reintento de la carga de imágenes
const retryLoadImage = (img, imageUrl, maxAttempts = 3, attempt = 1) => {
    return new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = () => {
            if (attempt < maxAttempts) {
                console.warn(`Reintento ${attempt} para cargar la imagen: ${imageUrl}`);
                setTimeout(() => retryLoadImage(img, imageUrl, maxAttempts, attempt + 1).then(resolve).catch(reject), 1000);
            } else {
                reject(new Error(`No se pudo cargar la imagen después de ${maxAttempts} intentos`));
            }
        };
        img.src = imageUrl;
    });
};

export const addImageOverlay = (
    mapRef,
    imageUrls, // Puede ser un array o una cadena
    northWestCoords,
    southEastCoords,
    imageOverlayRef,
    setInitialBoundsSet,
    initialBoundsSet,
    boundsPalmas
) => {
    try {
        const mapInstance = mapRef.current;
        if (!mapInstance) {
            console.warn("addImageOverlay: No se encontró la referencia del mapa.");
            return;
        }

        // Asegurarse de que imageUrls sea un array, aunque solo haya una URL
        const urlsArray = Array.isArray(imageUrls) ? imageUrls : [imageUrls];

        if (!urlsArray.length) {
            console.warn("addImageOverlay: No se proporcionaron URLs de imagen.");
            return;
        }

        // Verificar si las coordenadas northWestCoords y southEastCoords son válidas, si no, usar boundsPalmas
        const validNorthWestCoords = northWestCoords && northWestCoords[0] !== undefined && northWestCoords[1] !== undefined;
        const validSouthEastCoords = southEastCoords && southEastCoords[0] !== undefined && southEastCoords[1] !== undefined;

        let bounds;

        if (validNorthWestCoords && validSouthEastCoords) {
            bounds = L.latLngBounds(
                L.latLng(northWestCoords[1], northWestCoords[0]),
                L.latLng(southEastCoords[1], southEastCoords[0])
            );
        } else if (boundsPalmas && boundsPalmas.northWest && boundsPalmas.southEast) {
            bounds = L.latLngBounds(
                L.latLng(boundsPalmas.northWest[1], boundsPalmas.northWest[0]),
                L.latLng(boundsPalmas.southEast[1], boundsPalmas.southEast[0])
            );
        } else {
            console.warn("addImageOverlay: No se pudieron obtener límites válidos ni de las coordenadas ni de boundsPalmas.");
            return;
        }

        if (!bounds.isValid()) {
            console.warn("addImageOverlay: Los límites no son válidos:", bounds);
            return;
        }

        // Procesar cada URL de imagen
        urlsArray.forEach((imageUrl, index) => {
            const img = new Image();
            img.crossOrigin = 'anonymous'; // Manejo de CORS

            retryLoadImage(img, imageUrl)
                .then(() => {
                    // Crear y añadir el ImageOverlay en Leaflet con la imagen cargada
                    const overlay = L.imageOverlay(imageUrl, bounds, {
                        opacity: 1,
                        interactive: false
                    }).addTo(mapInstance);

                    imageOverlayRef.current = overlay;
                    mapInstance.fitBounds(bounds);
                    setInitialBoundsSet(true);
                })
                .catch(error => {
                    console.error(`Error al cargar la imagen ${index + 1} después de varios intentos:`, error);
                });
        });
    } catch (error) {
        console.error('Error in addImageOverlay:', error);
    }
};
