import React, { useContext } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from './context/AuthContext';
import { AuthSecurityContext } from "./context/AuthSecurityContext";
import LoginPage from './views/Login/Login';
import RegisterPage from './views/Register/Register';
import Dashboard from './views/Dashboard/Dashboard';
import DashboardIndicators from "./views/DashboardIndicadores/DashboardIndicators";
import PasswordRecovery from './views/PasswordRecovery/PasswordRecovery';
import PasswordConfirmation from './views/Confirmations/PasswordConfirmation';
import PasswordSender from './views/Confirmations/PasswordSender';
import RegisterConfirmation from './views/Confirmations/RegisterConfirmation';
import RegisterSender from './views/Confirmations/RegisterSender';
import AdminPanel from './views/AdminPanel/AdminPanel';
import LayoutWithSidebar from "./components/LayoutWithSidebar/LayoutWithSidebar";
import Advertising from './views/Advertising/Advertising';
import Configuration from './views/Configuracion/Configuration';
import SecurityModule from './views/SecurityModule/SecurityModule';
import PermissionManagement from './views/SecurityModule/Components/PermissionControl/PermissionControl';
import LoginSecurity from "./views/SecurityModule/views/LoginSecurity/LoginSecurity";
import UserForm from './views/SecurityModule/Components/UserForm/UserForm';
import CompanyForm from './views/SecurityModule/Components/CompanyForm/CompanyForm';
import 'react-toastify/dist/ReactToastify.css';

const ProtectedRoute = ({ children }) => {
    const { isAuthenticated } = useContext(AuthContext);
    const location = useLocation();

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
};

const ProtectedSecurityRoute = ({ children }) => {
    const { isAuthenticated } = useContext(AuthSecurityContext);
    const location = useLocation();

    if (!isAuthenticated) {
        return <Navigate to="/security-login" state={{ from: location }} replace />;
    }

    return children;
};

const MainRoutes = () => {
    return (
        <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/registrar" element={<RegisterPage />} />
            <Route path="/passwordRecuperation" element={<PasswordRecovery />} />
            <Route path="/passwordRecuperationConfirmation" element={<PasswordConfirmation />} />
            <Route path="/passwordSender/:recipient" element={<PasswordSender />} />
            <Route path="/registerSender/:recipient" element={<RegisterSender />} />
            <Route path="/registerConfirmation/:recipient" element={<RegisterConfirmation />} />

            <Route path="/" element={<ProtectedRoute><LayoutWithSidebar><Advertising /></LayoutWithSidebar></ProtectedRoute>} />
            <Route path="/publicidad" element={<ProtectedRoute><LayoutWithSidebar><Advertising /></LayoutWithSidebar></ProtectedRoute>} />
            <Route path="/dashboard" element={<ProtectedRoute><LayoutWithSidebar><DashboardIndicators /></LayoutWithSidebar></ProtectedRoute>} />
            <Route path="/mapeo" element={<ProtectedRoute><LayoutWithSidebar><Dashboard /></LayoutWithSidebar></ProtectedRoute>} />
            <Route path="/adminPanel" element={<ProtectedRoute><LayoutWithSidebar><AdminPanel /></LayoutWithSidebar></ProtectedRoute>} />
            <Route path="/configuracion" element={<ProtectedRoute><LayoutWithSidebar><Configuration /></LayoutWithSidebar></ProtectedRoute>} />

            <Route path="/security-login" element={<LoginSecurity />} />

            <Route path="/security-module" element={<ProtectedSecurityRoute><SecurityModule /></ProtectedSecurityRoute>}>
                <Route index element={<Navigate to="/security-module/permisos" replace />} />
                <Route path="permisos" element={<PermissionManagement />} />
                <Route path="agregar-usuario" element={<UserForm />} />
                <Route path="agregar-empresa" element={<CompanyForm />} />
            </Route>
        </Routes>
    );
};

export default MainRoutes;
