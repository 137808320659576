import L from 'leaflet';

// Utilidad para crear una clave única a partir de las coordenadas
const createKeyFromCoordinates = (coordinates) => coordinates.flat(Infinity).join(',');

// Cache para almacenar las capas de polígonos ya creadas
const polygonLayerCache = new Map();

// Función genérica para crear una capa de polígono y utilizar el cache
const createPolygonLayer = (coordinates, color) => {
    const key = createKeyFromCoordinates(coordinates);

    if (polygonLayerCache.has(key)) {
        return polygonLayerCache.get(key);
    }

    // Convertir coordenadas a formato Leaflet [lat, lng]
    const positions = coordinates.map(coord => [coord[1], coord[0]]);
    const layer = L.polygon(positions, {
        color: color,
        weight: 3,
    });

    polygonLayerCache.set(key, layer);
    return layer;
};

// Función genérica para obtener capas de polígonos
export const getPolygonLayers = (dataArray, color) => {
    if (!Array.isArray(dataArray)) return [];

    return dataArray.map(item => {
        let coordinates = item.geometry?.coordinates[0];
        if (!coordinates && Array.isArray(item)) {
            coordinates = item;
        }
        if (!Array.isArray(coordinates)) return null;

        return createPolygonLayer(coordinates, color);
    }).filter(layer => layer !== null);
};

// Funciones específicas utilizando la función genérica
export const getBufferedLines = (bufferedLines) => getPolygonLayers(bufferedLines, 'purple');
export const getBufferedIntersections = (bufferedIntersections) => getPolygonLayers(bufferedIntersections, 'blue');
export const getShowIntersections = (areasSuperpuestas) => getPolygonLayers(areasSuperpuestas, 'red');
export const getNonIntersectedAreas = (nonIntersectedAreas) => getPolygonLayers(nonIntersectedAreas, 'yellow');

// Función para limpiar el cache si es necesario
export const clearBufferedLinesCache = () => {
    polygonLayerCache.clear();
};
