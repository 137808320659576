import React, { useState, useEffect } from 'react';
import { TextField, Button, IconButton, InputAdornment, Box, MenuItem, Select, FormControl, InputLabel, Dialog, DialogTitle, DialogContent, DialogActions, Typography, List, ListItem, ListItemText, ListItemButton, InputBase, Pagination } from '@mui/material';
import { Visibility, VisibilityOff, Lock, Search } from '@mui/icons-material';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { API_BASE_URL_SECURITY } from "../../../../utils/config";
import { toast } from 'react-toastify';

const generatePassword = () => uuidv4().slice(0, 12);

const UserForm = ({ isSidebarOpen }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState(generatePassword());
    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [email, setEmail] = useState('');
    const [empresaSeleccionada, setEmpresaSeleccionada] = useState('');
    const [empresas, setEmpresas] = useState([]);
    const [rolSeleccionado, setRolSeleccionado] = useState('');
    const [roles, setRoles] = useState([]);
    const [usuarios, setUsuarios] = useState([]);
    const [editingUserId, setEditingUserId] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogSuccess, setDialogSuccess] = useState(false);

    // Paginación y búsqueda
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);  // Usuarios por página
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchEmpresas = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL_SECURITY}empresas`);
                if (response.data.length === 0) {
                    toast.info("No se encontraron empresas");
                }
                setEmpresas(response.data);
            } catch (error) {
                toast.error("Error al obtener empresas");
            }
        };

        const fetchRoles = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL_SECURITY}roles`);
                setRoles(response.data);
            } catch (error) {
                toast.error("Error al obtener roles");
            }
        };

        const fetchUsuarios = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL_SECURITY}usuarios`);
                if (response.data.length === 0) {
                    toast.info("No se encontraron usuarios");
                }
                setUsuarios(response.data);
            } catch (error) {
                toast.error("Error al obtener usuarios");
            }
        };

        fetchEmpresas();
        fetchRoles();
        fetchUsuarios();
    }, []);

    const togglePasswordVisibility = () => setShowPassword(!showPassword);

    const handleGeneratePassword = () => {
        setPassword(generatePassword());
    };

    const handleSubmit = async () => {
        if (!nombre || !apellido || !email || !empresaSeleccionada || !rolSeleccionado) {
            toast.error("Todos los campos son obligatorios");
            return;
        }

        const usuarioData = { nombre, apellido, email, password, empresaId: empresaSeleccionada, idRol: rolSeleccionado, estatus: 1 };

        try {
            if (editingUserId) {
                await axios.put(`${API_BASE_URL_SECURITY}usuarios/${editingUserId}`, usuarioData);
                setDialogMessage("Usuario actualizado exitosamente");
            } else {
                await axios.post(`${API_BASE_URL_SECURITY}usuarios`, usuarioData);
                setDialogMessage("Usuario creado exitosamente");
            }
            setDialogSuccess(true);
            setDialogOpen(true);
            clearForm();
        } catch (error) {
            setDialogMessage("Error al procesar el usuario.");
            setDialogSuccess(false);
            setDialogOpen(true);
        }
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const clearForm = () => {
        setNombre('');
        setApellido('');
        setEmail('');
        setPassword(generatePassword());
        setEmpresaSeleccionada('');
        setRolSeleccionado('');
        setEditingUserId(null);
    };

    const handleEditClick = (usuario) => {
        setEditingUserId(usuario._id);
        setNombre(usuario.NOMBRE);
        setApellido(usuario.APELLIDO);
        setEmail(usuario.EMAIL);
        setEmpresaSeleccionada(usuario.EMPRESA_ID);
        setRolSeleccionado(usuario.ID_Rol);
        setPassword('');
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reiniciar a la primera página
    };

    // Filtrar usuarios basado en la búsqueda
    const filteredUsuarios = usuarios.filter(usuario =>
        usuario.NOMBRE.toLowerCase().includes(searchQuery.toLowerCase()) ||
        usuario.APELLIDO.toLowerCase().includes(searchQuery.toLowerCase()) ||
        usuario.EMAIL.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Obtener los usuarios de la página actual
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentUsuarios = filteredUsuarios.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <Box sx={{ display: 'flex', gap: '24px', padding: '24px', marginTop: '25px', marginLeft: isSidebarOpen ? '250px' : '160px', transition: 'margin-left 0.3s ease-in-out' }}>
            {/* Formulario */}
            <Box sx={{ flex: 1 }}>
                <Typography variant="h4" mb={2}>{editingUserId ? 'Editar Usuario' : 'Agregar Usuario'}</Typography>

                <TextField fullWidth label="Nombre" margin="normal" variant="outlined" value={nombre} onChange={(e) => setNombre(e.target.value)} required />
                <TextField fullWidth label="Apellido" margin="normal" variant="outlined" value={apellido} onChange={(e) => setApellido(e.target.value)} required />
                <TextField fullWidth label="Correo" margin="normal" variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)} type="email" required />

                <FormControl fullWidth margin="normal">
                    <InputLabel>Seleccionar Empresa</InputLabel>
                    <Select value={empresaSeleccionada} onChange={(e) => setEmpresaSeleccionada(e.target.value)} required>
                        {empresas.map((empresa) => (
                            <MenuItem key={empresa._id} value={empresa._id}>{empresa.nombre}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <InputLabel>Seleccionar Rol</InputLabel>
                    <Select value={rolSeleccionado} onChange={(e) => setRolSeleccionado(e.target.value)} required>
                        {roles.map((rol) => (
                            <MenuItem key={rol._id} value={rol._id}>{rol.nombre}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    fullWidth
                    label="Contraseña"
                    margin="normal"
                    variant="outlined"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Lock />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={togglePasswordVisibility} edge="end">
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                <Button variant="contained" sx={{ backgroundColor: '#95A5A6', color: '#fff', marginTop: '16px' }} onClick={handleGeneratePassword} startIcon={<Lock />}>
                    Generar Contraseña
                </Button>

                <Button variant="contained" sx={{ backgroundColor: '#2C3E50', color: '#fff', marginTop: '16px', width: '100%', padding: '12px' }} onClick={handleSubmit}>
                    {editingUserId ? 'Actualizar Usuario' : 'Crear Usuario'}
                </Button>

                {editingUserId && (
                    <Button variant="outlined" sx={{ marginTop: '16px', width: '100%', padding: '12px' }} onClick={clearForm}>
                        Cancelar Edición
                    </Button>
                )}
            </Box>

            {/* Lista de Usuarios */}
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', paddingBottom: '16px' }}>
                    <InputBase
                        placeholder="Buscar usuario..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        sx={{ flex: 1, padding: '6px 12px', border: '1px solid #ccc', borderRadius: '4px' }}
                        startAdornment={<Search sx={{ marginRight: '8px' }} />}
                    />
                </Box>

                <List sx={{ height: '300px', overflowY: 'auto', border: '1px solid #ccc', borderRadius: '8px' }}>
                    {currentUsuarios.map((usuario) => (
                        <ListItem key={usuario._id} disablePadding>
                            <ListItemButton onClick={() => handleEditClick(usuario)}>
                                <ListItemText primary={`${usuario.NOMBRE} ${usuario.APELLIDO}`} secondary={usuario.EMAIL} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>

                {/* Paginación */}
                <Pagination
                    count={Math.ceil(filteredUsuarios.length / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    sx={{ alignSelf: 'center' }}
                />
            </Box>

            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>{dialogSuccess ? "Éxito" : "Error"}</DialogTitle>
                <DialogContent><Typography variant="body1">{dialogMessage}</Typography></DialogContent>
                <DialogActions><Button onClick={handleDialogClose} color="primary">Cerrar</Button></DialogActions>
            </Dialog>
        </Box>
    );
};

export default UserForm;