import React, { useEffect, useState, useRef, useCallback } from 'react';
import L from 'leaflet';
import { useSocket } from '../../context/SocketContext';
import { API_BASE_PYTHON_SERVICE } from '../../utils/config';
import axios from 'axios';
import {
    polygon as turfPolygon,
    area as turfArea,
    union as turfUnion,
    difference as turfDifference,
    intersect as turfIntersect,
    buffer as turfBuffer,
    lineSplit as turfLineSplit,
    lineString as turfLineString,
    bbox as turfBbox,
    lineIntersect as turfLineIntersect,
    length as turfLength,
    nearestPointOnLine as turfNearestPointOnLine,
    pointToLineDistance as turfPointToLineDistance,
    distance as turfDistance,
    point as turfPoint,
    destination as turfDestination,
    bearing as turfBearing,
} from '@turf/turf';
import BarIndicator from "../../components/BarIndicator/BarIndicator";
import { v4 as uuidv4 } from 'uuid';
import CommonMap from '../../components/CommonMap/CommonMap';
import MapDialog from '../../components/MapDialog/MapDialog';
import FloatingToolsAerialApplications
    from "../../components/FloatingToolsAerialApplications/FloatingToolsAerialApplications";
import { Snackbar, Alert} from '@mui/material';

const AerialApplications = ({
                                idAnalisis,
                                tipoAnalisis,
                                onAreasCalculated,
                                onPromediosCalculated,
                                activarEdicionInteractiva,
                                highlightedLote,
                                activeLotes,
                                polygonsData,
                                onSelectLote,
                                onLeaveLote,
                                onHoverLote,
                                isFilterDialogOpen,
                                closeFilterDialog,
                                setImgLaflet,
                                mapRef,
                                polygonsLayerRef,
                                setSelectedIndicator,
                                setLabelsAndColors,
                                setOnAreasCalculatedLoader
                            }) => {

    const userData = JSON.parse(localStorage.getItem("userData"));

    const [polygons, setPolygons] = useState([]);
    const [areasSuperpuestas, setAreasSuperpuestas] = useState([]);
    const [mapCenter, setMapCenter] = useState([0, 0]);
    const [zoom, setZoom] = useState(3);
    const [activeFilter, setActiveFilter] = useState(null);
    const [speedFilterActivated, setSpeedFilterActivated] = useState(false);
    const [altitudeFilterActivated, setAltitudeFilterActivated] = useState(false);
    const [realDoseFilterActivated, setRealDoseFilterActivated] = useState(false);
    const [isMapCreated, setIsMapCreated] = useState(new Date());
    const [nonIntersectedAreas, setNonIntersectedAreas] = useState([]);
    const [polygonsProperties, setPolygonsProperties] = useState([]);
    const [intersectionsKey, setIntersectionsKey] = useState(Date.now());
    const [showIntersections, setShowIntersections] = useState(true);
    const [overAppliedArea, setOverAppliedArea] = useState(0);
    const [appliedArea, setAppliedArea] = useState(0);
    const [lines, setLines] = useState([]);
    const [lineasNoFiltradas, setLineasNoFiltradas] = useState([]);
    const [onClickLinea, setOnClickLinea] = useState(false);
    const [onClickCuteLine, setOnClickCuteLine] = useState(false);
    const [onClickDrawLine, setOnClickDrawLine] = useState(false);
    const [onClickLineaStrech, setOnClickLineaStrech] = useState(false);

    const [bufferedLines, setBufferedLines] = useState([]);
    const [formData, setFormData] = useState({ idAnalisis });
    const [bufferedIntersections, setBufferedIntersections] = useState([]);
    const [kmlPolygons, setKmlPolygons] = useState([]);
    const [isKml, setIsKml] = useState(false);
    const [isDrawingLine, setIsDrawingLine] = useState(false);
    const [activeTool, setActiveTool] = useState(null);
    const [selectedLine, setSelectedLine] = useState(null);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [isFilteringLines, setIsFilteringLines] = useState(true);
    const [actionHistory, setActionHistory] = useState([]);
    const DISTANCE_THRESHOLD = 0.005;
    const ANGLE_THRESHOLD = 1;
    const [bufferValue, setBufferValue] = useState(0);
    const [isBufferActive, setIsBufferActive] = useState(false);
    const [stretchPoints, setStretchPoints] = useState([]);
    const [showUnfilteredLines, setShowUnfilteredLines] = useState(true);

    const workerRef = useRef(null);
    const activeToolRef = useRef(activeTool);

    const [filterValues, setFilterValues] = useState({
        VELOCIDAD: { low: 0, medium: 0, high: 0 },
        ALTURA: { low: 0, medium: 0, high: 0 },
        DOSISREAL: { low: 0, medium: 0, high: 0 }
    });

    const [availableFilters, setAvailableFilters] = useState({
        speed: false,
        altitude: false,
        realDose: false
    });

    const [filterSpeed, setFilterSpeed] = useState(false);
    const [filterAltitude, setFilterAltitude] = useState(false);
    const [filterRealDose, setFilterRealDose] = useState(false);

    const [lowSpeed, setLowSpeed] = useState(0);
    const [medSpeed, setMedSpeed] = useState(0);
    const [highSpeed, setHighSpeed] = useState(0);

    const [lowAltitude, setLowAltitude] = useState(0);
    const [medAltitude, setMedAltitude] = useState(0);
    const [highAltitude, setHighAltitude] = useState(0);

    const [lowRealDose, setLowRealDose] = useState(0);
    const [medRealDose, setMedRealDose] = useState(0);
    const [highRealDose, setHighRealDose] = useState(0);
    const timeoutRef = useRef(null);
    const [lastBufferedLines, setLastBufferedLines] = useState(bufferedLines);
    const socketContext = useSocket();
    const { socket, socketSessionID } = socketContext;
    const currentToolCleanupRef = useRef(null);

    // Nuevo estado para controlar si las áreas han sido calculadas
    const [areasCalculated, setAreasCalculated] = useState(false);

    const handleToggleFilter = (filterName) => {
        switch (filterName) {
            case 'VELOCIDAD':
                setFilterSpeed(prev => !prev);
                setActiveFilter('speed');
                break;
            case 'ALTURA':
                setFilterAltitude(prev => !prev);
                setActiveFilter('altura');
                break;
            case 'DOSISREAL':
                setFilterRealDose(prev => !prev);
                setActiveFilter('dosisReal');
                break;
            default:
                break;
        }
    };

    const deactivateTool = () => {
        if (currentToolCleanupRef.current) {
            currentToolCleanupRef.current();
            currentToolCleanupRef.current = null;
        }
    };


    const autoPanMap = (e) => {
        const map = mapRef.current;
        const containerPoint = map.latLngToContainerPoint(e.latlng);
        const containerSize = map.getSize();
        const edgeThreshold = 50;

        const panOffset = L.point(0, 0);

        if (containerPoint.x < edgeThreshold) {
            panOffset.x = -50;
        } else if (containerPoint.x > containerSize.x - edgeThreshold) {
            panOffset.x = 50;
        }

        if (containerPoint.y < edgeThreshold) {
            panOffset.y = -50;
        } else if (containerPoint.y > containerSize.y - edgeThreshold) {
            panOffset.y = 50;
        }

        if (panOffset.x !== 0 || panOffset.y !== 0) {
            map.panBy(panOffset);
        }
    };


    useEffect(() => {
        applyFilters();
    }, [filterSpeed, filterAltitude, filterRealDose]);

    const applyFilters = () => {
        const filtered = polygonsProperties.map(prop => {
            let color = 'green';
            if (filterSpeed) {
                const speed = prop.VELOCIDAD;
                if (speed < lowSpeed) {
                    color = 'green';
                } else if (speed >= lowSpeed && speed < medSpeed) {
                    color = 'yellow';
                } else if (speed >= medSpeed && speed <= highSpeed) {
                    color = 'orange';
                } else {
                    color = 'red';
                }
            }
            if (filterAltitude) {
                const altitude = prop.ALTURA;
                if (altitude < lowAltitude) {
                    color = 'green';
                } else if (altitude >= lowAltitude && altitude < medAltitude) {
                    color = 'yellow';
                } else if (altitude >= medAltitude && altitude <= highAltitude) {
                    color = 'orange';
                } else {
                    color = 'red';
                }
            }
            if (filterRealDose) {
                const realDose = prop.DOSISREAL;
                if (realDose < lowRealDose) {
                    color = 'green';
                } else if (realDose >= lowRealDose && realDose < medRealDose) {
                    color = 'yellow';
                } else if (realDose >= medRealDose && realDose <= highRealDose) {
                    color = 'orange';
                } else {
                    color = 'red';
                }
            }

            return { ...prop, color };
        });

        setPolygonsProperties(filtered);
    };

    useEffect(() => {
        workerRef.current = new Worker("Workers/dataWorker.js");

        workerRef.current.onmessage = (e) => {
            if (e.data.action === "geoJsonDataProcessed") {
                const createLatLngArray = (segment) => {
                    return segment.map((coords) => {
                        if (coords.length !== 2) {
                            return null;
                        }
                        const [lat, lng] = coords;
                        return { lat, lng };
                    }).filter(coord => coord !== null);
                };

                // Verifica si tenemos líneas filtradas
                if (e.data.data.filtradas && e.data.data.filtradas.lines) {
                    const { lines: filtradasLines, polygons: filtradasPolygons } = e.data.data.filtradas;
                    const filtradasLinesWithEvents = filtradasLines.map((line) => {
                        const lineId = line.id;
                        const latLngArray = createLatLngArray(line.paths);

                        if (latLngArray.length < 2) {
                            console.error("Segmento con menos de 2 puntos:", latLngArray);
                            return null;
                        }

                        const polyline = L.polyline(latLngArray, {
                            color: "red",
                        });

                        const bounds = L.latLngBounds(latLngArray);
                        polyline._bounds = bounds;

                        // Modificar para pasar el objeto completo de la línea, no solo las coordenadas
                        polyline.on("mouseover", (e) => handleLineHover(e, lineId));
                        polyline.on("mouseout", (e) => handleLineMouseOut(e, lineId));
                        polyline.on("click", (e) => handleLineClick(line, e)); // Pasa la línea completa

                        return { polyline, id: lineId, properties: line.properties };
                    }).filter((line) => line !== null);
                    setLines(filtradasLinesWithEvents);
                    setIsKml(true);
                    setKmlPolygons(filtradasPolygons);
                    setIsFilteringLines(true);
                }

                if (e.data.data.noFiltradas && e.data.data.noFiltradas.lines) {
                    const { lines: noFiltradasLines, polygons: noFiltradasPolygons } = e.data.data.noFiltradas;
                    const noFiltradasLinesWithEvents = noFiltradasLines.map((line) => {
                        const lineId = line.id;

                        const latLngArray = createLatLngArray(line.paths);

                        if (latLngArray.length < 2) {
                            console.error("Segmento con menos de 2 puntos:", latLngArray);
                            return null;
                        }

                        const polyline = L.polyline(latLngArray, {
                            color: "blue", // Azul para no filtradas
                        });

                        const bounds = L.latLngBounds(latLngArray);
                        polyline._bounds = bounds;

                        // Añade eventos a la polilínea
                        polyline.on("mouseover", (e) => handleLineHover(e, lineId));
                        polyline.on("mouseout", (e) => handleLineMouseOut(e, lineId));
                        polyline.on("click", (e) => handleLineClick(line, e));

                        return { polyline, id: lineId };
                    }).filter((line) => line !== null);
                    setLineasNoFiltradas(noFiltradasLinesWithEvents);
                    setIsKml(true);
                    setKmlPolygons(noFiltradasPolygons);
                }

                if (e.data.data.lines && !e.data.data.noFiltradas) {
                    const { lines, polygons } = e.data.data;

                    const linesWithEvents = lines.map((line) => {
                        const lineId = line.id;

                        const latLngArray = createLatLngArray(line.paths);

                        if (latLngArray.length < 2) {
                            console.error("Segmento con menos de 2 puntos:", latLngArray);
                            return null;
                        }

                        const polyline = L.polyline(latLngArray, {
                            color: "green",
                        });

                        const bounds = L.latLngBounds(latLngArray);
                        polyline._bounds = bounds;

                        polyline.on("mouseover", (e) => handleLineHover(e, lineId));
                        polyline.on("mouseout", (e) => handleLineMouseOut(e, lineId));
                        polyline.on("click", (e) => handleLineClick(line, e));

                        return { polyline, id: lineId };
                    }).filter((line) => line !== null);

                    setLines(linesWithEvents);
                    setIsKml(true);
                    setKmlPolygons(polygons);
                    setIsFilteringLines(false);
                }

                // Verifica si solo tenemos polígonos
                if (!e.data.data.lines && e.data.data.polygons) {
                    const { polygons } = e.data.data;
                    const formattedPolygons = polygons.map((poly) => formatPolygon(poly.polygon[0]));
                    setPolygonsProperties(polygons.map((poly) => poly.properties));
                    setPolygons(formattedPolygons);
                    setIsFilteringLines(false);
                }
            }
        };

        if (socket) {
            socket.on(`${socketSessionID}:updateGeoJSONLayer`, (geojsonData) => {
                workerRef.current.postMessage({
                    action: "processGeoJsonData",
                    geojsonData,
                    type: tipoAnalisis,
                    activarEdicionInteractiva,
                });
            });

            return () => {
                workerRef.current.terminate();
                socket.off(`${socketSessionID}:updateGeoJSONLayer`);
            };
        }

    }, [tipoAnalisis, socket]);

    useEffect(() => {
        if (workerRef.current) {
            workerRef.current.postMessage({ action: 'setActivarEdicionInteractiva', activarEdicionInteractiva });
        }
    }, [activarEdicionInteractiva]);

    useEffect(() => {
        if (isFilteringLines && lines.length > 0 && activarEdicionInteractiva) {

            setLines(lines);

            axios.post(`${API_BASE_PYTHON_SERVICE}mapping/guardar_lineas_filtradas`, {
                id_analisis: idAnalisis.data.ID_ANALISIS,
                lineas: lines
            })
                .then(response => {
                    console.log("GeoJSON guardado en:", response.data.url_firmada);
                })
                .catch(error => {
                    console.error("Error guardando el GeoJSON:", error);
                });
            setActiveFilter("aerialApplicationsTraslapeDrones");
            setIsFilteringLines(false);
        }
    }, [isFilteringLines, lines]);



    function removeSmallerIntersectingLines(lines) {
        const linesToRemove = new Set();

        for (let i = 0; i < lines.length; i++) {
            for (let j = i + 1; j < lines.length; j++) {
                const lineA = lines[i];
                const lineB = lines[j];

                const intersectPoints = turfLineIntersect(turfLineString(lineA.polyline._latlngs.map(coord => [coord.lng, coord.lat])), turfLineString(lineB.polyline._latlngs.map(coord => [coord.lng, coord.lat])));
                if (intersectPoints.features.length > 0) {
                    const lengthA = turfLength(turfLineString(lineA.polyline._latlngs.map(coord => [coord.lng, coord.lat])));
                    const lengthB = turfLength(turfLineString(lineB.polyline._latlngs.map(coord => [coord.lng, coord.lat])));
                    if (lengthA < lengthB) {
                        linesToRemove.add(lineA.id);
                    } else {
                        linesToRemove.add(lineB.id);
                    }
                }
            }
        }

        return lines.filter(line => !linesToRemove.has(line.id));
    }

// Generar GeoJSON para las líneas filtradas y no filtradas
    const generateGeoJSON = (lines, isUnfiltered = false) => {
        const features = lines.map(line => {
            // Determinar si estamos procesando líneas no filtradas
            const coordinates = isUnfiltered
                ? line.polyline._latlngs.map(coord => [coord.lng, coord.lat]) // para lineasNoFiltradas
                : line.polyline._latlngs.map(coord => [coord.lng, coord.lat]); // para lines

            return {
                type: 'Feature',
                properties: { id: line.id, length: line.length },
                geometry: {
                    type: 'LineString',
                    coordinates: coordinates
                }
            };
        });

        return {
            type: 'FeatureCollection',
            features: features
        };
    };

    const calculateLineAngle = (coord1, coord2) => {
        const dy = coord2.lat - coord1.lat;
        const dx = coord2.lng - coord1.lng;
        const radians = Math.atan2(dy, dx);
        const degrees = radians * (180 / Math.PI);
        return degrees < 0 ? degrees + 360 : degrees;
    };

    const clusterLinesByOrientation = (lines, angleThreshold = 1) => {
        const clusters = [];

        lines.forEach(line => {
            const coords = line.polyline._latlngs;
            if (coords.length < 2) return;

            const angle = calculateLineAngle(coords[0], coords[coords.length - 1]);
            let addedToCluster = false;

            for (const cluster of clusters) {
                const clusterAngle = cluster.averageAngle;
                if (Math.abs(clusterAngle - angle) < angleThreshold) {
                    cluster.lines.push(line);
                    cluster.averageAngle = (cluster.averageAngle * cluster.lines.length + angle) / (cluster.lines.length + 1);
                    addedToCluster = true;
                    break;
                }
            }

            if (!addedToCluster) {
                clusters.push({
                    lines: [line],
                    averageAngle: angle
                });
            }
        });

        return clusters;
    };


    const filterLargestClusters = (clusters, numClusters = 2) => {
        clusters.sort((a, b) => b.lines.length - a.lines.length);
        return clusters.slice(0, numClusters).flatMap(cluster => cluster.lines);
    };

    const filterConsistentPatterns = (lines) => {
        const clusters = clusterLinesByOrientation(lines);
        return filterLargestClusters(clusters);
    };

    const normalizeCoordinates = (coordinates) => {
        return coordinates.map(coord => {
            if (coord.lat !== undefined && coord.lng !== undefined) {
                return [coord.lng, coord.lat];
            }
            throw new Error("Invalid coordinates format in line data");
        });
    };

    const addBufferToLine = (line, width) => {
        try {
            const coordinates = normalizeCoordinates(line);

            const lineString = {
                type: "Feature",
                geometry: {
                    type: "LineString",
                    coordinates: coordinates
                }
            };

            const bufferWidth = width > 0 ? width : 1;

            const bufferedLine = turfBuffer(lineString, bufferWidth, { units: 'meters' });

            if (!bufferedLine || !bufferedLine.geometry || !Array.isArray(bufferedLine.geometry.coordinates) || bufferedLine.geometry.coordinates.length === 0) {
                throw new Error("Buffer result is invalid.");
            }

            return bufferedLine;
        } catch (error) {
            console.error("Error in addBufferToLine:", error);
            return null;
        }
    };

    useEffect(() => {
        if (lines) {
            const newBufferedLines = lines.map(line => {
                if (line.polyline && Array.isArray(line.polyline._latlngs) && line.polyline._latlngs.length > 0) {
                    const bufferedLine = addBufferToLine(line.polyline._latlngs, parseFloat(bufferValue));
                    if (bufferedLine) {
                        return bufferedLine;
                    } else {
                        console.warn("Buffer result is invalid for line: ", line);
                        return null;
                    }
                }
                console.error("Invalid line data: missing or incorrect polyline or latlngs");
                return null;
            }).filter(bufferedLine => bufferedLine !== null);

            setBufferedLines(newBufferedLines);
            setAreasCalculated(false); // Resetar el estado al generar nuevos bufferedLines
        }
    }, [bufferValue]);

    const handleToggleBuffer = () => {
        try {
            if (isBufferActive) {
                setBufferedLines([]);
                setBufferedIntersections([]);
                setAreasCalculated(false); // Mostrar la advertencia nuevamente
            } else {
                const newBufferedLines = lines.map(line => {
                    if (line.polyline && Array.isArray(line.polyline._latlngs) && line.polyline._latlngs.length > 0) {
                        const bufferedLine = addBufferToLine(line.polyline._latlngs, parseFloat(bufferValue));
                        if (bufferedLine) {
                            return bufferedLine;
                        } else {
                            console.warn("Resultado de buffer inválido para la línea: ", line);
                            return null;
                        }
                    }
                    console.error("Datos de línea inválidos: falta o incorrecto polyline o latlngs");
                    return null;
                }).filter(bufferedLine => bufferedLine !== null);
                setBufferedLines(newBufferedLines);
                setAreasCalculated(false); // Resetar el estado al generar nuevos bufferedLines
            }
            setIsBufferActive(!isBufferActive);
        } catch (error) {
            console.error("Error al aplicar buffer:", error);
        }
    };



    useEffect(() => {
        const adjustMapBounds = (entities, entityType) => {
            if (mapRef.current != null && entities.length > 0) {
                const map = mapRef.current;

                let validCoordinates = [];
                if (entityType === "POLYGONS") {
                    validCoordinates = entities.flatMap(polygon => {
                        if (Array.isArray(polygon) && polygon.length > 0) {
                            return polygon.map(coord => {
                                if (Array.isArray(coord) && coord.length === 2) {
                                    return L.latLng(coord[1], coord[0]);
                                }
                                console.error(`Invalid coordinate in polygon:`, coord);
                                return null;
                            }).filter(coord => coord !== null);
                        }
                        return [];
                    });
                } else {
                    validCoordinates = entities.flatMap(entity => {
                        if (entity.polyline && Array.isArray(entity.polyline._latlngs)) {
                            return entity.polyline._latlngs.flatMap(coord => {
                                if (coord.lat !== undefined && coord.lng !== undefined) {
                                    return [coord];
                                }
                                return Array.isArray(coord) ? coord : [];
                            });
                        }
                        return [];
                    });
                }

                if (validCoordinates.length > 0) {
                    const bounds = L.latLngBounds(validCoordinates);
                    map.fitBounds(bounds);
                    setTimeout(() => {
                        map.invalidateSize();
                    }, 100);
                }
            }
        };

        if (lines.length > 0 && !isFilteringLines) {
            lines.forEach(line => {
                if (line.polyline) {
                    line.polyline.off('mouseover mouseout click');
                    line.polyline.on('mouseover', (e) => handleLineHover(e, line.id));
                    line.polyline.on('mouseout', (e) => handleLineMouseOut(e, line.id));
                    line.polyline.on('click', (e) => handleLineClick(line, e));
                }
            });
        }

        if (isFirstLoad && !isFilteringLines) {
            adjustMapBounds(polygons, "POLYGONS");
            adjustMapBounds(lines, "LINES");
            setIsFirstLoad(false);
        }
    }, [polygons, lines, isFilteringLines]);

    useEffect(() => {
        if (mapRef.current && polygons.length > 0) {
            const latLngCoords = polygons.flatMap(polygon =>
                polygon.map(coordPair => [coordPair[1], coordPair[0]])
            );
            const mapBounds = L.latLngBounds(latLngCoords);
            if (mapBounds.isValid() || activeFilter) {
                setIntersectionsKey(Date.now());
                findIntersections(polygons);
            }
        }
    }, [polygons, lines, activeFilter, mapRef]);


    useEffect(() => {
        const checkAvailableFilters = () => {
            const hasSpeed = polygonsProperties.some(prop => prop.VELOCIDAD != null && prop.VELOCIDAD !== "");
            const hasAltitude = polygonsProperties.some(prop => prop.ALTURA != null && prop.ALTURA !== "");
            const hasRealDose = polygonsProperties.some(prop => prop.DOSISREAL != null && prop.DOSISREAL !== "");

            setAvailableFilters({
                speed: hasSpeed,
                altitude: hasAltitude,
                realDose: hasRealDose,
            });
        };

        checkAvailableFilters();
    }, [polygonsProperties]);

    const handleLineHover = useCallback((e, lineId) => {
        e.target.setStyle({
            color: 'cyan',
            weight: 5,
        });
        setSelectedLine(lineId);
    }, [setSelectedLine]);

    const handleLineMouseOut = (e, lineId) => {
        e.target.setStyle({
            color: 'red',
            weight: 2,
        });
        setSelectedLine(null);
    };

    const handleLineClick = (line, e) => {
        console.log("ESTE ES MI ACTIVE TOOL: ", activeToolRef.current);

        if (activeToolRef.current === 'delete') {
            console.log("ESTA ES LA LINEAS", line);

            // Verificar que line.polyline y getLatLngs existan y sean funciones
            if (line && line.polyline && typeof line.polyline.getLatLngs === 'function') {
                const clickedLatLngs = line.polyline.getLatLngs().map(coord => [coord.lng, coord.lat]);
                const lineString = turfLineString(clickedLatLngs);

                // Encuentra la línea en el estado y elimínala
                const lineInState = lines.find(l => {
                    if (l.polyline && typeof l.polyline.getLatLngs === 'function') {
                        const stateLatLngs = l.polyline.getLatLngs().map(coord => [coord.lng, coord.lat]);
                        return JSON.stringify(stateLatLngs) === JSON.stringify(clickedLatLngs);
                    }
                    return false;
                });

                if (lineInState) {
                    setLines(prevLines => prevLines.filter(l => l.id !== lineInState.id));
                    setActionHistory(prevHistory => [...prevHistory, { type: 'delete', line: lineInState }]);
                } else {
                    console.warn("No se encontró la línea en el estado para eliminar.");
                }
            } else {
                console.error("El objeto line no tiene una propiedad polyline válida.");
            }
        } else {
            // Resto del código para manejar otros casos cuando no está activo el modo 'delete'
            if (!line || !line.polyline) {
                console.error("Line object is invalid or missing polyline property", line);
                return;
            }

            // Verifica si el mapa está disponible antes de cualquier operación
            if (!mapRef.current) {
                console.error("Mapa no disponible al hacer clic en la línea");
                return;
            }

            // Verificar que la línea tenga propiedades
            const { properties } = line || {};

            let propertyList = '';
            if (properties && Object.keys(properties).length > 0) {
                propertyList = Object.entries(properties).map(([key, value]) => {
                    return `<li><strong>${key}:</strong> ${value ? value : 'N/A'}</li>`;
                }).join('');
            }

            const coordinates = line.polyline.getLatLngs().map(coord => [coord.lng, coord.lat]);
            const lineString = turfLineString(coordinates);
            const lengthKm = turfLength(lineString, { units: 'kilometers' });
            const lengthMiles = turfLength(lineString, { units: 'miles' });
            const lengthMeters = lengthKm * 1000;

            // Construcción del contenido del popup
            let popupContent = `
            <div>
                <strong>Tamaño línea aplicación:</strong>
                <ul>
                    <li>${lengthKm.toFixed(3)} km</li>
                    <li>${lengthMiles.toFixed(3)} mi</li>
                    <li>${lengthMeters.toFixed(3)} m</li>
                </ul>
            </div>
        `;

            if (propertyList) {
                popupContent = `
                <div>
                    <strong>Información de aplicación:</strong>
                    <ul>
                        ${propertyList}
                    </ul>
                    ${popupContent}
                </div>
            `;
            }

            const popup = L.popup()
                .setLatLng(e.latlng)
                .setContent(popupContent);

            mapRef.current.openPopup(popup);
        }
    };



    const unifyParallelLines = (lines, angleThreshold, distanceThreshold) => {
        // Verifica si las líneas están cerca
        const areLinesClose = (lineA, lineB, threshold) => {
            if (!lineA.polyline || !lineB.polyline) {
                console.error("Polyline is undefined in one of the lines", lineA, lineB);
                return false;  // Si no hay polilínea, no pueden estar cerca
            }

            for (const point of lineA.polyline._latlngs) {
                const nearest = turfNearestPointOnLine(
                    turfLineString(lineB.polyline._latlngs.map(coord => [coord.lng, coord.lat])),
                    turfPoint([point.lng, point.lat])
                );
                const distance = turfDistance(turfPoint([point.lng, point.lat]), nearest, { units: 'kilometers' });
                if (distance < threshold) {
                    return true;
                }
            }
            return false;
        };

        // Combina las coordenadas de las líneas
        const combineLines = (line1, line2) => {
            if (!line1.polyline || !line2.polyline) {
                console.error("Polyline is missing in one of the lines during combination", line1, line2);
                return null;
            }

            const combinedLatLngs = [...line1.polyline._latlngs, ...line2.polyline._latlngs].filter(
                (value, index, self) => index === self.findIndex((t) => (
                    t.lat === value.lat && t.lng === value.lng
                ))
            );
            const combinedProperties = combineProperties(line1.properties, line2.properties); // Combina las propiedades
            return { latlngs: combinedLatLngs, properties: combinedProperties };
        };

        // Combina las propiedades de dos líneas
        const combineProperties = (properties1, properties2) => {
            const combined = { ...properties1 };

            for (const key in properties2) {
                if (!combined[key] || combined[key] === null) {
                    combined[key] = properties2[key];
                }
            }

            return combined;
        };

        // Agrupa las líneas por orientación
        const clusters = lines.reduce((acc, line) => {
            if (!line.polyline || line.polyline._latlngs.length < 2) {
                console.warn("Invalid polyline data", line);
                return acc;
            }

            const coords = line.polyline._latlngs;
            const angle = calculateLineAngle(coords[0], coords[coords.length - 1]);
            let addedToCluster = false;

            for (const cluster of acc) {
                const clusterAngle = cluster.averageAngle;
                if (Math.abs(clusterAngle - angle) < angleThreshold) {
                    cluster.lines.push(line);
                    cluster.averageAngle = (cluster.averageAngle * cluster.lines.length + angle) / (cluster.lines.length + 1);
                    addedToCluster = true;
                    break;
                }
            }

            if (!addedToCluster) {
                acc.push({
                    lines: [line],
                    averageAngle: angle
                });
            }

            return acc;
        }, []);

        // Unifica las líneas dentro de cada cluster
        const unifiedLines = clusters.flatMap(cluster => {
            const lines = cluster.lines;
            const validLines = lines.map(line => turfLineString(line.polyline._latlngs.map(coord => [coord.lng, coord.lat])));
            const visited = new Array(validLines.length).fill(false);
            let unifiedLines = [];

            for (let i = 0; i < validLines.length; i++) {
                if (visited[i]) continue;
                let unifiedLine = lines[i];  // Mantiene las propiedades de la primera línea
                let maxLength = turfLength(validLines[i]);
                visited[i] = true;

                for (let j = i + 1; j < validLines.length; j++) {
                    if (visited[j]) continue;
                    if (areLinesClose(lines[i], lines[j], distanceThreshold)) {
                        const combinedResult = combineLines(unifiedLine, lines[j]);
                        if (combinedResult) {
                            unifiedLine.polyline._latlngs = combinedResult.latlngs;  // Combina las coordenadas
                            unifiedLine.properties = combinedResult.properties;  // Combina las propiedades
                        }
                        const lineLength = turfLength(validLines[j]);
                        if (lineLength > maxLength) {
                            maxLength = lineLength;
                        }
                        visited[j] = true;
                    }
                }

                // Crea una nueva polilínea con las coordenadas combinadas
                if (unifiedLine.polyline && unifiedLine.polyline._latlngs) {
                    const newPolyline = L.polyline(unifiedLine.polyline._latlngs, { color: 'red' });
                    newPolyline.on('mouseover', (e) => handleLineHover(e, unifiedLine.id));
                    newPolyline.on('mouseout', (e) => handleLineMouseOut(e, unifiedLine.id));
                    newPolyline.on('click', (event) => handleLineClick(unifiedLine, event));
                    unifiedLines.push({ polyline: newPolyline, id: unifiedLine.id, length: maxLength, properties: unifiedLine.properties });
                } else {
                    console.warn("Skipping line due to invalid polyline data", unifiedLine);
                }
            }

            return unifiedLines;
        });

        return unifiedLines;
    };


    useEffect(() => {
        if (activeFilter) {
            const newData = {
                ...formData,
                [activeFilter]: filterValues[activeFilter]
            };
            setFormData(newData);
        }
        localStorage.setItem('formData', JSON.stringify(formData));
    }, [filterValues, activeFilter, idAnalisis]);

    useEffect(() => {
        Promise.resolve(idAnalisis)
            .then(resolvedId => {
                if (resolvedId && resolvedId.data) {
                    const actualId = resolvedId.data.ID_ANALISIS;
                    setFormData(currentData => ({
                        ...currentData,
                        idAnalisis: actualId
                    }));
                } else {
                    // Maneja el caso cuando resolvedId es null o no tiene la propiedad data
                    console.error("resolvedId es null o no tiene la propiedad 'data'");
                }
            })
            .catch(error => {
                // Maneja cualquier error que ocurra durante la promesa
                console.error("Error al resolver idAnalisis:", error);
            });
    }, [idAnalisis]);

    const formatPolygon = (polygon) => {
        if (polygon.length >= 3) {
            if (polygon[0][0] !== polygon[polygon.length - 1][0] || polygon[0][1] !== polygon[polygon.length - 1][1]) {
                polygon.push([polygon[0][0], polygon[0][1]]);
            }
            return polygon.map(coordPair => [coordPair[1], coordPair[0]]);
        }
        return [];
    };


    const findIntersections = (polygons) => {
        let intersections = [];
        polygons.forEach((poly1, i) => {
            polygons.slice(i + 1).forEach(poly2 => {
                if (poly1.length > 0 && poly2.length > 0) {
                    const intersection = turfIntersect(turfPolygon([poly1]), turfPolygon([poly2]));
                    if (intersection) {
                        if (intersection.geometry.type === 'MultiPolygon') {
                            intersection.geometry.coordinates.forEach(coords => {
                                intersections.push(coords[0]);
                            });
                        } else if (intersection.geometry.type === 'Polygon') {
                            intersections.push(intersection.geometry.coordinates[0]);
                        }
                    }
                }
            });
        });
        setAreasSuperpuestas(intersections);
    };

    const calculateNonIntersectedAreas = (polygons) => {
        let nonIntersectedAreas = [];

        if (polygons.length > 1) {
            const unionOfAllPolygons = polygons.slice(1).reduce((acc, polygon) => {
                return turfUnion(acc, turfPolygon([polygon]));
            }, turfPolygon([polygons[0]]));

            polygons.forEach(polygon => {
                const difference = turfDifference(turfPolygon([polygon]), unionOfAllPolygons);
                if (difference) {
                    nonIntersectedAreas.push(difference.geometry.coordinates);
                }
            });
        } else {
            nonIntersectedAreas.push(polygons[0]);
        }

        return nonIntersectedAreas;
    };

    useEffect(() => {
        if (polygons.length > 0) {
            const newNonIntersectedAreas = calculateNonIntersectedAreas(polygons);
            setNonIntersectedAreas(newNonIntersectedAreas);
        }
    }, [polygons]);


    useEffect(() => {
        if (activeFilter) {
            setPolygons(polygons.map(polygon => [...polygon]));
        }
    }, [activeFilter, filterValues]);

    const correctionFactor = 1.014;
    const correctionFactorIntersections = 1.98;

    useEffect(() => {
        if (polygons.length === 0 || polygonsData.length === 0) return;

        const calculateAreas = async () => {
            try {
                const requestData = {
                    bufferedLines: [],
                    polygons: polygons.map(polygon => ({
                        geometry: {
                            type: "Polygon",
                            coordinates: polygon
                        }
                    })),
                    polygonsData: polygonsData.map(polygonData => ({
                        type: polygonData.type,
                        geometry: polygonData.geometry,
                        properties: polygonData.properties
                    })),
                    id_analisis: idAnalisis.data.ID_ANALISIS
                };



                const response = await axios.post(`${API_BASE_PYTHON_SERVICE}mapping/calculate_areas`, requestData);
                setOnAreasCalculatedLoader(true);

                if (response.status === 200 && response.data) {
                    const { areaSobreAplicada, areaAplicada, areaNoAplicada, porcentajeDeVariacion, areaAplicadaEnCalles, areaAplicadaFueraDelLote, dosisTotalDiferencia,dosisTotalRealAplicada} = response.data.data;

                    if (onAreasCalculated) {
                        onAreasCalculated({
                            areaSobreAplicada,
                            areaAplicada,
                            areaNoAplicada,
                            porcentajeDeVariacion,
                            areaAplicadaEnCalles,
                            areaAplicadaFueraDelLote,
                            dosisTotalRealAplicada,
                            dosisTotalDiferencia
                        });
                    }
                } else {
                    console.error("Error al calcular áreas: Respuesta no válida del servidor");
                }

                // Log GeoJSON of polygonsData
                const geoJson = {
                    type: "FeatureCollection",
                    features: polygonsData.map(polygonData => ({
                        type: "Feature",
                        geometry: polygonData.geometry,
                        properties: polygonData.properties
                    }))
                };

            } catch (error) {
                console.error("Error al calcular áreas:", error);
            }
        };

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            calculateAreas();
        }, 500);

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [polygons, polygonsData, onAreasCalculated]);

    useEffect(() => {
        console.log("ESTOS SON LOS POLIGONOS: ", polygons);
        if (polygons.length === 0 || polygonsProperties.length === 0) return;
        const totalSpeed = polygonsProperties.reduce((acc, curr) => acc + (curr.VELOCIDAD || 0), 0);
        const totalAltitude = polygonsProperties.reduce((acc, curr) => acc + (curr.ALTURA || 0), 0);
        const totalRealDose = polygonsProperties.reduce((acc, curr) => acc + (curr.DOSISREAL || 0), 0);

        const promedioVelocidad = (totalSpeed / polygonsProperties.length).toFixed(3);
        const promedioAltura = (totalAltitude / polygonsProperties.length).toFixed(3);
        const promedioDosisReal = (totalRealDose / polygonsProperties.length).toFixed(3);

        if (onPromediosCalculated) {
            onPromediosCalculated({
                promedioVelocidad,
                promedioAltura,
                promedioDosisReal
            });
        }
    }, [polygons, polygonsProperties, onPromediosCalculated]);

    useEffect(() => {
        if (bufferedLines.length === 0 || polygonsData.length === 0) return;

        const bufferedLinesChanged = JSON.stringify(bufferedLines) !== JSON.stringify(lastBufferedLines);
        calculateBufferedIntersections(bufferedLines);

        if (!bufferedLinesChanged) return;

        const calculateAreas = async () => {
            try {
                const requestData = {
                    bufferedLines: bufferedLines.map(line => ({
                        geometry: line.geometry
                    })),
                    polygons: [],
                    polygonsData: polygonsData.map(polygonData => ({
                        type: polygonData.type,
                        geometry: polygonData.geometry,
                        properties: polygonData.properties
                    })),
                    id_analisis: idAnalisis.data.ID_ANALISIS
                };


                const response = await axios.post(`${API_BASE_PYTHON_SERVICE}mapping/calculate_areas`, requestData);
                setOnAreasCalculatedLoader(true);

                if (response.status === 200 && response.data) {
                    const { areaSobreAplicada, areaAplicada, areaNoAplicada, porcentajeDeVariacion, areaAplicadaEnCalles, areaAplicadaFueraDelLote, dosisTotalDiferencia,dosisTotalRealAplicada} = response.data.data;

                    if (onAreasCalculated) {
                        onAreasCalculated({
                            areaSobreAplicada,
                            areaAplicada,
                            areaNoAplicada,
                            porcentajeDeVariacion,
                            areaAplicadaEnCalles,
                            areaAplicadaFueraDelLote,
                            dosisTotalRealAplicada,
                            dosisTotalDiferencia
                        });
                    }

                    // Actualizar el estado para cerrar el pop-up
                    setAreasCalculated(true);
                } else {
                    console.error("Error al calcular áreas: Respuesta no válida del servidor");
                }
            } catch (error) {
                console.error("Error al calcular áreas:", error);
            }
        };

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            setOnAreasCalculatedLoader(false);
            calculateAreas();
            setLastBufferedLines(bufferedLines);
        }, 2000);

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [bufferedLines, polygonsData, lastBufferedLines, onAreasCalculated]);

    const isValidPolygon = ({ type, coordinates }) =>
        type === 'Polygon' &&
        Array.isArray(coordinates) &&
        coordinates.length > 0 &&
        coordinates.every(
            ring =>
                Array.isArray(ring) &&
                ring.length >= 4 &&
                ring.every(point => Array.isArray(point) && point.length === 2)
        );


    const areValidGeometries = (buffer1, buffer2) => {
        if (!buffer1?.geometry || !buffer2?.geometry) return false;
        const geom1 = buffer1.geometry;
        const geom2 = buffer2.geometry;

        if (
            !isValidPolygon(geom1) ||
            !isValidPolygon(geom2) ||
            JSON.stringify(geom1.coordinates) === JSON.stringify(geom2.coordinates)
        ) return false;

        return true;
    };

    const calculateBufferedIntersections = (bufferedLines) => {
        const intersections = [];

        bufferedLines.forEach((buffer1, i) => {
            bufferedLines.slice(i + 1).forEach(buffer2 => {
                if (areValidGeometries(buffer1, buffer2)) {
                    try {
                        const intersection = turfIntersect(buffer1, buffer2);
                        if (intersection && isValidPolygon(intersection.geometry)) {
                            const coords = intersection.geometry.coordinates;
                            if (intersection.geometry.type === 'MultiPolygon') {
                                coords.forEach(coord => intersections.push(coord[0]));
                            } else {
                                intersections.push(coords[0]);
                            }
                        }
                    } catch (error) {
                    }
                }
            });
        });
        setBufferedIntersections(intersections);

        const totalIntersectionArea = intersections.reduce((acc, intersection) =>
            acc + turfArea(turfPolygon([intersection])) / 10000, 0);

        return totalIntersectionArea;
    };


    useEffect(() => {
        if (isDrawingLine && mapRef.current) {
            const map = mapRef.current;
            const drawnLine = [];

            const onClickMap = (e) => {
                drawnLine.push([e.latlng.lat, e.latlng.lng]);
                if (drawnLine.length > 1) {
                    setIsFirstLoad(false);
                    setLines([...lines, drawnLine]);
                    setIsDrawingLine(false);
                    map.off('click', onClickMap);
                }
            };

            map.on('click', onClickMap);

            return () => {
                map.off('click', onClickMap);
            };
        }
    }, [isDrawingLine, lines]);

    const handleUndo = () => {
        if (actionHistory.length > 0) {
            const lastAction = actionHistory[actionHistory.length - 1];
            let newLines = [...lines];

            switch (lastAction.type) {
                case 'cut':
                    newLines = lastAction.originalLines;
                    break;
                case 'draw':
                    newLines = newLines.filter(line => line.id !== lastAction.line.id);
                    break;
                case 'delete':
                    newLines = [...newLines, lastAction.line];
                    break;
                default:
                    break;
            }
            if (activeTool === 'delete') {
                handleDeleteLine();
            }

            setLines(newLines);
            setActionHistory(actionHistory.slice(0, -1));
        }
    };

    const handleCutLine = () => {
        deactivateTool();
        setActiveTool('cut');
        setIsFirstLoad(false);

        if (mapRef.current && lines.length > 0) {
            const map = mapRef.current;
            let previewLine = [];
            let previewLayer = null;
            let isCutting = false; // Estado para controlar si estamos en medio de un corte

            const onMove = (e) => {
                if (!isCutting) return; // No hacer nada si no estamos cortando


                if (previewLine.length === 1) {
                    previewLine[1] = [e.latlng.lat, e.latlng.lng];
                } else {
                    previewLine[1] = [e.latlng.lat, e.latlng.lng];
                }

                if (previewLayer) {
                    previewLayer.setLatLngs(previewLine);
                } else {
                    previewLayer = L.polyline(previewLine, { color: 'blue', dashArray: '5, 10' }).addTo(map);
                }
                autoPanMap(e);
            };

            const onCutClick = (e) => {

                if (isCutting) {
                    // Completa el corte
                    if (previewLine.length === 2) {
                        const cutLineString = turfLineString(previewLine.map(coord => [coord[1], coord[0]]));

                        const newLines = [];
                        let cutSuccessful = false;
                        const originalLines = [...lines];

                        lines.forEach(line => {
                            const latlngs = line.polyline?._latlngs;
                            if (!latlngs || latlngs.length < 2) {
                                newLines.push(line);
                                return;
                            }

                            const lineString = turfLineString(latlngs.map(coord => [coord.lng, coord.lat]));
                            const intersections = turfLineIntersect(lineString, cutLineString);

                            if (intersections.features.length > 0) {
                                cutSuccessful = true;
                                const splitResult = turfLineSplit(lineString, cutLineString);

                                splitResult.features.forEach(f => {
                                    const newLineCoords = f.geometry.coordinates.map(coord => new L.LatLng(coord[1], coord[0]));
                                    const polyline = L.polyline(newLineCoords, { color: 'red' }).addTo(map);

                                    const lineObj = { polyline, id: uuidv4(), _latlngs: newLineCoords }; // Añade _latlngs si lo usas en otras partes
                                    polyline.on('mouseover', handleLineHover);
                                    polyline.on('mouseout', handleLineMouseOut);
                                    polyline.on('click', (event) => handleLineClick(lineObj, event));

                                    newLines.push(lineObj);
                                });

                            } else {
                                newLines.push(line);
                            }
                        });

                        if (cutSuccessful) {
                            setLines(newLines);
                            setActionHistory([...actionHistory, { type: 'cut', originalLines }]);
                        }

                        map.off('mousemove', onMove);
                        map.off('click', onCutClick);
                        map.removeLayer(previewLayer);

                        isCutting = false; // Terminar el corte
                        setOnClickCuteLine(true);
                    }
                } else {
                    // Inicia un nuevo corte
                    isCutting = true;
                    if (previewLayer) {
                        map.removeLayer(previewLayer);
                    }

                    previewLine = [[e.latlng.lat, e.latlng.lng]];

                    previewLayer = L.polyline(previewLine, { color: 'blue', dashArray: '5, 10' }).addTo(map);

                    map.on('mousemove', onMove); // Register move event
                }
            };

            map.off('click');
            map.on('click', onCutClick); // Register click event
        } else {
            console.warn("handleCutLine: No map reference or lines available.");
        }
    };


    const handleDrawLine = () => {
        deactivateTool();
        setActiveTool('draw');
        setIsFirstLoad(false);

        if (mapRef.current && mapRef.current._loaded) {  // Verificación adicional
            const map = mapRef.current;
            let newLine = [];
            let polyline = L.polyline([], { color: 'red', pane: 'overlayPane' }).addTo(map);

            const onMove = (e) => {
                if (newLine.length > 0) {
                    const currentLine = [...newLine, { lat: e.latlng.lat, lng: e.latlng.lng }];
                    polyline.setLatLngs(currentLine);
                }
                autoPanMap(e);
            };

            const onClick = (e) => {
                newLine.push({ lat: e.latlng.lat, lng: e.latlng.lng });
                polyline.addLatLng(e.latlng);
            };

            const onRightClick = () => {
                if (newLine.length < 2) {
                    console.error("La línea debe tener al menos dos puntos");
                    map.off('click', onClick);
                    map.off('mousemove', onMove);
                    map.off('contextmenu', onRightClick);
                    map.removeLayer(polyline);
                    return;
                }

                polyline.on('mouseover', handleLineHover);
                polyline.on('mouseout', handleLineMouseOut);
                const lineId = uuidv4();
                const lineObj = { polyline: polyline, _latlngs: newLine, id: lineId };

                polyline.on('click', (event) => handleLineClick(lineObj, event));

                setLines([...lines, lineObj]);
                setActionHistory([...actionHistory, {
                    type: 'draw',
                    line: lineObj
                }]);

                map.off('click', onClick);
                map.off('mousemove', onMove);
                map.off('contextmenu', onRightClick);
                setOnClickDrawLine(true);
            };

            map.on('click', onClick);
            map.on('mousemove', onMove);
            map.once('contextmenu', onRightClick);
        } else {
            console.error("Map not ready or reference is null");
        }
    };

// Definir onLineClick fuera de handleDeleteLine para mantener el nombre
    const onLineClick = (e) => {
        const clickedLine = e.target;
        const clickedLatLngs = clickedLine.getLatLngs();

        const lineInState = lines.find(line => {
            if (!line.polyline || !line.polyline._latlngs) {
                return false;
            }
            const stateLatLngs = line.polyline.getLatLngs();
            const isMatch = clickedLatLngs.every((latLng, index) => {
                const coord = stateLatLngs[index];
                const match = coord && coord.lat === latLng.lat && coord.lng === latLng.lng;
                return match;
            });
            return isMatch;
        });

        if (lineInState) {
            setLines(prevLines => {
                const newLines = prevLines.filter(line => line.id !== lineInState.id);
                return newLines;
            });
            setActionHistory(prevActionHistory => {
                const newActionHistory = [...prevActionHistory, { type: 'delete', line: lineInState }];
                return newActionHistory;
            });
            setOnClickLinea(true);
            reassignLineClickListeners(mapRef.current, onLineClick);
        } else {
        }
    };


    useEffect(() => {
        if (onClickLinea) {
            handleDeleteLine();
            setOnClickLinea(false);
        }
    }, [onClickLinea]);


    useEffect(() => {
        if (onClickDrawLine) {
            handleDrawLine();
            setOnClickDrawLine(false);
        }
    }, [onClickDrawLine]);

    useEffect(() => {
        if (onClickCuteLine) {
            handleCutLine();
            setOnClickCuteLine(false);
        }
    }, [onClickCuteLine]);

    useEffect(() => {
        activeToolRef.current = activeTool;
    }, [activeTool]);

// Función para reasignar los eventos de clic a todas las líneas
    const reassignLineClickListeners = (map, onLineClick) => {
        // Eliminar los listeners de clic anteriores
        map.eachLayer(layer => {
            if (layer instanceof L.Polyline) {
                layer.off('click');
                layer.on('click', onLineClick);
            }
        });

        // Agregar nuevos listeners de clic
        lines.forEach(line => {
            if (line.polyline && line.polyline._latlngs) {
                line.polyline.off('click');
                line.polyline.on('click', onLineClick);
            }
        });
    };

    const handleDeleteLine = () => {
        deactivateTool();
        setActiveTool('delete');
        if (mapRef.current) {
            const map = mapRef.current;
            lines.forEach(line => {
                if (line.polyline) {
                    line.polyline.off('click');
                    line.polyline.on('click', (e) => {
                        const clickedLayer = e.target;
                        const lineInState = lines.find(l => l.polyline && l.polyline._leaflet_id === clickedLayer._leaflet_id);
                        if (lineInState) {
                            // Eliminar línea del mapa
                            map.removeLayer(lineInState.polyline);

                            // Eliminar línea del estado
                            setLines(prevLines => prevLines.filter(l => l.id !== lineInState.id));

                            // Actualizar historial de acciones
                            setActionHistory(prevActions => [...prevActions, { type: 'delete', line: lineInState }]);
                        } else {
                            console.error("Line not found for layer", clickedLayer);
                        }
                    });
                }
            });
        }
    };




    useEffect(() => {
        if (onClickLineaStrech) {
            handleStretchLine();
        }

    }, [onClickLineaStrech]);

    const handleStretchLine = () => {
        setActiveTool('stretch');

        if (mapRef.current) {
            const map = mapRef.current;
            console.log("Map reference found");

            const onLineClick = (e) => {
                console.log("Line clicked", e);
                const clickedLine = e.target;
                const clickedLatLngs = clickedLine.getLatLngs();
                console.log("Clicked line LatLngs:", clickedLatLngs);

                // Limpiar puntos extremos anteriores
                stretchPoints.forEach(marker => {
                    console.log("Removing stretch point:", marker);
                    map.removeLayer(marker);
                });
                setStretchPoints([]);
                console.log("Previous stretch points removed");

                setSelectedLine(clickedLine);
                console.log("Selected line set");

                // Resaltar los extremos de la línea
                const startMarker = L.circleMarker(clickedLatLngs[0], {
                    color: 'blue',
                    radius: 5,
                    draggable: true
                }).addTo(map);
                const endMarker = L.circleMarker(clickedLatLngs[clickedLatLngs.length - 1], {
                    color: 'blue',
                    radius: 5,
                    draggable: true
                }).addTo(map);
                console.log("Start and end markers added to map:", startMarker, endMarker);
                setStretchPoints([startMarker, endMarker]);
                console.log("Stretch points set");

                const onDrag = (isStart, e) => {
                    console.log(`Dragging ${isStart ? 'start' : 'end'} point`, e);
                    const updatedLatLngs = [...clickedLatLngs];
                    const updatedPoint = { lat: e.target.getLatLng().lat, lng: e.target.getLatLng().lng };

                    if (isStart) {
                        updatedLatLngs[0] = updatedPoint;
                    } else {
                        updatedLatLngs[updatedLatLngs.length - 1] = updatedPoint;
                    }

                    clickedLine.setLatLngs(updatedLatLngs);
                    console.log("Clicked line LatLngs updated:", updatedLatLngs);
                };

                const onDragEnd = (isStart, e) => {
                    console.log(`Drag end detected for ${isStart ? 'start' : 'end'} point`, e);
                    map.dragging.enable(); // Habilitar interacción del mapa

                    const updatedLatLngs = [...clickedLatLngs];
                    const updatedPoint = { lat: e.target.getLatLng().lat, lng: e.target.getLatLng().lng };

                    if (isStart) {
                        updatedLatLngs[0] = updatedPoint;
                    } else {
                        updatedLatLngs[updatedLatLngs.length - 1] = updatedPoint;
                    }

                    clickedLine.setLatLngs(updatedLatLngs);
                    setLines(lines.map(line => {
                        if (line.polyline === clickedLine) {
                            console.log("Updating line in state", line);
                            return { ...line, polyline: clickedLine, _latlngs: clickedLine.getLatLngs() };
                        }
                        return line;
                    }));
                    console.log("Lines state updated");
                };

                const enableDragging = () => {
                    console.log("Dragging enabled");
                    map.dragging.enable();
                };

                startMarker.on('drag', (e) => onDrag(true, e));
                endMarker.on('drag', (e) => onDrag(false, e));
                startMarker.on('dragstart', () => {
                    console.log("Start marker drag started");
                    map.dragging.disable(); // Deshabilitar interacción del mapa
                });
                endMarker.on('dragstart', () => {
                    console.log("End marker drag started");
                    map.dragging.disable(); // Deshabilitar interacción del mapa
                });
                startMarker.on('dragend', (e) => {
                    onDragEnd(true, e);
                    enableDragging();
                });
                endMarker.on('dragend', (e) => {
                    onDragEnd(false, e);
                    enableDragging();
                });

                console.log("Drag listeners added to markers");
            };

            // Eliminar los listeners de clic anteriores
            map.eachLayer(layer => {
                if (layer instanceof L.Polyline) {
                    console.log("Removing previous click listener from polyline layer", layer);
                    layer.off('click');
                    layer.on('click', onLineClick);
                }
            });

            // Agregar nuevos listeners de clic
            lines.forEach(line => {
                if (line.polyline && line.polyline._latlngs) {
                    console.log("Adding click listener to line", line);
                    line.polyline.off('click');
                    line.polyline.on('click', onLineClick);
                }
            });
        } else {
            console.error("Map reference not found");
        }
    };
    const calculateExtremePoints = (coordinates) => {
        let maxDistance = 0;
        let extremePoints = [coordinates[0], coordinates[1]];

        for (let i = 0; i < coordinates.length; i++) {
            for (let j = i + 1; j < coordinates.length; j++) {
                const point1 = turfPoint(coordinates[i]);
                const point2 = turfPoint(coordinates[j]);
                const distance = turfDistance(point1, point2, { units: 'meters' });

                if (distance > maxDistance) {
                    maxDistance = distance;
                    extremePoints = [coordinates[i], coordinates[j]];
                }
            }
        }

        return extremePoints;
    };

    const calculateTotalLength = (coordinates) => {
        const [startPoint, endPoint] = calculateExtremePoints(coordinates);
        return turfDistance(turfPoint(startPoint), turfPoint(endPoint), { units: 'meters' });
    };

    const calculateDominantOrientation = (coordinates) => {
        const [startPoint, endPoint] = calculateExtremePoints(coordinates);
        return turfBearing(turfPoint(startPoint), turfPoint(endPoint));
    };

    const generateCompleteLine = (line) => {
        const coordinates = line.polyline._latlngs.map(coord => [coord.lng, coord.lat]);
        const totalLength = calculateTotalLength(coordinates);
        const dominantOrientation = calculateDominantOrientation(coordinates);

        const [startLng, startLat] = calculateExtremePoints(coordinates)[0];
        const destination = turfDestination(turfPoint([startLng, startLat]), totalLength / 1000, dominantOrientation, { units: 'kilometers' });

        const updatedLatLngs = [
            { lat: startLat, lng: startLng },
            { lat: destination.geometry.coordinates[1], lng: destination.geometry.coordinates[0] }
        ];

        const newPolyline = L.polyline(updatedLatLngs, line.polyline.options);

        newPolyline.on('mouseover', (e) => handleLineHover(e, line.id));
        newPolyline.on('mouseout', (e) => handleLineMouseOut(e, line.id));
        newPolyline.on('click', (e) => handleLineClick(line, e));

        return {
            ...line,
            polyline: newPolyline
        };
    };

    const areLinesParallel = (line1, line2, tolerance = 1) => {
        const coords1 = line1.map(coord => [coord.lng, coord.lat]);
        const coords2 = line2.map(coord => [coord.lng, coord.lat]);
        const bearing1 = turfBearing(turfPoint(coords1[0]), turfPoint(coords1[1]));
        const bearing2 = turfBearing(turfPoint(coords2[0]), turfPoint(coords2[1]));
        return Math.abs(bearing1 - bearing2) < tolerance || Math.abs(Math.abs(bearing1 - bearing2) - 180) < tolerance;
    };

    const isLineClose = (line1, line2, maxDistance) => {
        const coords1 = line1.map(coord => [coord.lng, coord.lat]);
        const coords2 = line2.map(coord => [coord.lng, coord.lat]);
        const lineString1 = turfLineString(coords1);
        for (let coord of coords2) {
            const point = turfPoint(coord);
            const distance = turfPointToLineDistance(point, lineString1, { units: 'kilometers' });
            if (distance > maxDistance) {
                return false;
            }
        }
        return true;
    };


    const handleLabelClick = useCallback((text, color) => {
        setLabelsAndColors({ text, color });
    }, [setLabelsAndColors]);

    const toggleUnfilteredLines = () => setShowUnfilteredLines(prevState => !prevState);

    useEffect(() => {
        activeToolRef.current = activeTool;
    }, [activeTool]);

    return (
        <>
            <CommonMap
                center={mapCenter}
                zoom={zoom}
                polygons={polygons}
                setPolygons={setPolygons}
                lines={lines}
                points={null}
                hullPolygon={null}
                nonIntersectedAreas={nonIntersectedAreas}
                bufferedLines={bufferedLines}
                bufferedIntersections={bufferedIntersections}
                onLineHover={handleLineHover}
                onLineMouseOut={handleLineMouseOut}
                onLineClick={handleLineClick}
                filterValues={filterValues}
                polygonProperties={polygonsProperties}
                showIntersections={showIntersections}
                mapRef={mapRef}
                userId={userData.userId}
                stretchPoints={stretchPoints}
                lineasNoFiltradas={showUnfilteredLines ? lineasNoFiltradas : []}
                highlightedLote={highlightedLote}
                polygonsData={polygonsData}
                activeLotes={activeLotes}
                onLeaveLote={onLeaveLote}
                onSelectLote={onSelectLote}
                onHoverLote={onHoverLote}
                areasSuperpuestas={areasSuperpuestas}
                setImgLaflet={setImgLaflet}
                polygonsLayerRef={polygonsLayerRef}
                setLines={setLines}
            />
            {isKml && (
                <FloatingToolsAerialApplications
                    handleCutLine={handleCutLine}
                    handleDrawLine={handleDrawLine}
                    handleDeleteLine={handleDeleteLine}
                    handleToggleBuffer={handleToggleBuffer}
                    handleUndo={handleUndo}
                    handleStretchLine={handleStretchLine}
                    activeTool={activeTool}
                    isBufferActive={isBufferActive}
                    bufferValue={bufferValue}
                    setBufferValue={setBufferValue}
                    showUnfilteredLines={showUnfilteredLines}
                    toggleUnfilteredLines={toggleUnfilteredLines}
                />
            )}

            {(polygons.length > 0 || lines.length > 0) && (
                <BarIndicator
                    filterType={activeFilter ? activeFilter : "aerialApplicationsTraslape"}
                    isHistory={false}
                    onLabelClick={handleLabelClick}
                    setLabelsAndColors={setLabelsAndColors}
                />
            )}

            <MapDialog
                isOpen={isFilterDialogOpen}
                onClose={closeFilterDialog}
                availableFilters={availableFilters}
                handleToggleFilter={handleToggleFilter}
                filterSpeed={filterSpeed}
                filterAltitude={filterAltitude}
                filterRealDose={filterRealDose}
                lowSpeed={lowSpeed}
                medSpeed={medSpeed}
                highSpeed={highSpeed}
                setLowSpeed={setLowSpeed}
                setMedSpeed={setMedSpeed}
                setHighSpeed={setHighSpeed}
                lowAltitude={lowAltitude}
                medAltitude={medAltitude}
                highAltitude={highAltitude}
                setLowAltitude={setLowAltitude}
                setMedAltitude={setMedAltitude}
                setHighAltitude={setHighAltitude}
                lowRealDose={lowRealDose}
                medRealDose={setMedRealDose}
                highRealDose={setHighRealDose}
            />

            {/* Snackbar de advertencia */}
            <Snackbar
                open={!areasCalculated && lines.length > 0}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert severity="warning" variant="filled" sx={{ width: '100%' }}>
                    <strong>Advertencia:</strong> Genera el buffer en las líneas para mostrar las áreas en el dashboard.
                </Alert>
            </Snackbar>
        </>
    );

};

export default AerialApplications;