import { styled } from '@mui/system';

const useStyles = styled((theme) => ({
    root: {
        display: 'flex',
        height: '100vh',
        backgroundColor: '#f4f6f8',
        transition: 'all 0.5s ease', // Transiciones suaves al abrir/cerrar el sidebar
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginTop: '60px', // Ajuste para el navbar
        marginLeft: ({ isSidebarOpen }) => (isSidebarOpen ? '250px' : '75px'), // Ajuste dinámico del sidebar
        transition: 'margin-left 0.5s ease, width 0.5s ease',
        width: ({ isSidebarOpen }) => (isSidebarOpen ? 'calc(100% - 250px)' : 'calc(100% - 75px)'), // Ajuste dinámico del ancho
        backgroundColor: '#fff',
        borderRadius: '8px', // Bordes redondeados
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Sombras suaves
    },
}));

export default useStyles;
