// src/analysis/cosechaMecanica/cosechaMecanicaDataCards.jsx
import React from 'react';
import DataCard from "../../components/CardData/DataCard";
import {displayValue} from "../../utils/generalUtils";

export const renderCosechaMecanicaDataCards = (validations, datosAnalisis, indicadores) => (
    <>
        {validations.nombreMaquina && <DataCard title="Equipo">{displayValue(datosAnalisis.nombreMaquina.valor)}</DataCard>}
        {validations.areaBrutaCm && <DataCard title="Área Bruta">{displayValue(datosAnalisis.areaBrutaCm.valor)}</DataCard>}
        {validations.horaInicio && <DataCard title="Hora Inicio (H)">{displayValue(datosAnalisis.horaInicio.valor)}</DataCard>}
        {validations.horaFin && <DataCard title="Hora Fin (H)">{displayValue(datosAnalisis.horaFin.valor)}</DataCard>}
        {validations.tiempoTotalActividad && <DataCard title="Tiempo total (H)">{displayValue(datosAnalisis.tiempoTotalActividad.valor)}</DataCard>}
        {validations.consumoCombustible && <DataCard title="Combustible Gal/H">{displayValue(datosAnalisis.consumoCombustible.valor)}</DataCard>}
        {validations.calidadGps && <DataCard title="Calidad GPS">{displayValue(datosAnalisis.calidadGps.valor)}</DataCard>}
        {validations.eficienciaCm && <DataCard title="Eficiencia Ha/Hora">{displayValue(datosAnalisis.eficienciaCm.valor)}</DataCard>}
        {validations.promedioVelocidad && <DataCard title="Velocidad Km/H">{displayValue(datosAnalisis.promedioVelocidad.valor)}</DataCard>}
        {validations.rpm && <DataCard title="RPM">{displayValue(datosAnalisis.rpm.valor)}</DataCard>}
        {validations.tch && <DataCard title="TCH">{displayValue(datosAnalisis.tch.valor)}</DataCard>}
        {validations.tah && <DataCard title="TAH">{displayValue(datosAnalisis.tah.valor)}</DataCard>}
        {validations.presionCortadorBase && <DataCard title="Presión Cortador Base (Bar)">{displayValue(datosAnalisis.presionCortadorBase.valor)}</DataCard>}
        {indicadores.porcentajeAreaPilotoCm && <DataCard title="Piloto Automático">{displayValue(indicadores.porcentajeAreaPilotoCm)}</DataCard>}
        {indicadores.porcentajeAreaAutoTrackerCm && <DataCard title="Auto Tracket">{displayValue(indicadores.porcentajeAreaAutoTrackerCm)}</DataCard>}
        {indicadores.porcentajeModoCortadorBaseCm && <DataCard title="Corte Base">{displayValue(indicadores.porcentajeModoCortadorBaseCm)}</DataCard>}
    </>
);
