// ChartCard.jsx

import React, { useState, useEffect } from 'react';
import { CardContent, Typography, IconButton } from '@mui/material';
import { ResponsiveLine } from '@nivo/line';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveBar } from '@nivo/bar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { StyledCard } from './ChartCardStyle';
import { BasicTooltip } from '@nivo/tooltip';
import NoDataMessage from '../NoDataMessage/NoDataMessage';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const ChartCard = ({
                       title,
                       data = [],
                       type = 'line',
                       color,
                       colors,
                       icon,
                       min,
                       max,
                       isPieChart = false,
                       size = 'full',
                   }) => {
    const [expanded, setExpanded] = useState(false);
    const [hasData, setHasData] = useState(true);

    useEffect(() => {
        if (
            !data ||
            data.length === 0 ||
            data.some((item) => item.value == null || item.name == null)
        ) {
            setHasData(false);
        } else {
            setHasData(true);
        }
    }, [data]);

    const handleToggleExpand = () => {
        setExpanded(!expanded);
    };

    const formatValue = (value) => Number(value).toFixed(2);

    const getMaxYValue = () => {
        if (data.length === 0) return 0;
        const maxValue = Math.max(...data.map((item) => item.value));
        return maxValue + maxValue * 0.3;
    };

    const nivoTheme = {
        tooltip: {
            container: {
                background: '#ffffff',
                color: '#333333',
                fontSize: '14px',
                borderRadius: '4px',
                boxShadow: '0 3px 9px rgba(0, 0, 0, 0.5)',
            },
        },
        axis: {
            ticks: {
                text: {
                    fontSize: '12px',
                    fill: '#555555',
                },
            },
            legend: {
                text: {
                    fontSize: '14px',
                    fill: '#333333',
                },
            },
        },
    };

    const renderChart = () => {
        if (!hasData) {
            return <NoDataMessage message="No hay datos" />;
        }

        switch (type) {
            case 'line':
                return (
                    <div
                        style={{
                            overflowX: 'auto',
                            height: '300px',
                            position: 'relative',
                        }}
                    >
                        <div
                            style={{
                                width: expanded ? '1500px' : '100%',
                                height: '100%',
                            }}
                        >
                            <ResponsiveLine
                                data={[
                                    {
                                        id: title,
                                        data: data.map((item) => ({
                                            x: item.name,
                                            y: formatValue(item.value),
                                        })),
                                    },
                                ]}
                                margin={{
                                    top: 20,
                                    right: 20,
                                    bottom: 100,
                                    left: 80,
                                }}
                                xScale={{ type: 'point' }}
                                yScale={{
                                    type: 'linear',
                                    min: 0,
                                    max: getMaxYValue(),
                                    stacked: false,
                                    reverse: false,
                                }}
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    orient: 'bottom',
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 45,
                                    legend: '',
                                    legendOffset: 36,
                                    legendPosition: 'middle',
                                }}
                                axisLeft={{
                                    orient: 'left',
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: '',
                                    legendOffset: -40,
                                    legendPosition: 'middle',
                                }}
                                enableArea={true}
                                areaOpacity={0.3}
                                pointSize={10}
                                pointColor={{ theme: 'background' }}
                                pointBorderWidth={2}
                                pointBorderColor={{ from: 'serieColor' }}
                                useMesh={true}
                                tooltip={({ point }) => (
                                    <BasicTooltip
                                        id={<strong>{point.data.xFormatted}</strong>}
                                        value={point.data.yFormatted}
                                        enableChip={true}
                                        color={point.color}
                                    />
                                )}
                                animate={true}
                                colors={color ? [color] : { scheme: 'category10' }}
                                theme={nivoTheme}
                            />
                        </div>
                    </div>
                );
            case 'bar':
                return (
                    <div style={{ height: '300px' }}>
                        <ResponsiveBar
                            data={data}
                            keys={['value']}
                            indexBy="name"
                            margin={{ top: 20, right: 20, bottom: 80, left: 80 }}
                            padding={0.3}
                            valueScale={{ type: 'linear' }}
                            indexScale={{ type: 'band', round: true }}
                            colors={color ? [color] : { scheme: 'category10' }}
                            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                orient: 'bottom',
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 45,
                                legend: '',
                                legendPosition: 'middle',
                                legendOffset: 32,
                            }}
                            axisLeft={{
                                orient: 'left',
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '',
                                legendPosition: 'middle',
                                legendOffset: -40,
                            }}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor={{
                                from: 'color',
                                modifiers: [['darker', 1.6]],
                            }}
                            animate={true}
                        />
                    </div>
                );
            case 'pie':
                return (
                    <div style={{ height: '300px' }}>
                        <ResponsivePie
                            data={data}
                            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                            innerRadius={0.5}
                            padAngle={0.7}
                            cornerRadius={3}
                            activeOuterRadiusOffset={8}
                            borderWidth={1}
                            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                            arcLinkLabelsSkipAngle={10}
                            arcLinkLabelsTextColor="#333333"
                            arcLinkLabelsThickness={2}
                            arcLinkLabelsColor={{ from: 'color' }}
                            arcLabelsSkipAngle={10}
                            arcLabelsTextColor={{
                                from: 'color',
                                modifiers: [['darker', 2]],
                            }}
                            animate={true}
                            colors={colors || { scheme: 'category10' }}
                            theme={nivoTheme}
                        />
                    </div>
                );
            default:
                return <NoDataMessage message="Tipo de gráfico no soportado" />;
        }
    };

    return (
        <StyledCard size={size}>
            <CardContent
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h6">{title}</Typography>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {type === 'line' && (
                            <IconButton onClick={handleToggleExpand}>
                                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                        )}
                        {icon && <span style={{ marginLeft: '8px' }}>{icon}</span>}
                    </div>
                </div>
                <div style={{ height: '300px', width: '100%' }}>{renderChart()}</div>
                {hasData && min !== undefined && max !== undefined && (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginTop: '10px',
                        }}
                    >
                        <div style={{ textAlign: 'center' }}>
                            <ArrowDownwardIcon style={{ color: 'green' }} />
                            <div style={{ fontSize: '12px', color: 'green' }}>
                                {min !== 'N/A' ? min.toFixed(2) : 'N/A'}
                            </div>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <ArrowUpwardIcon style={{ color: 'red' }} />
                            <div style={{ fontSize: '12px', color: 'red' }}>
                                {max !== 'N/A' ? max.toFixed(2) : 'N/A'}
                            </div>
                        </div>
                    </div>
                )}
            </CardContent>
        </StyledCard>
    );
};

export default ChartCard;
