// src/components/ErrorListDialog.js
import React from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemText,
    Typography
} from '@mui/material';

const ErrorListDialog = ({ open, onClose, errors, title, description }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>{title || 'Errors Found'}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {description || 'The following errors were encountered during processing. Please correct them and try again.'}
                </DialogContentText>
                <List>
                    {errors.map((error, index) => (
                        <ListItem key={index} alignItems="flex-start">
                            <ListItemText
                                primary={
                                    <Typography variant="body1" color="textPrimary">
                                        <strong>Fila {error.fila}, Columna {error.columna} ({error.campo}):</strong> {error.mensaje}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ErrorListDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(
        PropTypes.shape({
            fila: PropTypes.number.isRequired,
            columna: PropTypes.number.isRequired,
            campo: PropTypes.string.isRequired,
            mensaje: PropTypes.string.isRequired,
        })
    ).isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
};

export default ErrorListDialog;