import React from 'react';
import DataCard from "../../components/CardData/DataCard";
import { displayValue } from "../../utils/generalUtils";
import CircularProgress from '@mui/material/CircularProgress';

export const renderApsDataCards = (validations, datosAnalisis, indicadores, onAreasCalculatedLoader) => (
    <>
        {validations.codigoEquipo && (
            <DataCard title="Equipo">{displayValue(datosAnalisis.codigoEquipo?.valor)}</DataCard>
        )}
        {validations.eficiencia && (
            <DataCard title="Eficiencia">{displayValue(datosAnalisis.eficiencia?.valor)}</DataCard>
        )}
        {validations.codigoFinca && (
            <DataCard title="Código Finca">{displayValue(datosAnalisis.codigoFinca?.valor)}</DataCard>
        )}
        {validations.codigoLote && (
            <DataCard title="Código Lote">{displayValue(datosAnalisis.codigoLote?.valor)}</DataCard>
        )}
        {validations.dosisTeorica && (
            <DataCard title="Dosis Teórica">{displayValue(datosAnalisis.dosisTeorica?.valor)}</DataCard>
        )}
        {validations.productoAps && (
            <DataCard title="Producto">{displayValue(datosAnalisis.productoAps?.valor)}</DataCard>
        )}
        {validations.humedadDelCultivo && (
            <DataCard title="Humedad del Cultivo">{displayValue(datosAnalisis.humedadDelCultivo?.valor)}</DataCard>
        )}
        {validations.tchEstimado && (
            <DataCard title="TCH Estimado">{displayValue(datosAnalisis.tchEstimado?.valor)}</DataCard>
        )}
        {validations.tiempoTotal && (
            <DataCard title="Tiempo Total">{displayValue(datosAnalisis.tiempoTotal?.valor)} hrs</DataCard>
        )}

        {datosAnalisis.alturaAps !== undefined && datosAnalisis.alturaAps !== null && (
            <DataCard title="Altura">{displayValue(datosAnalisis.alturaAps?.valor)}</DataCard>
        )}

        {onAreasCalculatedLoader === false ? (
            <DataCard title="Área Sobre Aplicada">
                <CircularProgress />
            </DataCard>
        ) : (
            <DataCard title="Área Sobre Aplicada">
                {displayValue(indicadores.areaSobreAplicada)} ha
            </DataCard>
        )}

        {onAreasCalculatedLoader === false ? (
            <DataCard title="Área no aplicada">
                <CircularProgress />
            </DataCard>
        ) : (
            <DataCard title="Área no aplicada">
                {displayValue(indicadores.areaNoAplicada)} ha
            </DataCard>
        )}

        {onAreasCalculatedLoader === false ? (
            <DataCard title="Área Aplicada">
                <CircularProgress />
            </DataCard>
        ) : (
            <DataCard title="Área Aplicada">
                {displayValue(indicadores.areaAplicada)} ha
            </DataCard>
        )}

        {onAreasCalculatedLoader === false ? (
            <DataCard title="Área Fuera de Lote">
                <CircularProgress />
            </DataCard>
        ) : (
            <DataCard title="Área Fuera de Lote">
                {displayValue(indicadores.areaFueraDeLote)} ha
            </DataCard>
        )}

        {onAreasCalculatedLoader === false ? (
            <DataCard title="Área Sobre Calles">
                <CircularProgress />
            </DataCard>
        ) : (
            <DataCard title="Área Sobre Calles">
                {displayValue(indicadores.areaSobreCalles)} ha
            </DataCard>
        )}

        {onAreasCalculatedLoader === false ? (
            <DataCard title="Porcentaje de Variación">
                <CircularProgress />
            </DataCard>
        ) : (
            <DataCard title="Porcentaje de Variación">
                {displayValue(indicadores.porcentajeVariacion)}
            </DataCard>
        )}
        {onAreasCalculatedLoader  === false ? (
            <DataCard title="Dosis Total Real Aplicada">
                <CircularProgress />
            </DataCard>
        ) : (
            <DataCard title="Dosis Total Real Aplicada">
                {displayValue(indicadores.dosisTotalRealAplicada)} L/ha
            </DataCard>
        )}

        {datosAnalisis.dosisReal !== undefined && datosAnalisis.dosisReal !== null && datosAnalisis.dosisReal&& (
            <DataCard title="Volumen Total">{displayValue(datosAnalisis.dosisReal?.valor)}</DataCard>
        )}


        {onAreasCalculatedLoader  === false ? (
            <DataCard title="Diferencia Dosis Real y Teorica">
                <CircularProgress />
            </DataCard>
        ) : (
            <DataCard title="Diferencia Dosis Real y Teorica">
                {displayValue(indicadores.diferenciaDeAreaApsKml)} L/ha
            </DataCard>
        )}


        {(indicadores.promedioVelocidad !== undefined || datosAnalisis.velocidadAps !== undefined) && (indicadores.promedioVelocidad !== null || datosAnalisis.velocidadAps !== undefined) && (
            <DataCard title="Velocidad">{displayValue(indicadores.promedioVelocidad || datosAnalisis.velocidadAps?.valor)}</DataCard>
        )}
    </>
);
