// DashboardIndicatorsStyle.js

import { styled } from '@mui/system';

export const DashboardContainer = styled('div')(({ isSidebarOpen }) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    padding: '16px',
    transition: 'margin-left 0.3s',
    marginLeft: isSidebarOpen ? '110px' : '60px',
}));

export const ContentContainer = styled('div')(() => ({
    marginTop: '160px',
}));

export const LeftPanel = styled('div')({
    flex: '0 0 250px',
    marginRight: '16px',
});

export const RightPanel = styled('div')({
    flex: '1',
});

export const CardsContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '16px',
});

export const ChartCardContainer = styled('div')(() => ({
    flex: '1 1 calc(33.33% - 20px)',
    minWidth: '300px',
    margin: '10px',
    height: 'auto',
    overflow: 'visible',
}));

// Nuevo componente para el mensaje de sección en desarrollo
export const InProgressMessage = styled('div')(({ theme }) => ({
    color: '#B0B0B0', // Gris claro
    textAlign: 'center',
    marginTop: '100px',
    fontSize: '24px',
    fontWeight: '600',
    padding: '20px',
}));
