// src/context/AuthSecurityContext.js

import React, { createContext, useContext, useState, useEffect } from "react";

export const AuthSecurityContext = createContext();

export const useAuthSecurity = () => useContext(AuthSecurityContext);

export const AuthSecurityProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem("SecurityToken");
        let storedUserData = localStorage.getItem("securityUserData");
        if (token && storedUserData) {
            try {
                storedUserData = JSON.parse(storedUserData);
                setIsAuthenticated(true);
                setUserData(storedUserData);
            } catch (error) {
                console.error("Error parsing securityUserData from localStorage:", error);
                localStorage.removeItem("securityUserData");
                setIsAuthenticated(false);
                setUserData(null);
            }
        }
    }, []);

    const login = (userData) => {
        setIsAuthenticated(true);
       /* setUserData(userData);
        localStorage.setItem("SecurityToken", userData.token);
        localStorage.setItem("securityUserData", JSON.stringify(userData));*/
    };

    const logout = () => {
        setIsAuthenticated(false);
        setUserData(null);
        localStorage.removeItem("SecurityToken");
        localStorage.removeItem("securityUserData");
    };

    return (
        <AuthSecurityContext.Provider value={{ isAuthenticated, userData, login, logout }}>
            {children}
        </AuthSecurityContext.Provider>
    );
};
