// LeafletImage.jsx

import { queue } from 'd3-queue';
import L from 'leaflet';
import axios from 'axios';
import { API_BASE_PYTHON_SERVICE } from "../utils/config";

const cacheBusterDate = +new Date();

export function leafletImage2(map, callback) {
    const hasMapbox = !!L.mapbox;
    const dimensions = map.getSize();
    const layerQueue = queue(1); // Procesa una capa a la vez

    const mainCanvas = document.createElement('canvas');
    mainCanvas.width = dimensions.x;
    mainCanvas.height = dimensions.y;
    const mainCtx = mainCanvas.getContext('2d');

    const dummyCanvas = document.createElement('canvas');
    dummyCanvas.width = 1;
    dummyCanvas.height = 1;
    const dummyCtx = dummyCanvas.getContext('2d');
    dummyCtx.fillStyle = 'rgba(0,0,0,0)';
    dummyCtx.fillRect(0, 0, 1, 1);

    // Array para almacenar los datos de CircleMarkers
    const circleMarkersData = [];

    // Itera sobre cada capa del mapa y las maneja según su tipo
    map.eachLayer(drawTileLayer);
    map.eachLayer(drawEsriDynamicLayer);

    if (map._pathRoot) {
        layerQueue.defer(handlePathRoot, map._pathRoot);
    } else if (map._panes) {
        const firstCanvas = map._panes.overlayPane.getElementsByTagName('canvas').item(0);
        if (firstCanvas) { layerQueue.defer(handlePathRoot, firstCanvas); }
    }

    map.eachLayer(drawMarkerLayer);
    map.eachLayer(drawPolygonLayer);
    map.eachLayer(drawPolylineLayer);
    map.eachLayer(collectCircleMarkerLayer); // Recolectar CircleMarkers
    map.eachLayer(drawImageOverlay); // Manejo de ImageOverlay

    // Después de recolectar todos los CircleMarkers, procesarlos
    if (circleMarkersData.length > 0) {
        layerQueue.defer(handleCircleMarkersBatch);
    }

    layerQueue.awaitAll(layersDone);

    // Funciones para dibujar diferentes tipos de capas
    function drawTileLayer(layer) {
        if (layer instanceof L.TileLayer) layerQueue.defer(handleTileLayer, layer);
        else if (layer._heat) layerQueue.defer(handlePathRoot, layer._canvas);
    }

    function drawMarkerLayer(layer) {
        if (layer instanceof L.Marker && layer.options.icon instanceof L.Icon) {
            layerQueue.defer(handleMarkerLayer, layer);
        }
    }

    function drawEsriDynamicLayer(layer) {
        if (!L.esri) return;
        if (layer instanceof L.esri.DynamicMapLayer) {
            layerQueue.defer(handleEsriDynamicLayer, layer);
        }
    }

    function drawPolygonLayer(layer) {
        if (layer instanceof L.Polygon && !(layer instanceof L.Rectangle)) {
            layerQueue.defer(handlePolygonLayer, layer);
        }
    }

    function drawPolylineLayer(layer) {
        if (layer instanceof L.Polyline && !(layer instanceof L.Polygon)) {
            layerQueue.defer(handlePolylineLayer, layer);
        }
    }

    function collectCircleMarkerLayer(layer) {
        if (layer instanceof L.CircleMarker) {
            const mapBounds = map.getBounds();
            const markerLatLng = layer.getLatLng();

            // Verificar si el marcador está dentro de los límites del mapa
            if (!mapBounds.contains(markerLatLng)) {
                return;
            }

            // Obtener las opciones del marcador y agregarlo al array
            circleMarkersData.push({
                latLng: [markerLatLng.lat, markerLatLng.lng],
                options: {
                    fillColor: layer.options.fillColor || 'rgba(0, 0, 0, 0.5)',
                    color: layer.options.color || '#000',
                    weight: layer.options.weight || 1,
                    radius: layer.options.radius || 5
                }
            });
        }
    }

    function drawImageOverlay(layer) {
        if (layer instanceof L.ImageOverlay) {
            layerQueue.defer(handleImageOverlayLayer, layer);
        }
    }

    // Función para manejar el final del procesamiento de capas
    function layersDone(err, layers) {
        if (err) {
            return callback(err);
        }

        const visibleLayers = layers.filter(layer => layer.canvas);
        const drawPromises = visibleLayers.map((layer) => {
            return new Promise((resolve) => {
                const layerCanvas = normalizeCanvas(layer.canvas, mainCanvas.width, mainCanvas.height);
                if (layerCanvas) {
                    requestAnimationFrame(() => {
                        mainCtx.drawImage(layerCanvas, 0, 0);
                        resolve();
                    });
                } else {
                    resolve();
                }
            });
        });

        Promise.all(drawPromises).then(() => {
            callback(null, mainCanvas);
        }).catch(error => {
            console.error('Error al dibujar las capas:', error);
            callback(error, null);
        });
    }

    // Normaliza el canvas de la capa o crea uno en blanco si no existe
    function normalizeCanvas(layerCanvas, targetWidth, targetHeight) {
        if (!layerCanvas) {
            return createBlankCanvas(targetWidth, targetHeight);
        }
        return layerCanvas;
    }

    // Crea un canvas en blanco
    function createBlankCanvas(width, height) {
        if (typeof OffscreenCanvas !== 'undefined') {
            const blankCanvas = new OffscreenCanvas(width, height);
            const blankCtx = blankCanvas.getContext('2d');
            blankCtx.fillStyle = 'rgba(0, 0, 0, 0)';
            blankCtx.fillRect(0, 0, width, height);
            return blankCanvas;
        } else {
            const blankCanvas = document.createElement('canvas');
            blankCanvas.width = width;
            blankCanvas.height = height;
            const blankCtx = blankCanvas.getContext('2d');
            blankCtx.fillStyle = 'rgba(0, 0, 0, 0)';
            blankCtx.fillRect(0, 0, width, height);
            return blankCanvas;
        }
    }

    // Maneja las capas de TileLayer
    function handleTileLayer(layer, callback) {
        const isCanvasLayer = (L.TileLayer.Canvas && layer instanceof L.TileLayer.Canvas);
        const layerCanvas = document.createElement('canvas');

        layerCanvas.width = dimensions.x;
        layerCanvas.height = dimensions.y;

        const ctx = layerCanvas.getContext('2d');
        const bounds = map.getPixelBounds();
        const zoom = map.getZoom();
        const tileSize = layer.options.tileSize;

        if (zoom > layer.options.maxZoom || zoom < layer.options.minZoom ||
            (hasMapbox && layer instanceof L.mapbox.tileLayer && !layer.options.tiles)) {
            return callback(null, { canvas: null });
        }

        const tileBounds = L.bounds(
            bounds.min.divideBy(tileSize)._floor(),
            bounds.max.divideBy(tileSize)._floor()
        );
        const tiles = [];
        const tileQueue = queue(1);

        for (let j = tileBounds.min.y; j <= tileBounds.max.y; j++) {
            for (let i = tileBounds.min.x; i <= tileBounds.max.x; i++) {
                tiles.push(new L.Point(i, j));
            }
        }

        tiles.forEach(function (tilePoint) {
            const originalTilePoint = tilePoint.clone();

            if (layer._adjustTilePoint) {
                layer._adjustTilePoint(tilePoint);
            }

            const tilePos = originalTilePoint
                .scaleBy(new L.Point(tileSize, tileSize))
                .subtract(bounds.min);

            if (tilePoint.y >= 0) {
                if (isCanvasLayer) {
                    const tile = layer._tiles[tilePoint.x + ':' + tilePoint.y];
                    tileQueue.defer(canvasTile, tile, tilePos, tileSize);
                } else {
                    const url = addCacheString(layer.getTileUrl(tilePoint));
                    tileQueue.defer(loadTile, url, tilePos, tileSize);
                }
            }
        });

        tileQueue.awaitAll(tileQueueFinish);

        function canvasTile(tile, tilePos, tileSize, callback) {
            callback(null, {
                img: tile,
                pos: tilePos,
                size: tileSize
            });
        }

        function loadTile(url, tilePos, tileSize, callback) {
            const im = new Image();
            im.crossOrigin = '';
            im.onload = function () {
                callback(null, {
                    img: this,
                    pos: tilePos,
                    size: tileSize
                });
            };
            im.onerror = function (e) {
                if (layer.options.errorTileUrl !== '' && e.target.errorCheck === undefined) {
                    e.target.errorCheck = true;
                    e.target.src = layer.options.errorTileUrl;
                } else {
                    callback(null, {
                        img: dummyCanvas,
                        pos: tilePos,
                        size: tileSize
                    });
                }
            };
            im.src = url;
        }

        function tileQueueFinish(err, data) {
            if (err) {
                console.error('Error al cargar las tiles:', err);
                return callback(err, { canvas: null });
            }
            data.forEach(drawTile);
            callback(null, { canvas: layerCanvas });
        }

        function drawTile(d) {
            ctx.drawImage(d.img, Math.floor(d.pos.x), Math.floor(d.pos.y),
                d.size, d.size);
        }
    }

    // Maneja el root de las paths
    function handlePathRoot(root, callback) {
        const bounds = map.getPixelBounds();
        const origin = map.getPixelOrigin();
        const pathCanvas = document.createElement('canvas');
        pathCanvas.width = dimensions.x;
        pathCanvas.height = dimensions.y;
        const ctx = pathCanvas.getContext('2d');
        const pos = L.DomUtil.getPosition(root).subtract(bounds.min).add(origin);
        try {
            ctx.drawImage(root, pos.x, pos.y, pathCanvas.width - (pos.x * 2), pathCanvas.height - (pos.y * 2));
            callback(null, { canvas: pathCanvas });
        } catch (e) {
            console.error('Elemento no pudo ser dibujado en el canvas:', root, e);
            callback(null, { canvas: null });
        }
    }

    // Maneja las capas de marcadores
    function handleMarkerLayer(marker, callback) {
        const markerCanvas = document.createElement('canvas');
        const ctx = markerCanvas.getContext('2d');
        const pixelBounds = map.getPixelBounds();
        const minPoint = new L.Point(pixelBounds.min.x, pixelBounds.min.y);
        const pixelPoint = map.project(marker.getLatLng());
        const isBase64 = /^data\:/.test(marker._icon.src);
        const url = isBase64 ? marker._icon.src : addCacheString(marker._icon.src);
        const im = new Image();
        const options = marker.options.icon.options;
        let size = options.iconSize;
        if (size instanceof L.Point) size = [size.x, size.y];
        const pos = pixelPoint.subtract(minPoint);
        const anchor = L.point(options.iconAnchor || (size && [size[0] / 2, size[1] / 2]));

        const x = Math.round(pos.x - anchor.x);
        const y = Math.round(pos.y - anchor.y);

        markerCanvas.width = dimensions.x;
        markerCanvas.height = dimensions.y;
        im.crossOrigin = '';

        im.onload = function () {
            ctx.drawImage(this, x, y, size[0], size[1]);
            callback(null, { canvas: markerCanvas });
        };

        im.onerror = function () {
            console.error('Error al cargar la imagen del marcador:', url);
            callback(null, { canvas: null });
        };

        im.src = url;

        if (isBase64) {
            im.onload();
        }
    }

    // Maneja las capas de polígonos
    function handlePolygonLayer(layer, callback) {
        const polygonBounds = layer.getBounds();
        const mapBounds = map.getBounds();

        // Verificar si el polígono intersecta los límites del mapa
        if (!mapBounds.intersects(polygonBounds)) {
            // El polígono está completamente fuera de la vista
            return callback(null, { canvas: null });
        }

        // Obtener todas las coordenadas del polígono
        const latLngs = layer.getLatLngs();

        // Función para extraer las coordenadas de un polígono o multi-polígono
        function extractCoords(latLngs) {
            if (Array.isArray(latLngs[0][0])) {
                // Multipolígono
                return latLngs.map(polygon => polygon.map(ring => ring.map(latlng => [latlng.lng, latlng.lat])));
            } else if (Array.isArray(latLngs[0])) {
                // Polígono con posibles huecos
                return [latLngs.map(ring => ring.map(latlng => [latlng.lng, latlng.lat]))];
            } else {
                // Polígono simple
                return [[latLngs.map(latlng => [latlng.lng, latlng.lat])]];
            }
        }

        const polygonLatLngs = extractCoords(latLngs);

        // Validar que todas las coordenadas sean números válidos
        const hasInvalidCoords = polygonLatLngs.some(polygon =>
            polygon.some(ring =>
                ring.some(coord =>
                    coord[0] == null || coord[1] == null || isNaN(coord[0]) || isNaN(coord[1])
                )
            )
        );

        if (hasInvalidCoords) {
            console.error('Polígono contiene coordenadas inválidas. Se omitirá este polígono.');
            return callback(null, { canvas: null });
        }

        // Preparar los datos para enviar al backend
        const polygons = [
            {
                latLngs: polygonLatLngs,
                options: {
                    fillColor: layer.options.fillColor || null,
                    color: layer.options.color || 'rgba(0, 0, 0, 1)',
                    weight: layer.options.weight || 2
                }
            }
        ];

        // Obtener los límites y zoom del mapa para el backend
        const mapBoundsObj = map.getBounds();
        const data = {
            width: mainCanvas.width,
            height: mainCanvas.height,
            bounds: {
                northWest: {
                    lat: mapBoundsObj.getNorthWest().lat,
                    lng: mapBoundsObj.getNorthWest().lng
                },
                southEast: {
                    lat: mapBoundsObj.getSouthEast().lat,
                    lng: mapBoundsObj.getSouthEast().lng
                }
            },
            zoom: map.getZoom(),
            polygons: polygons
        };

        // Realizar la solicitud POST al backend
        axios.post(`${API_BASE_PYTHON_SERVICE}image_generation/generate_image_map`, data, {
            responseType: 'blob' // Para manejar datos binarios
        })
            .then(response => {
                const imgBlob = response.data;
                const imgUrl = URL.createObjectURL(imgBlob);
                const img = new Image();

                img.onload = () => {
                    const polygonCanvas = document.createElement('canvas');
                    polygonCanvas.width = mainCanvas.width;
                    polygonCanvas.height = mainCanvas.height;
                    const polygonCtx = polygonCanvas.getContext('2d');

                    // Dibujar la imagen recibida en el canvas
                    polygonCtx.drawImage(img, 0, 0);

                    // Limpiar el objeto URL
                    URL.revokeObjectURL(imgUrl);

                    // Pasar el canvas al callback
                    callback(null, { canvas: polygonCanvas });
                };

                img.onerror = () => {
                    console.error('Error al cargar la imagen desde el backend');
                    callback(new Error('Error al cargar la imagen desde el backend'), { canvas: null });
                };

                img.src = imgUrl;
            })
            .catch(error => {
                console.error('Error al generar la imagen del polígono:', error);
                callback(error, { canvas: null });
            });
    }

    // Maneja las capas de polilíneas
    function handlePolylineLayer(layer, callback) {
        const polylineBounds = layer.getBounds();
        const mapBounds = map.getBounds();

        // Verificar si la polilínea intersecta los límites del mapa
        if (!mapBounds.intersects(polylineBounds)) {
            // La polilínea está completamente fuera de la vista
            return callback(null, { canvas: null });
        }

        // Obtener todas las coordenadas de la polilínea
        const latLngs = layer.getLatLngs();

        // Función para extraer las coordenadas de una polilínea o multi-polilínea
        function extractCoords(latLngs) {
            if (Array.isArray(latLngs[0][0])) {
                // Multi-polilínea
                return latLngs.map(line => line.map(latlng => [latlng.lng, latlng.lat]));
            } else {
                // Polilínea simple
                return [latLngs.map(latlng => [latlng.lng, latlng.lat])];
            }
        }

        const polylineLatLngs = extractCoords(latLngs);

        // Validar que todas las coordenadas sean números válidos
        const hasInvalidCoords = polylineLatLngs.some(line =>
            line.some(coord =>
                coord[0] == null || coord[1] == null || isNaN(coord[0]) || isNaN(coord[1])
            )
        );

        if (hasInvalidCoords) {
            console.error('Polilínea contiene coordenadas inválidas. Se omitirá esta polilínea.');
            return callback(null, { canvas: null });
        }

        // Preparar los datos para enviar al backend
        const polylines = [
            {
                latLngs: polylineLatLngs,
                options: {
                    color: layer.options.color || 'rgba(0, 0, 0, 1)',
                    weight: layer.options.weight || 2
                }
            }
        ];

        // Obtener los límites y zoom del mapa para el backend
        const mapBoundsObj = map.getBounds();
        const data = {
            width: mainCanvas.width,
            height: mainCanvas.height,
            bounds: {
                northWest: {
                    lat: mapBoundsObj.getNorthWest().lat,
                    lng: mapBoundsObj.getNorthWest().lng
                },
                southEast: {
                    lat: mapBoundsObj.getSouthEast().lat,
                    lng: mapBoundsObj.getSouthEast().lng
                }
            },
            zoom: map.getZoom(),
            polylines: polylines
        };

        // Realizar la solicitud POST al backend
        axios.post(`${API_BASE_PYTHON_SERVICE}image_generation/generate_image_map`, data, {
            responseType: 'blob' // Para manejar datos binarios
        })
            .then(response => {
                const imgBlob = response.data;
                const imgUrl = URL.createObjectURL(imgBlob);
                const img = new Image();

                img.onload = () => {
                    const polylineCanvas = document.createElement('canvas');
                    polylineCanvas.width = mainCanvas.width;
                    polylineCanvas.height = mainCanvas.height;
                    const polylineCtx = polylineCanvas.getContext('2d');

                    // Dibujar la imagen recibida en el canvas
                    polylineCtx.drawImage(img, 0, 0);

                    // Limpiar el objeto URL
                    URL.revokeObjectURL(imgUrl);

                    // Pasar el canvas al callback
                    callback(null, { canvas: polylineCanvas });
                };

                img.onerror = () => {
                    console.error('Error al cargar la imagen desde el backend');
                    callback(new Error('Error al cargar la imagen desde el backend'), { canvas: null });
                };

                img.src = imgUrl;
            })
            .catch(error => {
                console.error('Error al generar la imagen de la polilínea:', error);
                callback(error, { canvas: null });
            });
    }

    // Maneja las capas de CircleMarkers en batch
    function handleCircleMarkersBatch(callback) {
        const mapBoundsObj = map.getBounds();

        // Preparar los datos para enviar al backend
        const data = {
            width: mainCanvas.width,
            height: mainCanvas.height,
            bounds: {
                northWest: {
                    lat: mapBoundsObj.getNorthWest().lat,
                    lng: mapBoundsObj.getNorthWest().lng
                },
                southEast: {
                    lat: mapBoundsObj.getSouthEast().lat,
                    lng: mapBoundsObj.getSouthEast().lng
                }
            },
            zoom: map.getZoom(),
            circleMarkers: circleMarkersData
        };

        // Realizar la solicitud POST al backend
        axios.post(`${API_BASE_PYTHON_SERVICE}image_generation/generate_image_map`, data, {
            responseType: 'blob' // Para manejar datos binarios
        })
            .then(response => {
                const imgBlob = response.data;
                const imgUrl = URL.createObjectURL(imgBlob);
                const img = new Image();

                img.onload = () => {
                    const circleMarkersCanvas = document.createElement('canvas');
                    circleMarkersCanvas.width = mainCanvas.width;
                    circleMarkersCanvas.height = mainCanvas.height;
                    const ctx = circleMarkersCanvas.getContext('2d');

                    // Dibujar la imagen recibida en el canvas
                    ctx.drawImage(img, 0, 0);

                    // Limpiar el objeto URL
                    URL.revokeObjectURL(imgUrl);

                    // Pasar el canvas al callback
                    callback(null, { canvas: circleMarkersCanvas });
                };

                img.onerror = () => {
                    console.error('Error al cargar la imagen desde el backend');
                    callback(new Error('Error al cargar la imagen desde el backend'), { canvas: null });
                };

                img.src = imgUrl;
            })
            .catch(error => {
                console.error('Error al generar la imagen de los marcadores circulares:', error);
                callback(error, { canvas: null });
            });
    }

    // Maneja las capas de ImageOverlay
    function handleImageOverlayLayer(layer, callback) {
        const bounds = map.getBounds();
        const overlayBounds = layer.getBounds();

        if (!overlayBounds || !bounds.intersects(overlayBounds)) {
            return callback(null, { canvas: null });
        }

        const overlayCanvas = document.createElement('canvas');
        overlayCanvas.width = dimensions.x;
        overlayCanvas.height = dimensions.y;
        const overlayCtx = overlayCanvas.getContext('2d');

        const imageUrl = layer._url;
        const im = new Image();
        im.crossOrigin = '';

        im.onload = function () {
            const topLeft = map.latLngToContainerPoint(overlayBounds.getNorthWest());
            const bottomRight = map.latLngToContainerPoint(overlayBounds.getSouthEast());
            const width = bottomRight.x - topLeft.x;
            const height = bottomRight.y - topLeft.y;

            overlayCtx.drawImage(im, topLeft.x, topLeft.y, width, height);
            callback(null, { canvas: overlayCanvas });
        };

        im.onerror = function () {
            console.error('Error al cargar la imagen de ImageOverlay:', imageUrl);
            callback(null, { canvas: null });
        };

        im.src = imageUrl;
    }

    // Maneja las capas dinámicas de Esri
    function handleEsriDynamicLayer(dynamicLayer, callback) {
        const esriCanvas = document.createElement('canvas');
        esriCanvas.width = dimensions.x;
        esriCanvas.height = dimensions.y;

        const ctx = esriCanvas.getContext('2d');

        const im = new Image();
        im.crossOrigin = '';
        im.src = addCacheString(dynamicLayer._currentImage._image.src);

        im.onload = function () {
            ctx.drawImage(im, 0, 0);
            callback(null, { canvas: esriCanvas });
        };

        im.onerror = function () {
            console.error('Error al cargar la imagen de Esri Dynamic Layer:', dynamicLayer._currentImage._image.src);
            callback(null, { canvas: null });
        };
    }

    // Función auxiliar para añadir un parámetro de cache al URL
    function addCacheString(url) {
        if (isDataURL(url) || url.indexOf('mapbox.com/styles/v1') !== -1) {
            return url;
        }
        return url + ((url.match(/\?/)) ? '&' : '?') + 'cache=' + cacheBusterDate;
    }

    // Verifica si el URL es un Data URL
    function isDataURL(url) {
        const dataURLRegex = /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;
        return !!url.match(dataURLRegex);
    }
}
