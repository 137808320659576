import { styled } from '@mui/system';
import { Button, Link } from '@mui/material';

// Background for the login page
export const LoginBackgroundSecurity = styled('div')({
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#333', // Un tono oscuro más serio
    overflow: 'hidden',
});

// Container for the login form
export const LoginContainerSecurity = styled('div')({
    backgroundColor: '#1a1a1a',
    borderRadius: '10px',
    width: '100%',
    maxWidth: '450px',
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
    zIndex: 2,
    color: '#fff',
    textAlign: 'center',
});

// Left section for logo or branding
export const LeftContainerSecurity = styled('div')({
    width: '100%',
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

// Company logo styling
export const LogoSecurity = styled('img')({
    width: '70%',
    maxWidth: '250px',
    marginBottom: '20px',
});

// Right section for login form
export const RightContainerSecurity = styled('div')({
    width: '100%',
});

// Custom styled button for consistency
export const StyledButtonSecurity = styled(Button)({
    backgroundColor: '#00796b',
    color: '#fff',
    transition: 'all 0.3s ease',
    '&:hover': {
        backgroundColor: '#004d40',
        transform: 'translateY(-2px)',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
    },
});

// Forgot Password link styling
export const StyledForgotPasswordSecurity = styled(Link)({
    display: 'block',
    textAlign: 'center',
    color: '#80cbc4',
    fontSize: '0.9rem',
    textDecoration: 'none',
    marginTop: '15px',
    transition: 'color 0.3s ease',
    '&:hover': {
        color: '#004d40',
    },
});
