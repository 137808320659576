import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";  // Importa Axios
import { toast } from "react-toastify";
import { useAuthSecurity } from "../../../../context/AuthSecurityContext";
import { TextField, Container, Grid, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff, Lock, Email } from "@mui/icons-material";
import {API_BASE_URL_SECURITY} from "../../../../utils/config";
import {
    LoginBackgroundSecurity,
    LoginContainerSecurity,
    LeftContainerSecurity,
    RightContainerSecurity,
    LogoSecurity,
    StyledButtonSecurity,
    StyledForgotPasswordSecurity,
} from "./LoginSecurityStyle";
import logo from "../../../../assets/img/logo_letra.png";

// URL base para el endpoint de seguridad

const LoginSecurity = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { isAuthenticated, login } = useAuthSecurity();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (isAuthenticated) {
            const redirectTo = location.state?.from?.pathname || "/security-module";
            navigate(redirectTo);
        }
    }, [isAuthenticated, navigate, location]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Realiza la petición al endpoint de login
            const response = await axios.post(`${API_BASE_URL_SECURITY}login`, {
                email,
                password,
            });

            // Si la autenticación es exitosa, guarda los datos de usuario y redirige
            const { token, user } = response.data;

            // Guardar el token y el usuario en el contexto o almacenamiento local
            login({ token, user });

            // Redirigir al módulo de seguridad
            navigate("/security-module");
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // Si las credenciales son incorrectas
                toast.error("Credenciales incorrectas.");
            } else {
                // Si ocurre otro error
                toast.error("Ocurrió un error en el inicio de sesión. Por favor, inténtalo de nuevo.");
            }
        }
    };

    const handleForgotPasswordClick = (e) => {
        e.preventDefault();
        navigate("/security-passwordRecovery");
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <LoginBackgroundSecurity>
            <Container
                maxWidth="sm"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
            >
                <LoginContainerSecurity>
                    <LeftContainerSecurity>
                        <LogoSecurity src={logo} alt="Security Module Logo" />
                    </LeftContainerSecurity>
                    <RightContainerSecurity>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label="Email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        autoComplete="email"
                                        required
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Email style={{ color: "#ffffff" }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                        InputLabelProps={{
                                            style: { color: "#ffffff" },
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: '#ffffff',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#80cbc4',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#00796b',
                                                },
                                                input: {
                                                    color: '#ffffff',
                                                },
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label="Contraseña"
                                        type={showPassword ? "text" : "password"}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        autoComplete="current-password"
                                        required
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Lock style={{ color: "#ffffff" }} />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={togglePasswordVisibility}
                                                        edge="end"
                                                        style={{ color: "#ffffff" }}
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        InputLabelProps={{
                                            style: { color: "#ffffff" },
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: '#ffffff',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#80cbc4',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#00796b',
                                                },
                                                input: {
                                                    color: '#ffffff',
                                                },
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ marginTop: 3 }}>
                                <Grid item xs={12}>
                                    <StyledButtonSecurity
                                        fullWidth
                                        variant="contained"
                                        type="submit"
                                    >
                                        Iniciar sesión
                                    </StyledButtonSecurity>
                                </Grid>
                            </Grid>
                        </form>
                        <StyledForgotPasswordSecurity
                            href="#"
                            onClick={handleForgotPasswordClick}
                        >
                            ¿Olvidaste tu contraseña?
                        </StyledForgotPasswordSecurity>
                    </RightContainerSecurity>
                </LoginContainerSecurity>
            </Container>
        </LoginBackgroundSecurity>
    );
};

export default LoginSecurity;