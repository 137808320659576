// FilterToolbarStyle.js

import { styled } from '@mui/system';
import { AppBar, Button } from '@mui/material';
import { red } from '@mui/material/colors';

export const FilterBar = styled(AppBar)(({ isSidebarOpen, isDashboardIndicators, isSmallScreen }) => ({
    backgroundColor: '#fff',
    color: '#000',
    boxShadow: 'none',
    borderBottom: '1px solid #e0e0e0',
    padding: isSmallScreen ? '16px 8px 0 8px' : '30px 16px 0 0px',
    position: 'fixed',
    top: isDashboardIndicators ? 'auto' : '135px',
    width: isDashboardIndicators ? '100%' : isSidebarOpen ? 'calc(100% - 250px)' : 'calc(100% - 360px)',
    transition: 'width 0.5s ease',
    zIndex: isDashboardIndicators ? 'auto' : 1000,
    display: 'flex',
    alignItems: 'center',
}));

export const FilterButton = styled(Button)({
    margin: '0 8px',
    textTransform: 'none',
    color: '#000',
    fontWeight: 'bold',
});

export const ResetButton = styled(Button)({
    marginLeft: '8px',
    color: red[600],
    textTransform: 'none',
    fontWeight: 'bold',
});
