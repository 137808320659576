// FilterToolbar.jsx

import React, { useState, useEffect } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import 'moment/locale/es';
import {
    IconButton,
    TextField,
    Button,
    useMediaQuery,
    Box,
    Tooltip,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import RefreshIcon from '@mui/icons-material/Refresh';
import { FilterBar, ResetButton } from './FilterToolbarStyle';
import Autocomplete from '@mui/material/Autocomplete';
import { VariableSizeList } from 'react-window';
import { useTheme } from '@mui/material/styles';

const LISTBOX_PADDING = 8; // Tamaño de padding del listbox

function renderRow(props) {
    const { data, index, style } = props;
    const option = data[index];

    return (
        <Tooltip title={option.props.children} key={index}>
            {React.cloneElement(option, {
                style: {
                    ...style,
                    top: style.top + LISTBOX_PADDING,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '200px',
                },
            })}
        </Tooltip>
    );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef(function OuterElementType(props, ref) {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
        if (React.isValidElement(child) && child.type === 'ListSubheader') {
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.reduce((a, b) => a + getChildSize(b), 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

const FilterToolbar = ({
                           isSidebarOpen,
                           isDashboardIndicators,
                           filterOptions = [],
                           onDateChange,
                           onFilterClick,
                           handleAircraftChange,
                           loteOptions = [],
                           operadorOptions = [],
                           fincasOptions = [],
                           zafrasOptions = [],
                           tercioOptions = [],
                           operadorOptionsAA = [],
                           fincasOptionsAA = [],
                           productosOptionsAA = [],
                           zafraOptionsAA = [],
                           loteOptionsAA = [],
                           tercioOptionsAA = [],
                           dateRange,
                           selectedActivity,
                       }) => {
    moment.locale('es');

    const startOfMonth = moment().startOf('month');
    const endOfMonth = moment().endOf('month');

    const [startDate, setStartDate] = useState(dateRange?.startDate || startOfMonth);
    const [endDate, setEndDate] = useState(dateRange?.endDate || endOfMonth);

    const [selectedFilters, setSelectedFilters] = useState({
        loteCM: null,
        operadorCM: null,
        tipoAeronave: '',
        zafraCM: null,
        tercioCM: null,
        fincaCM: null,
        operadorAA: null,
        fincaAA: null,
        productoAA: null,
        zafraAA: null,
        loteAA: null,
        tercioAA: null,
    });

    // Resetear los filtros seleccionados cuando cambia la actividad
    useEffect(() => {
        setSelectedFilters({
            loteCM: null,
            operadorCM: null,
            tipoAeronave: '',
            zafraCM: null,
            tercioCM: null,
            fincaCM: null,
            operadorAA: null,
            fincaAA: null,
            productoAA: null,
            zafraAA: null,
            loteAA: null,
            tercioAA: null,
        });
        setStartDate(dateRange?.startDate || startOfMonth);
        setEndDate(dateRange?.endDate || endOfMonth);
    }, [selectedActivity, dateRange]);

    const handleStartDateChange = (date) => {
        if (date && date.isValid() && moment(date).isSameOrBefore(endDate, 'day')) {
            setStartDate(date);
            if (onDateChange) {
                onDateChange({ startDate: date, endDate });
            }
        }
    };

    const handleEndDateChange = (date) => {
        if (date && date.isValid() && moment(date).isSameOrAfter(startDate, 'day')) {
            setEndDate(date);
            if (onDateChange) {
                onDateChange({ startDate, endDate: date });
            }
        }
    };

    const handleFilterChange = (filterName, value) => {
        setSelectedFilters((prevFilters) => ({
            ...prevFilters,
            [filterName]: value,
        }));

        if (filterName === 'tipoAeronave' && handleAircraftChange) {
            handleAircraftChange(value);
        }
    };

    const handleReset = () => {
        setStartDate(startOfMonth);
        setEndDate(endOfMonth);
        setSelectedFilters({
            loteCM: null,
            operadorCM: null,
            tipoAeronave: '',
            zafraCM: null,
            tercioCM: null,
            fincaCM: null,
            operadorAA: null,
            fincaAA: null,
            productoAA: null,
            zafraAA: null,
            loteAA: null,
            tercioAA: null,
        });
        if (onDateChange) {
            onDateChange({ startDate: startOfMonth, endDate: endOfMonth });
        }
        if (handleAircraftChange) {
            handleAircraftChange('');
        }
    };

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <FilterBar
            position="static"
            isSidebarOpen={isSidebarOpen}
            isDashboardIndicators={isDashboardIndicators}
            isSmallScreen={isSmallScreen}
        >
            <Box
                display="flex"
                flexWrap={isSmallScreen ? 'wrap' : 'nowrap'}
                alignItems="center"
                width="100%"
                p={1}
                overflow="auto"
                sx={{
                    '&::-webkit-scrollbar': {
                        height: '6px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#cccccc',
                        borderRadius: '3px',
                    },
                }}
            >
                <IconButton edge="start" color="inherit" aria-label="filter">
                    <FilterListIcon />
                </IconButton>

                {/* DatePickers */}
                {onDateChange && (
                    <LocalizationProvider dateAdapter={AdapterMoment} locale="es">
                        <DatePicker
                            label={isSmallScreen ? 'Inicio' : 'Fecha Inicio'}
                            value={startDate}
                            onChange={handleStartDateChange}
                            maxDate={endDate}
                            disableFuture
                            inputFormat="DD/MM/YYYY"
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    sx: {
                                        marginRight: 2,
                                        minWidth: isSmallScreen ? 80 : 100,
                                        maxWidth: isSmallScreen ? 100 : 150,
                                        height: 40,
                                        flexShrink: 1,
                                        '& .MuiInputBase-input': {
                                            height: '1.5em',
                                            fontSize: isSmallScreen ? '0.75rem' : '0.875rem',
                                        },
                                    },
                                },
                            }}
                        />
                        <DatePicker
                            label={isSmallScreen ? 'Fin' : 'Fecha Fin'}
                            value={endDate}
                            onChange={handleEndDateChange}
                            minDate={startDate}
                            disableFuture
                            inputFormat="DD/MM/YYYY"
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    sx: {
                                        marginRight: 2,
                                        minWidth: isSmallScreen ? 80 : 100,
                                        maxWidth: isSmallScreen ? 100 : 150,
                                        height: 40,
                                        flexShrink: 1,
                                        '& .MuiInputBase-input': {
                                            height: '1.5em',
                                            fontSize: isSmallScreen ? '0.75rem' : '0.875rem',
                                        },
                                    },
                                },
                            }}
                        />
                    </LocalizationProvider>
                )}

                {/* Filtros dinámicos */}
                {filterOptions.map((filter) => {
                    if (filter.value === 'fechaInicio' || filter.value === 'fechaFin') {
                        return null;
                    }
                    let options = [];
                    let loading = false;
                    let filterValue = selectedFilters[filter.value];

                    switch (filter.value) {
                        case 'tipoAeronave':
                            options = filter.options || [];
                            break;
                        case 'loteCM':
                            options = loteOptions || [];
                            break;
                        case 'operadorCM':
                            options = operadorOptions || [];
                            break;
                        case 'fincaCM':
                            options = fincasOptions || [];
                            break;
                        case 'zafraCM':
                            options = zafrasOptions || [];
                            break;
                        case 'tercioCM':
                            options = tercioOptions || [];
                            break;
                        case 'operadorAA':
                            options = operadorOptionsAA || [];
                            break;
                        case 'fincaAA':
                            options = fincasOptionsAA || [];
                            break;
                        case 'productoAA':
                            options = productosOptionsAA || [];
                            break;
                        case 'zafraAA':
                            options = zafraOptionsAA || [];
                            break;
                        case 'loteAA':
                            options = loteOptionsAA || [];
                            break;
                        case 'tercioAA':
                            options = tercioOptionsAA || [];
                            break;
                        default:
                            options = [];
                    }

                    const isLargeDataset = options.length > 100;

                    return (
                        <Autocomplete
                            key={filter.value}
                            options={options}
                            getOptionLabel={(option) => option}
                            loading={loading}
                            value={filterValue}
                            onChange={(event, newValue) => {
                                handleFilterChange(filter.value, newValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={isSmallScreen ? filter.label.substring(0, 10) : filter.label}
                                    variant="outlined"
                                    size="small"
                                    InputProps={{
                                        ...params.InputProps,
                                        style: {
                                            height: '40px',
                                            padding: '0 14px',
                                            fontSize: isSmallScreen ? '0.75rem' : '0.875rem',
                                        },
                                    }}
                                    style={{
                                        marginRight: 8,
                                        minWidth: isSmallScreen ? 80 : 100,
                                        maxWidth: isSmallScreen ? 100 : 150,
                                        flexShrink: 1,
                                    }}
                                />
                            )}
                            renderOption={(props, option) => (
                                <Box
                                    component="li"
                                    {...props}
                                    sx={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        maxWidth: '200px',
                                    }}
                                >
                                    {option}
                                </Box>
                            )}
                            ListboxComponent={isLargeDataset ? ListboxComponent : undefined}
                            style={{
                                marginRight: 8,
                                minWidth: isSmallScreen ? 80 : 100,
                                maxWidth: isSmallScreen ? 100 : 150,
                                flexShrink: 1,
                            }}
                        />
                    );
                })}

                {/* Botones */}
                {onFilterClick && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => onFilterClick(selectedFilters)}
                        style={{ marginRight: 8, flexShrink: 1 }}
                        size={isSmallScreen ? 'small' : 'medium'}
                    >
                        Filtrar
                    </Button>
                )}
                <ResetButton
                    startIcon={<RefreshIcon />}
                    onClick={handleReset}
                    style={{ flexShrink: 1 }}
                    size={isSmallScreen ? 'small' : 'medium'}
                >
                    Reset
                </ResetButton>
            </Box>
        </FilterBar>
    );
};

export default FilterToolbar;