// src/analysis/aps/apsProcess.jsx

import axios from 'axios';
import pako from 'pako';
import { toast } from 'react-toastify';
import { API_BASE_URL, API_BASE_PYTHON_SERVICE } from "../../utils/config";

export const ejecutarProcesoAps = async ({
                                             selectedFile,
                                             selectedZipFile,
                                             idMax,
                                             idUsuario,
                                             setProcessingFinished,
                                             socket,
                                             socketSessionID,
                                             activarEdicionInteractiva,
                                             setShowProgressBar,
                                             setProgress,
                                             setTitleLoader

                                         }) => {
    setProgress(0);
    setTitleLoader("Iniciando mapeo de aplicaciones aéreas...");
    setShowProgressBar(true);

    try {
        // Leer el contenido del archivo CSV
        const fileReader = new FileReader();

        fileReader.onload = async (e) => {
            try {
                const content = e.target.result;

                // Comprimir el contenido usando pako
                const compressedContent = pako.gzip(content);

                // Crear un Blob a partir del contenido comprimido
                const blob = new Blob([compressedContent], { type: 'application/gzip' });

                // Crear el FormData y añadir el archivo comprimido y otros datos
                const formData = new FormData();
                formData.append('file', blob, `${selectedFile.name}.gz`);
                formData.append('collection_name', 'aplicaciones_aereas');
                formData.append('session_id', socketSessionID);

                // Enviar los datos al backend
                const insertResponse = await axios.post(`${API_BASE_PYTHON_SERVICE}mongo/insert_csv`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });

                // Verificar si la respuesta indica un error
                if (insertResponse.data.status === "error") {
                    throw new Error(insertResponse.data.message);
                }

                setProgress(20);
                setTitleLoader("Datos cargados, generando líneas...");

                const formDataGenerarLineas = new FormData();
                formDataGenerarLineas.append('filtrar', activarEdicionInteractiva);
                formDataGenerarLineas.append('polygon_path', selectedZipFile);
                formDataGenerarLineas.append('session_id', socketSessionID);
                formDataGenerarLineas.append('id_analisis', idMax);

                // Luego de insertar el CSV, proceder con las líneas
                const lineasResponse = await axios.post(`${API_BASE_PYTHON_SERVICE}mapping/aplicaciones_areas`, formDataGenerarLineas, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });

                // Verificar si la respuesta indica un error
                if (lineasResponse.data.status === "error") {
                    throw new Error(lineasResponse.data.message);
                }

                setProgress(80);
                setTitleLoader("Líneas generadas, ejecutando proceso...");

                // Manejar el éxito de la operación
                setProcessingFinished(true);
                toast.success('Datos cargados exitosamente.', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setProgress(100);
                setTitleLoader("Proceso finalizado.");
                setShowProgressBar(false);
            } catch (error) {
                console.error("Error al procesar el lote de Aplicaciones Aéreas:", error);
                const errorMessage = error.message || 'Ocurrió un error, consulta con tu administrador.';
                toast.error(errorMessage, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setShowProgressBar(false);
            }
        };

        fileReader.onerror = (error) => {
            console.error("Error al leer el archivo:", error);
            toast.error('Ocurrió un error al leer el archivo, consulta con tu administrador.', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setShowProgressBar(false);
        };

        // Leer el archivo como texto
        fileReader.readAsText(selectedFile);
    } catch (error) {
        console.error("Error al iniciar el proceso de Aplicaciones Aéreas:", error);
        const errorMessage = error.message || 'Ocurrió un error, consulta con tu administrador.';
        toast.error(errorMessage, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setShowProgressBar(false);
    }
};

export const ejecutarProcesoSinArchivoAps = async ({
                                                       idMax,
                                                       idUsuario,
                                                       setProcessingFinished,
                                                       setShowProgressBar
                                                   }) => {
    const formData = new FormData();
    formData.append('esPrimeraIteracion', 'true');
    formData.append('esKmlInteractivo', true);

    setShowProgressBar(true);

    try {
        const response = await axios.post(`${API_BASE_URL}dashboard/execBash/${idUsuario}/1/${idMax}/0/ok/0`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });

        if (response.data.status === "error") {
            throw new Error(response.data.message);
        }

        setProcessingFinished(true);
        toast.success('Proceso ejecutado exitosamente.', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setShowProgressBar(false);
    } catch (error) {
        console.error("Error al procesar el lote de Aplicaciones Aéreas sin archivo:", error);
        const errorMessage = error.message || 'Ocurrió un error, consulta con tu administrador.';
        toast.error(errorMessage, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setShowProgressBar(false);
    }
};