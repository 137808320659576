import React, { useEffect, useContext } from 'react';
import { Box, CssBaseline } from '@mui/material';
import { Outlet } from 'react-router-dom'; // Importa Outlet
import { SidebarContext } from '../../context/SidebarContext';
import useStyles from './SecurityModuleStyle';
import LayoutWithSidebar from '../../components/LayoutWithSidebar/LayoutWithSidebar';

const SecurityModule = ({ isSidebarOpen }) => {
    const classes = useStyles({ isSidebarOpen });
    const { setSelectedSidebarOption } = useContext(SidebarContext);

    useEffect(() => {
        setSelectedSidebarOption('Seguridad');
    }, [setSelectedSidebarOption]);

    return (
        <LayoutWithSidebar>
            <Box className={classes.root}>
                <CssBaseline />
                <Box className={classes.content}>
                    <Outlet context={{ isSidebarOpen }} />
                </Box>
            </Box>
        </LayoutWithSidebar>
    );
};

export default SecurityModule;
