import { styled } from '@mui/system';
import { Card, Typography, Box, Grid, TextField, IconButton, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export const SectionCard = styled(Card)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
    cursor: 'pointer',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
        transform: 'translateY(-4px)',
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.15)',
    },
    marginBottom: '8px',
});

export const SectionTitle = styled(Typography)({
    fontSize: '16px',
    fontWeight: '600',
    color: '#333333',
});

export const ExpandIcon = styled(IconButton)({
    transition: 'transform 0.3s',
});

export const InputsContainer = styled(Box)({
    padding: '16px',
    backgroundColor: '#f7f9fc',
    borderRadius: '8px',
    marginTop: '-8px',
    marginBottom: '16px',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
});

export const StyledTextField = styled(TextField)({
    marginBottom: '16px',
    width: '100%',
    '& label.Mui-focused': {
        color: '#3f51b5',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#3f51b5',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#c4c4c4',
        },
        '&:hover fieldset': {
            borderColor: '#3f51b5',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#3f51b5',
        },
    },
});