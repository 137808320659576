import React from 'react';
import { Box, Typography, Checkbox, FormControlLabel } from '@mui/material';

const PermissionControl = ({ selectedCompany, selectedRole }) => {
    if (!selectedCompany || !selectedRole) return null;

    return (
        <Box>
            <Typography variant="h6">Control de Permisos</Typography>
            <FormControlLabel control={<Checkbox />} label="Acceso al Dashboard" />
            <FormControlLabel control={<Checkbox />} label="Acceso a Configuraciones" />
        </Box>
    );
};

export default PermissionControl;
