import { API_BASE_URL } from "../../utils/Constants";
import {API_BASE_URL_DASHBOARD} from "../../utils/config";
import { ultimoAnalisis } from "../../utils/mapUtils";
import {obtenerDatosCompletosCm} from "../../utils/Constants";

// Función para obtener todos los datos de cosecha mecánica de una sola vez
export const fetchDataCosechaMecanica = async (idAnalisisCosechaMecanica, setDatosAnalisis) => {
    try {
        const data = await obtenerDatosCompletosCm(idAnalisisCosechaMecanica);
        const datos = {
            nombreResponsable: {
                titulo: "Nombre del responsable",
                valor: data.nombreResponsable || ''
            },
            fechaInicio: {
                titulo: "Fecha de inicio actividad",
                valor: data.fechaInicio || ''
            },
            fechaFin: {
                titulo: "Fecha de fin actividad",
                valor: data.fechaFin || ''
            },
            nombreFinca: {
                titulo: "Nombre de la finca",
                valor: data.nombreFinca || ''
            },
            codigoParcelaResponsable: {
                titulo: "Código de parcela responsable",
                valor: data.codigoParcelaResponsable || ''
            },
            nombreOperador: {
                titulo: "Nombre del operador",
                valor: data.nombreOperador || ''
            },
            nombreMaquina: {
                titulo: "Nombre de la máquina",
                valor: data.nombreMaquina || ''
            },
            actividad: {
                titulo: "Actividad realizada",
                valor: data.actividad || ''
            },
            horaInicio: {
                titulo: "Hora de inicio",
                valor: data.horaInicio || ''
            },
            horaFinal: {
                titulo: "Hora final",
                valor: data.horaFinal || ''
            },
            tiempoTotalActividad: {
                titulo: "Tiempo total de actividad",
                valor: data.tiempoTotalActividad || 0
            },
            calidadGps: {
                titulo: "Calidad del GPS",
                valor: data.calidadGps !== undefined ? parseFloat(data.calidadGps).toFixed(2) : null
            },
            promedioVelocidad: {
                titulo: "Promedio de velocidad",
                valor: data.promedioVelocidad !== undefined ? `${parseFloat(data.promedioVelocidad).toFixed(2)} Km/H` : null
            },
            consumoCombustible: {
                titulo: "Consumo de combustible",
                valor: data.consumoCombustible !== undefined ? parseFloat(data.consumoCombustible).toFixed(2) : null
            },
            presionCortadorBase: {
                titulo: "Presión del cortador base",
                valor: data.presionCortadorBase !== undefined ? parseFloat(data.presionCortadorBase).toFixed(2) : null
            },
            tah: {
                titulo: "TAH",
                valor: data.tah !== undefined ? parseFloat(data.tah).toFixed(2) : null
            },
            rpm: {
                titulo: "RPM",
                valor: data.rpm !== undefined ? parseFloat(data.rpm).toFixed(2) : null
            },
            tch: {
                titulo: "TCH",
                valor: data.tch !== undefined ? parseFloat(data.tch).toFixed(2) : null
            }
        };


        // Setear los datos obtenidos
        setDatosAnalisis(datos);
    } catch (error) {
        console.error("Error general al cargar datos de Cosecha Mecánica:", error);
        // Opcional: setear datos vacíos en caso de error
        setDatosAnalisis({});
    }
};

export const shouldEnableExecBashCosechaMecanica = (selectedFile) => {
    return selectedFile !== null;
};

export const cargaDatosCosechaMecanica = async (userData, selectedAnalysisTypeRef) => {

    if (selectedAnalysisTypeRef.current && userData.userId) {
        try {
            const response = await ultimoAnalisis(selectedAnalysisTypeRef, userData.userId);
            if (response && response.data && response.data.ID_ANALISIS) {
                return response;
            } else {
                console.error("Respuesta del último análisis no contiene datos esperados");
            }
        } catch (error) {
            console.error("Error al obtener último análisis:", error);
        }
    }
};

export const processCosechaMecanicaData = (datosAnalisis, indicadores) => ({
    analisis: "COSECHA_MECANICA",
    ...datosAnalisis,
    indicadores: {
        areaBrutaCm: {
            titulo: "Área bruta CM",
            valor: indicadores.areaBrutaCm || ''
        },
        eficienciaCm: {
            titulo: "Eficiencia CM",
            valor: indicadores.eficienciaCm || ''
        },
        porcentajeAreaPilotoCm: {
            titulo: "Porcentaje de área con piloto CM",
            valor: indicadores.porcentajeAreaPilotoCm || ''
        },
        porcentajeAreaAutoTrackerCm: {
            titulo: "Porcentaje de área con AutoTracker CM",
            valor: indicadores.porcentajeAreaAutoTrackerCm || ''
        },
        porcentajeModoCortadorBaseCm: {
            titulo: "Porcentaje en modo cortador base CM",
            valor: indicadores.porcentajeModoCortadorBaseCm || ''
        }
    }
});


export const fetchDataCosechaMecanicaIndicators = async (startDate, endDate, endpoint, idUsuario, minMaxParams = {}, filters = {}) => {
    try {

        // Convertir el objeto minMaxParams en una cadena de consulta (query string)
        const queryParams = new URLSearchParams({
            userId: idUsuario,
            FECHA_INICIO: startDate,
            FECHA_FINAL: endDate,
            minMax: JSON.stringify(minMaxParams),
            NOMBRE_FINCA: filters.finca || '',
            LOTE: filters.loteCM || '',
            OPERADOR: filters.operadorCM || '',
            ZAFRA: filters.zafraCM || '',
            TERCIO: filters.tercioCM || '',
        }).toString();


        // Realizar la solicitud fetch con los parámetros completos
        const response = await fetch(`${API_BASE_URL_DASHBOARD}api/indicators/cosecha-mecanica/${endpoint}?${queryParams}`);
        const data = await response.json();

        return data.map(item => {
            const processedData = Object.entries(item).reduce((acc, [key, value]) => {
                if (value && typeof value === 'object') {
                    acc[key] = {
                        title: value.titulo || 'Desconocido',
                        value: value.valor || 0,
                    };
                }
                return acc;
            }, {});

            return {
                _id: item._id || null,
                ...processedData,
            };
        });
    } catch (error) {
        console.error(`Error al obtener los indicadores de ${endpoint}:`, error);
        return [];
    }
};



export const initialIndicatorsCosechaMecanica = {
    cardData: {
        velocidad: { title: 'Velocidad', max: '', min: '' },
        rpm: { title: 'RPM', max: '', min: '' },
        calidadGps: { title: 'Calidad GPS', max: '', min: '' },
        consumoCombustible: { title: 'Consumo de Combustible', max: '', min: '' },
        presionCorteBase: { title: 'Presión Corte Base', max: '', min: '' },
        tch: { title: 'TCH', max: '', min: '' },
        tah: { title: 'TAH', max: '', min: '' },
        pilotoAutomatico: { title: 'Piloto Automático', max: '', min: '' },
        autoTracket: { title: 'Auto Tracket', max: '', min: '' },
        modoCorteBase: { title: 'Modo Corte Base', max: '', min: '' },
    }
};