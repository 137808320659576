import React, { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaQuestionCircle } from 'react-icons/fa';
import BarChartIcon from '@mui/icons-material/BarChart';
import MapIcon from '@mui/icons-material/Map';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import LayersIcon from '@mui/icons-material/Layers';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useAuth } from '../../context/AuthContext';
import { Modal, Box } from '@mui/material';
import {
    SidebarContainer, MenuToggle, LogoSection, LogoImage, MenuItemsContainer, MenuItemContainer,
    MenuIconContainer, MenuText, TooltipContainer, ModalContent, HelpButton
} from './SideBarStyle';
import Tutorial from '../Tutorial/Tutorial';
import { CompanyContext } from '../../context/CompanyContext';
import { SidebarContext } from '../../context/SidebarContext';

const Sidebar = ({ onToggle }) => {
    const location = useLocation();
    const { logout } = useAuth();
    const { setSelectedSidebarOption } = useContext(SidebarContext);
    const [activeItem, setActiveItem] = useState(location.pathname);
    const [isOpen, setIsOpen] = useState(true);
    const [tooltip, setTooltip] = useState({ visible: false, content: '', position: { top: 0, left: 0 } });
    const [expandedItems, setExpandedItems] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { logo } = useContext(CompanyContext);
    const [isTutorialActive, setIsTutorialActive] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const navigate = useNavigate();

    // Función para manejar clic en el elemento del menú (categorías y subcategorías)
    const handleMenuItemClick = (item, subItem) => {
        const target = subItem || item;
        if (target.name === 'Cerrar sesión') {
            logout();
            navigate('/login');
            return;
        }

        if (!target.path && !target.subcategories) {
            setModalMessage('Estamos trabajando en esta sección para mejorar tu experiencia.');
            setIsModalOpen(true);
        } else if (target.path) {
            setSelectedSidebarOption(target.name);
            setActiveItem(target.path);
            navigate(target.path);
        }
    };



    const toggleSidebar = () => {
        setIsOpen(!isOpen);
        onToggle(!isOpen);
    };

    // Función para manejar expansión de categorías con subcategorías
    const handleExpandClick = (name) => {
        setExpandedItems((prevState) => ({
            ...prevState,
            [name]: !prevState[name], // Alterna entre expandido y colapsado
        }));
    };

    const menuItems = [
        { name: 'Dashboard', icon: <BarChartIcon />, path: '/dashboard' },
        { name: 'Mapeo de maquinaria', icon: <MapIcon />, path: '/mapeo?tipo=mapeo_maquinaria' },
        { name: 'Conteo de plantas', icon: <AgricultureIcon />, path: '/mapeo?tipo=conteo_plantas' },
        {
            name: 'SIG Agrícola', icon: <LayersIcon />, subcategories: [
                {
                    name: 'Cosecha', subcategories: [
                        { name: 'Planificación de cosecha', path: null },  // Sección en construcción
                        { name: 'Avance de cosecha', path: '/cosecha/avance' },
                        { name: 'Tipo de cosecha (mecánica / manual)', path: '/cosecha/tipo' },
                        { name: 'Traciegos', path: '/cosecha/traciegos' },
                        { name: 'Quemas criminales', path: '/cosecha/quemas' },
                        { name: 'Rutas cañeras', path: '/cosecha/rutas' },
                    ]
                },
                {
                    name: 'Madurantes', subcategories: [
                        { name: 'Planificacion de madurante', path: '/madurantes/planificacion' },
                        { name: 'Avance de madurante', path: '/madurantes/avance' },
                        { name: 'Dias programados para corte', path: null },  // Sección en construcción
                        { name: 'Aplicacion con dron', path: '/madurantes/aplicacion-dron' },
                    ]
                },
                {
                    name: 'Variedades', subcategories: [
                        { name: 'Tipos de variedades', path: '/variedades/tipos' },
                        { name: 'Variedades de más a menos productividad', path: null },  // Sección en construcción
                        { name: 'Semilleros', path: '/variedades/semilleros' },
                        { name: 'Ensayos de variedades', path: '/variedades/ensayos' },
                    ]
                },
                {
                    name: 'Plagas', subcategories: [
                        { name: 'Barrenador', path: '/plagas/barrenador' },
                        { name: 'Chinche salivosa', path: '/plagas/chinche' },
                        { name: 'Rata', path: null },  // Sección en construcción
                        { name: 'Plagas del suelo', path: '/plagas/suelo' },
                        { name: 'Programación de muestreo semanal', path: '/plagas/programacion' },
                    ]
                },
                {
                    name: 'Riego', subcategories: [
                        { name: 'Áreas con y sin riego', path: '/riego/areas' },
                        { name: 'Tipos de riego', path: '/riego/tipos' },
                        { name: 'Balance hídrico', path: '/riego/balance' },
                        { name: 'Evapotranspiración', path: '/riego/evapotranspiracion' },
                        { name: 'Calidad del riego', path: '/riego/calidad' },
                        { name: 'Productividad con y sin riego', path: null },  // Sección en construcción
                    ]
                }
            ]
        },
        { name: 'Configuración', icon: <SettingsIcon />, path: '/configuracion' },
        { name: 'Cerrar sesión', icon: <ExitToAppIcon />, path: '/logout' }
    ];

    const securityItems = [
        { name: 'Usuario', icon: <SettingsIcon />, path: '/security-module/agregar-usuario' },
        { name: 'Empresa', icon: <SettingsIcon />, path: '/security-module/agregar-empresa' },
        { name: 'Cerrar sesión', icon: <ExitToAppIcon />, path: '/security-login' }
    ];


    const activeItems = location.pathname.includes('/security-module') ? securityItems : menuItems;

    const showTooltip = (content, event) => {
        const position = {
            top: event.currentTarget.getBoundingClientRect().top + window.scrollY,
            left: event.currentTarget.getBoundingClientRect().right + 10
        };
        setTooltip({ visible: true, content, position });
    };

    const hideTooltip = () => {
        setTooltip({ visible: false, content: '', position: { top: 0, left: 0 } });
    };

    return (
        <>
            <MenuToggle isOpen={isOpen} onClick={toggleSidebar}>
                {isOpen ? <CloseIcon /> : <MenuIcon />}
            </MenuToggle>
            <SidebarContainer isOpen={isOpen}>
                {isOpen && (
                    <LogoSection>
                        <LogoImage src={logo} alt="Logo" />
                    </LogoSection>
                )}
                <MenuItemsContainer>
                    {activeItems.map((item, index) => (
                        <Box key={index} style={{ width: '100%' }}>
                            <MenuItemContainer
                                active={activeItem === item.path}
                                onClick={() => {
                                    // Despliega subcategorías o lanza modal si es necesario
                                    if (item.subcategories) {
                                        handleExpandClick(item.name);
                                    } else {
                                        handleMenuItemClick(item);
                                    }
                                }}
                                onMouseEnter={(e) => showTooltip(item.name, e)}
                                onMouseLeave={hideTooltip}
                            >
                                <MenuIconContainer>{item.icon}</MenuIconContainer>
                                {isOpen && <MenuText>{item.name}</MenuText>}
                                {item.subcategories?.length > 0 && isOpen && (
                                    <Box>
                                        {expandedItems[item.name] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </Box>
                                )}
                            </MenuItemContainer>
                            {expandedItems[item.name] && item.subcategories?.length > 0 && (
                                <Box>
                                    {item.subcategories.map((subItem, subIndex) => (
                                        <Box key={subIndex} style={{ width: '100%', paddingLeft: '1.5rem' }}>
                                            <MenuItemContainer
                                                active={activeItem === subItem.path}
                                                onClick={() => {
                                                    // Despliega sub-subcategorías o lanza modal si es necesario
                                                    if (subItem.subcategories) {
                                                        handleExpandClick(subItem.name);
                                                    } else {
                                                        handleMenuItemClick(subItem);
                                                    }
                                                }}
                                                onMouseEnter={(e) => showTooltip(subItem.name, e)}
                                                onMouseLeave={hideTooltip}
                                            >
                                                {isOpen && <MenuText>{subItem.name}</MenuText>}
                                                {subItem.subcategories?.length > 0 && (
                                                    <Box>
                                                        {expandedItems[subItem.name] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                    </Box>
                                                )}
                                            </MenuItemContainer>
                                            {expandedItems[subItem.name] && subItem.subcategories?.length > 0 && (
                                                <Box>
                                                    {subItem.subcategories.map((subSubItem, subSubIndex) => (
                                                        <Box key={subSubIndex} style={{ width: '100%', paddingLeft: '3rem' }}>
                                                            <MenuItemContainer
                                                                active={activeItem === subSubItem.path}
                                                                onClick={() => handleMenuItemClick(subSubItem)}
                                                                onMouseEnter={(e) => showTooltip(subSubItem.name, e)}
                                                                onMouseLeave={hideTooltip}
                                                            >
                                                                {isOpen && <MenuText>{subSubItem.name}</MenuText>}
                                                            </MenuItemContainer>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            )}
                                        </Box>
                                    ))}
                                </Box>
                            )}
                        </Box>
                    ))}
                </MenuItemsContainer>
                <HelpButton onClick={() => setIsTutorialActive(true)}>
                    <FaQuestionCircle />
                </HelpButton>
            </SidebarContainer>
            {tooltip.visible && (
                <TooltipContainer top={tooltip.position.top} left={tooltip.position.left}>
                    {tooltip.content}
                </TooltipContainer>
            )}

            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <ModalContent>
                    <SettingsIcon style={{ fontSize: '4rem', color: '#f5a623', animation: 'rotate 2s linear infinite' }} />
                    <h2 id="modal-title">Sección en construcción</h2>
                    <p id="modal-description">{modalMessage}</p>
                </ModalContent>
            </Modal>

            <Tutorial isActive={isTutorialActive} onClose={() => setIsTutorialActive(false)} />
        </>
    );
};

export default Sidebar;
