import React, { useRef, useState, useEffect, useMemo } from 'react';
import { MapContainer, TileLayer, LayersControl, Polyline, Popup, useMap } from 'react-leaflet';
import { Box } from '@mui/material';
import { handleZoom, throttledHandleMoveEnd, fitBoundsIfValid } from '../../analysis/AnalysisMapping/MappingGeneral';
import { getLoteId, memoizedPolygons, externalPolygons, memoizedMarkers } from '../../analysis/AnalysisMapping/CosechaMecanicaMapping';
import { addImageOverlay } from '../../analysis/AnalysisMapping/ConteoPalmasMapping';
import { getBufferedLines, getBufferedIntersections, getShowIntersections, getNonIntersectedAreas } from '../../analysis/AnalysisMapping/AplicacionesAereasMapping';
import GeoTIFFLayer from "../../analysis/cosechaMecanica/GeoTIFFLayer";
import L from 'leaflet';
import throttle from 'lodash/throttle';  // Asegúrate de importar throttle desde lodash
import { useAddImageOverlay } from "../../analysis/AnalysisMapping/ConteoPalmasMapping";
import {API_BASE_PYTHON_SERVICE} from "../../utils/config";
const { BaseLayer } = LayersControl;

const CommonMap = (props) => {
    const {
        center = [14.634915, -90.506882],
        zoom = 8,
        polygons = [],
        lines = [],
        points = [],
        areasSuperpuestas = [],
        nonIntersectedAreas = [],
        bufferedLines = [],
        bufferedIntersections = [],
        onLineHover = () => {},
        onLineMouseOut = () => {},
        onLineClick = () => {},
        polygonProperties = [],
        showIntersections = true,
        mapRef = { current: null },
        stretchPoints = [],
        lineasNoFiltradas = [],
        polygonsData = [],
        highlightedLote = null,
        activeLotes = [],
        onSelectLote = () => {},
        onHoverLote = () => {},
        onLeaveLote = () => {},
        imageUrl = '',
        northWestCoords ,
        southEastCoords ,
        polygonsLayerRef,
        imageBoundsRaster ,
        imageOverlayUrlRaster = null,
        filteredColors
    } = props;

    const localMapRef = useRef(null);
    const [mapCenter, setMapCenter] = useState(center);
    const [mapZoom, setMapZoom] = useState(zoom);
    const [initialBoundsSet, setInitialBoundsSet] = useState(false);
    const mapKey = useRef(Date.now());
    const [shouldFitBounds, setShouldFitBounds] = useState(true);
    const [isFirstPolygons, setIsFirstPolygons] = useState(true);
    const markersRef = useRef([]);
    const previousPointsRef = useRef(points || []);
    const imageOverlayRef = useRef(null);
    const [imageOverlayUrlRasterInit, setImageOverlayUrlRasterInit] = useState(null);
    const [linesInit, setLinesInit] = useState(null);
    const [imageDataInit, setImageDataInit] = useState(null);
    const [polygonsInit, setPolygonsInit] = useState(null);
    const [boundsPalmas, setBoundsPalmas] = useState(null);
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    const throttledHandleZoom = useMemo(() => throttle(() => handleZoom(localMapRef, setMapZoom), 200), [localMapRef, setMapZoom]);
    const memoizedPolygonsInit = useMemo(() => polygonsInit, [polygonsInit]);

    const MapEffect = () => {
        const map = useMap();

        useEffect(() => {
            if (map) {
                localMapRef.current = map;
                mapRef.current = map;
                map.on('moveend', () => throttledHandleMoveEnd(localMapRef, setMapCenter));
            }
            return () => {
                if (map) {
                    map.off('moveend', throttledHandleMoveEnd);
                }
            };
        }, [map]);

        return null;
    };

    useEffect(() => {
        const loadImageOverlay = async () => {
            addImageOverlay(mapRef, (imageUrl || imageDataInit), northWestCoords, southEastCoords, imageOverlayRef, setInitialBoundsSet, initialBoundsSet, boundsPalmas);        };
        if (imageUrl || imageDataInit) {
            loadImageOverlay();
        }
    }, [localMapRef, imageUrl, northWestCoords, southEastCoords, imageDataInit]);

    useEffect(() => {
        const map = localMapRef.current;
        if (map) {
            map.createPane('polygonPane');
            map.getPane('polygonPane').style.zIndex = 400; // Asegura que los polígonos estén al fondo

            map.createPane('circlePane');
            map.getPane('circlePane').style.zIndex = 1000; // Asegura que los puntos estén al frente
        }
    }, [localMapRef]);


    useEffect(() => {
        try {
            if (localMapRef.current && !initialBoundsSet) {
                mapRef.current = localMapRef.current;
                const bounds = new L.LatLngBounds();
                if (Array.isArray(points) && points.length > 0 && !imageOverlayUrlRaster) {
                    points.forEach(point => {
                        const coordinates = point.geometry?.coordinates;
                        if (Array.isArray(coordinates) && coordinates.length >= 2) {
                            bounds.extend([coordinates[1], coordinates[0]]);
                        } else {
                            console.error('Coordenadas no válidas para el punto:', point);
                        }
                    });
                }

                if (Array.isArray(lines) && lines.length > 0) {
                    lines.forEach(line => {
                        if (line.polyline && Array.isArray(line.polyline._latlngs)) {
                            line.polyline._latlngs.forEach(latlng => {
                                bounds.extend([latlng.lat, latlng.lng]);
                            });
                        }
                    });
                }


                if (Array.isArray(polygons) && polygons.length > 0) {
                    polygons.forEach(polygon => {
                        polygon.forEach(coord => {
                            if (Array.isArray(coord) && coord.length >= 2) {
                                bounds.extend([coord[1], coord[0]]);
                            } else {
                                console.error('Coordenadas no válidas en el polígono:', coord);
                            }
                        });
                    });
                }



                fitBoundsIfValid(localMapRef.current, bounds);
                fitBoundsIfValid(mapRef.current, bounds);

                localMapRef.current.on('moveend', () => throttledHandleMoveEnd(localMapRef, setMapCenter));

                mapRef.current.on('moveend', () => throttledHandleMoveEnd(localMapRef, setMapCenter));

                setInitialBoundsSet(true);
                setShouldFitBounds(true);
            }

            return () => {
                try {
                    if (localMapRef.current) {
                        localMapRef.current.off('zoomend', throttledHandleZoom);
                        localMapRef.current.off('moveend', throttledHandleMoveEnd);
                    }
                    if (mapRef.current) {
                        mapRef.current.off('zoomend', throttledHandleZoom);
                        mapRef.current.off('moveend', throttledHandleMoveEnd);
                    }
                } catch (error) {
                    console.error("Error in cleanup [useEffect]:", error);
                }
            };
        } catch (error) {
            console.error("Error in useEffect [initialBoundsSet]:", error);
        }
    }, [points, lines, polygons, initialBoundsSet, throttledHandleZoom, imageOverlayUrlRaster]);

    useEffect(() => {
        try {
            if (localMapRef.current) {
                const map = localMapRef.current;
                if (!map._loaded) {
                    console.error('El mapa aún no se ha cargado completamente.');
                    return;
                }

                map.eachLayer((layer) => {
                    if (
                        layer instanceof L.Polygon ||
                        layer instanceof L.Polyline ||
                        layer instanceof L.LayerGroup
                    ) {
                        map.removeLayer(layer);
                    }
                });

                const filteredPolygons =
                    Array.isArray(activeLotes) && activeLotes.length > 0
                        ? polygonsData.filter((feature) =>
                            activeLotes.includes(getLoteId(feature.properties))
                        )
                        : polygonsData;

                const geojsonLayer = L.geoJSON(filteredPolygons, {
                    pane: 'polygonPane',
                    style: (feature) => ({
                        color: 'rgb(255,209,0)',
                        weight: 2,
                        fillColor: 'rgba(255,215,0,0.3)',
                        fillOpacity: 1,
                    }),
                    renderer: L.canvas(),
                });
                geojsonLayer.addTo(map);
                geojsonLayer.bringToBack();

                if (isFirstPolygons && Array.isArray(polygonsData) && polygonsData.length > 0) {
                    const map = mapRef.current = localMapRef.current;

                    const polygonsLayer = L.geoJSON(polygonsData, {
                        style: {
                            color: 'rgb(255,209,0)',
                            weight: 2,
                            opacity: 0,
                            fillColor: 'rgba(255,208,0,0.3)',
                            fillOpacity: 0.8
                        },
                        renderer: L.canvas()
                    }).addTo(map);

                    polygonsLayer.bringToBack();

                    polygonsLayerRef.current = polygonsLayer;

                    map.fitBounds(polygonsLayer.getBounds());
                    setIsFirstPolygons(false);
                }


                const currentPoints = points || [];
                const previousPoints = previousPointsRef.current || [];

                const pointsHaveChanged =
                    previousPoints.length !== currentPoints.length ||
                    currentPoints.some(
                        (point, index) =>
                            point.geometry?.coordinates[0] !== previousPoints[index]?.geometry.coordinates[0] ||
                            point.geometry?.coordinates[1] !== previousPoints[index]?.geometry.coordinates[1] ||
                            point.color !== previousPoints[index]?.color
                    );

                if (pointsHaveChanged) {
                    markersRef.current.forEach((marker) => map.removeLayer(marker));
                    markersRef.current = [];

                    previousPointsRef.current = currentPoints;
                    markersRef.current = memoizedMarkers(points, setImageOverlayUrlRasterInit);
                    let bounds = new L.LatLngBounds();

                    markersRef.current.forEach((marker) => {
                        marker.addTo(map);
                        const latLng = marker.getLatLng();
                        if (latLng && latLng.lat && latLng.lng) {
                            bounds.extend(latLng);
                        } else {
                            console.error('El marcador tiene coordenadas no válidas:', latLng);
                        }
                    });

                    if (shouldFitBounds && bounds.isValid()) {
                        map.fitBounds(bounds);
                        setShouldFitBounds(false);
                        setInitialBoundsSet(true);
                    }
                }

                let bounds = new L.LatLngBounds();

                if (Array.isArray(activeLotes) && activeLotes.length > 0) {
                    const boundsPolygons = geojsonLayer.getBounds();
                    if (boundsPolygons.isValid()) {
                        bounds.extend(boundsPolygons);
                    } else {
                        console.error('Los límites calculados para los polígonos no son válidos.');
                    }
                }
                if (Array.isArray(polygons) && polygons.length > 0) {
                    const canvasLayer = L.canvas();
                    setPolygonsInit(null);
                    polygons.forEach((polygon, index) => {
                        // Validar que polygon es un array de coordenadas válido
                        const positions = polygon
                            .filter(coord => Array.isArray(coord) && coord.length >= 2) // Filtrar coordenadas inválidas
                            .map(coord => [coord[1], coord[0]]); // Invertir las coordenadas (lat, lng)

                        if (positions.length > 0) {
                            const polygonColor = polygonProperties[index]?.color || 'green';
                            const polygonLayer = L.polygon(positions, {
                                color: polygonColor,
                                weight: 3,
                                renderer: canvasLayer,
                            }).addTo(map);

                            // Validar que los bounds son válidos antes de extenderlos
                            if (polygonLayer.getBounds().isValid()) {
                                bounds.extend(polygonLayer.getBounds());
                            } else {
                                console.error('Límites no válidos en el polígono:', polygonLayer);
                            }
                        } else {
                            console.error('Polígono con coordenadas no válidas:', polygon);
                        }
                    });
                }




                if (Array.isArray(areasSuperpuestas) && areasSuperpuestas.length > 0) {
                    areasSuperpuestas.forEach((area) => {
                        const positions = area.map(coord => [coord[1], coord[0]]);
                        const areaLayer = L.polygon(positions, {
                            color: 'red',
                            weight: 3,
                            renderer: L.canvas()
                        }).addTo(map);
                        bounds.extend(areaLayer.getBounds());
                    });
                }

                if (Array.isArray(lineasNoFiltradas) && lineasNoFiltradas.length > 0) {
                    lineasNoFiltradas.forEach((linea) => {
                        if (linea.polyline && Array.isArray(linea.polyline._latlngs)) {
                            const positions = linea.polyline._latlngs.map((coord) => [
                                coord.lat,
                                coord.lng,
                            ]);
                            const polyline = L.polyline(positions, {
                                color: 'rgb(192, 192, 192)',
                                weight: 3,
                                opacity: 0.8,
                            }).addTo(map);
                            bounds.extend(polyline.getBounds());
                        }
                    });
                }

                if (Array.isArray(lines) && lines.length > 0) {
                    setLinesInit(null);
                    lines.forEach((line) => {
                        if (line && line.polyline && Array.isArray(line.polyline._latlngs)) {
                            const positions = line.polyline._latlngs.map((coord) => [
                                coord.lat,
                                coord.lng,
                            ]);
                            const lineLayer = L.polyline(positions, {
                                color: 'red',
                                weight: 3,
                            })
                                .on('mouseover', (e) => {
                                    onLineHover(e, line.id);
                                    e.target.bringToFront();  // Asegura que la línea se traiga al frente al hacer hover
                                })
                                .on('mouseout', (e) => {
                                    onLineMouseOut(e, line.id);
                                    e.target.bringToFront();  // Asegura que la línea roja permanezca al frente al salir del hover
                                })
                                .on('click', (e) => {
                                    onLineClick(line, e);
                                    e.target.bringToFront();  // Asegura que la línea se traiga al frente al hacer clic
                                })
                                .addTo(map);

                            bounds.extend(lineLayer.getBounds());
                        }
                    });
                }

                if (Array.isArray(linesInit) && linesInit.length > 0) {
                    let popupOpened = false;

                    const popup = L.popup({
                        closeOnClick: false,
                        autoClose: true,
                        autoPan: false
                    }).setContent('<strong>Análisis:</strong> Aplicaciones Aéreas - Drones');

                    const openPopup = (positions) => {
                        if (!popupOpened && positions[0] && map) {
                            map.closePopup();

                            popup.setLatLng(positions[0]).openOn(map);
                            popupOpened = true;
                        }
                    };

                    // Iterar sobre las líneas iniciales
                    linesInit.forEach((line) => {
                        if (Array.isArray(line) && line.length > 0 && Array.isArray(line[0])) {
                            const positions = line.map(coord => {
                                if (Array.isArray(coord) && coord.length === 2 && !isNaN(coord[0]) && !isNaN(coord[1])) {
                                    return [coord[1], coord[0]];
                                } else {
                                    console.error('Coordenada no válida:', coord);
                                    return null;
                                }
                            }).filter(Boolean);

                            if (positions.length > 1) {
                                const lineLayer = L.polyline(positions, {
                                    color: 'red',
                                    weight: 3,
                                })
                                    .on('mouseover', (e) => {
                                        onLineHover(e, line.id);
                                        e.target.bringToFront();
                                    })
                                    .on('mouseout', (e) => {
                                        onLineMouseOut(e, line.id);
                                        e.target.bringToFront();
                                    })
                                    .addTo(map);

                                openPopup(positions);
                            } else {
                                console.warn('Línea con menos de dos posiciones válidas:', line);
                            }
                        } else {
                            console.error('Formato de línea no válido:', line);
                        }
                    });

                    if (!popupOpened) {
                        console.warn('No se abrió ningún popup, revisar coordenadas.');
                    }

                    map.on('zoomend moveend', () => {
                        map.closePopup();
                        popupOpened = false;
                    });
                } else {
                }

                // Añadir líneas buffer al mapa
                if (Array.isArray(bufferedLines)) {
                    const layers = getBufferedLines(bufferedLines);
                    layers.forEach(layer => {
                        if (layer) {
                            layer.addTo(map); // Añadir cada capa al mapa
                        }
                    });
                }
                // Añadir intersecciones buffer al mapa
                if (Array.isArray(bufferedIntersections)) {
                    const layers = getBufferedIntersections(bufferedIntersections);
                    layers.forEach(layer => {
                        if (layer) {
                            layer.addTo(map); // Añadir cada capa al mapa
                        }
                    });
                }

                // Añadir áreas superpuestas al mapa
                if (Array.isArray(areasSuperpuestas)) {
                    const layers = getShowIntersections(areasSuperpuestas);
                    layers.forEach(layer => {
                        if (layer) {
                            layer.addTo(map); // Añadir cada capa al mapa
                        }
                    });
                }

                // Añadir áreas no intersectadas al mapa
                if (Array.isArray(nonIntersectedAreas)) {
                    const layers = getNonIntersectedAreas(nonIntersectedAreas);
                    layers.forEach(layer => {
                        if (layer) {
                            layer.addTo(map); // Añadir cada capa al mapa
                        }
                    });
                }

                if (stretchPoints) {
                    stretchPoints.forEach((marker) => {
                        marker.addTo(map);
                        bounds.extend(marker.getLatLng());
                    });
                }

                if (!initialBoundsSet && shouldFitBounds && bounds.isValid()) {
                    map.fitBounds(bounds);
                    setShouldFitBounds(false);
                }
            }
        } catch (error) {
            console.error("Error in useEffect [map manipulation]:", error);
        }
    }, [
        activeLotes,
        highlightedLote,
        polygonsData,
        polygons,
        bufferedIntersections,
        bufferedLines,
        areasSuperpuestas,
        lines,
        memoizedMarkers,
        shouldFitBounds,
        stretchPoints,
        lineasNoFiltradas,
        polygonProperties,
        linesInit,
        polygonsInit,
        imageOverlayUrlRasterInit,
        imageDataInit
    ]);


    useEffect(() => {
        const fetchAnalisisData = async () => {
            try {
                const response = await fetch(`${API_BASE_PYTHON_SERVICE}mapping/analisis/${userData.userId}`);
                const data = await response.json();
                if (data.status === 'success') {
                    let polygonsToSet = [];
                    let linesToSet = [];
                    let imagesToSet = [];
                    let rasterUrlToSet = null;
                    let boundsToSet = null;  // Para almacenar los bounds de conteo de palmas

                    // Mapeamos las promesas de procesamiento de archivos
                    const fileProcessingPromises = data.data.map(async (analisis) => {
                        const archivos = analisis.archivos;

                        // Descartar análisis sin archivos
                        const tieneArchivos = (
                            (archivos.aplicaciones_aereas && archivos.aplicaciones_aereas.length > 0) ||
                            (archivos.conteo_palma && archivos.conteo_palma.length > 0) ||
                            (archivos.cosecha_mecanica && archivos.cosecha_mecanica.length > 0)
                        );

                        if (!tieneArchivos) return;
                        // Procesar aplicaciones aéreas
                        const aplicacionesAereasPromises = archivos.aplicaciones_aereas?.map(async (file) => {
                            if (file.file_name.includes('_poligonos.geojson')) {
                                const res = await fetch(file.signed_url);
                                const geojson = await res.json();
                                if (Array.isArray(geojson.features)) {
                                    const parsedPolygons = geojson.features.map((feature) => {
                                        const coords = feature.geometry.coordinates[0];
                                        return Array.isArray(coords) ? coords : [];
                                    });
                                    polygonsToSet = [...polygonsToSet, ...parsedPolygons];
                                }
                            } else if (file.file_name.includes('_lineas_filtradas.geojson')) {
                                const res = await fetch(file.signed_url);
                                const geojson = await res.json();
                                if (Array.isArray(geojson.features)) {
                                    const parsedLines = geojson.features.map((feature) => {
                                        const coords = feature.geometry.coordinates;
                                        return coords.map(coord => [coord[1], coord[0]]); // Invertir para [lat, lng]
                                    });
                                    linesToSet = [...linesToSet, ...parsedLines];
                                }
                            }
                        }) || [];

                        // Procesar cosecha mecánica
                        const cosechaMecanicaPromises = archivos.cosecha_mecanica?.map((file) => {
                            if (file.file_name.endsWith('.tiff') || file.file_name.endsWith('.tif')) {
                                rasterUrlToSet = file.signed_url; // Sobrescribe la URL si hay más de un archivo
                            }
                        }) || [];

                        // Procesar conteo de palma y obtener bounds
                        const conteoPalmaPromises = archivos.conteo_palma?.map((file) => {
                            if (file.file_name.endsWith('.jpg') || file.file_name.endsWith('.png')) {
                                imagesToSet = [...imagesToSet, file.signed_url];
                                if (file.bounds) {
                                    boundsToSet = file.bounds;  // Asignar los bounds obtenidos
                                }
                            }
                        }) || [];

                        // Esperar que todos los fetch internos de cada análisis terminen
                        await Promise.all([...aplicacionesAereasPromises, ...cosechaMecanicaPromises, ...conteoPalmaPromises]);
                    });

                    // Esperar que todos los análisis hayan sido procesados
                    await Promise.all(fileProcessingPromises);

                    // Actualizar el estado con bounds de conteo de palmas si está disponible
                    setBoundsPalmas(boundsToSet);

                    // Actualizamos el estado solo una vez por cada categoría
                    setPolygonsInit((prev) => prev ? [...prev, ...polygonsToSet] : [...polygonsToSet]);
                    setLinesInit((prev) => prev ? [...prev, ...linesToSet] : [...linesToSet]);
                    setImageDataInit((prev) => prev ? [...prev, ...imagesToSet] : [...imagesToSet]);
                    if (rasterUrlToSet) {
                        setImageOverlayUrlRasterInit(rasterUrlToSet);
                    }
                } else {
                    console.error('Error al obtener los datos:', data);
                }
            } catch (error) {
                console.error('Error en la llamada al API:', error);
            }
        };

        //fetchAnalisisData();
    }, [userData.userId, setLinesInit, setPolygonsInit, setImageOverlayUrlRasterInit, setImageDataInit]);



    useEffect(() => {
        if (localMapRef.current && memoizedPolygonsInit && memoizedPolygonsInit.length > 0) {
            const map = localMapRef.current;
            processPolygonsInBatches(map, memoizedPolygonsInit, polygonProperties);
        }
    }, [memoizedPolygonsInit, polygonProperties]);

    const processPolygonsInBatches = (map, polygons, polygonProperties) => {
        const batchSize = 1000;
        let index = 0;

        // Crear el popup una vez
        const popup = L.popup({
            closeOnClick: false,  // No cerrar el popup al hacer clic
            autoClose: true,      // Asegurar que solo un pop-up esté abierto a la vez
            autoPan: false         // Ajustar el mapa cuando se abre el popup
        }).setContent('<strong>Análisis:</strong> Aplicaciones Aéreas - Polígonos');

        const processBatch = () => {
            const batch = polygons.slice(index, index + batchSize);

            batch.forEach((polygon, i) => {
                const positions = polygon.map(coord => [coord[1], coord[0]]); // Invertir a [lat, lng]
                const polygonColor = polygonProperties[i]?.color || 'green';

                const polygonLayer = L.polygon(positions, {
                    color: polygonColor,
                    weight: 3,
                }).addTo(map);

                if (index === 0 && i === 0) {
                    popup.setLatLng(positions[0])
                        .openOn(map);
                }
            });

            index += batchSize;
            if (index < polygons.length) {
                requestIdleCallback(processBatch);
            }
        };

        processBatch();
    };


    return (
        <Box position="relative">
            <MapContainer
                center={Array.isArray(mapCenter) && mapCenter.length === 2 ? mapCenter : [0, 0]}
                zoom={typeof mapZoom === 'number' ? mapZoom : 8}
                key={mapKey}
                style={{ height: '65vh', width: '100%' }}
                ref={localMapRef}
                whenReady={(map) => {
                    if (map) {
                        localMapRef.current = map;
                        mapRef.current = map;
                        setInitialBoundsSet(true);
                    }
                }}
            >
                <MapEffect />

                <LayersControl position="topright">
                    {/* Capa principal: Vista satelital sin detalles */}
                    <BaseLayer name="Satellite View (No Details)">
                        <TileLayer
                            url="https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiamRtYW4iLCJhIjoiY20xNGpkN2d0MHlsejJ4cTA1Z3lwdGNhMCJ9.O5UY8q02UPPoCCMrobQ0sA"
                            attribution='&copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                            maxZoom={19}
                            tileSize={512}
                            zoomOffset={-1}
                            updateWhenIdle={true}
                            keepBuffer={3}
                        />
                    </BaseLayer>

                    {/* Capa de calles (Street Map) */}
                    <BaseLayer checked name="Street Map">
                        <TileLayer
                            url="https://api.mapbox.com/styles/v1/mapbox/streets-v12/tiles/256/{z}/{x}/{y}@2x?optimize=true&access_token=pk.eyJ1IjoiamRtYW4iLCJhIjoiY20xNGpkN2d0MHlsejJ4cTA1Z3lwdGNhMCJ9.O5UY8q02UPPoCCMrobQ0sA"
                            attribution='&copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                            maxZoom={19}
                            tileSize={512}
                            zoomOffset={-1}
                            updateWhenIdle={true}
                            keepBuffer={3}
                        />
                    </BaseLayer>

                    {/* Capa de relieve (Terrain RGB) */}
                    <BaseLayer name="Terrain Map">
                        <TileLayer
                            url="https://api.mapbox.com/v4/mapbox.terrain-rgb/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiamRtYW4iLCJhIjoiY20xNGpkN2d0MHlsejJ4cTA1Z3lwdGNhMCJ9.O5UY8q02UPPoCCMrobQ0sA"
                            attribution='&copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                            maxZoom={15}
                            tileSize={256}
                            updateWhenIdle={true}
                            keepBuffer={3}
                        />
                    </BaseLayer>

                    {/* Capa al aire libre (Outdoors) */}
                    <BaseLayer name="Outdoors Map">
                        <TileLayer
                            url="https://api.mapbox.com/styles/v1/mapbox/outdoors-v12/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiamRtYW4iLCJhIjoiY20xNGpkN2d0MHlsejJ4cTA1Z3lwdGNhMCJ9.O5UY8q02UPPoCCMrobQ0sA"
                            attribution='&copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                            maxZoom={19}
                            tileSize={512}
                            zoomOffset={-1}
                            updateWhenIdle={true}
                            keepBuffer={3}
                        />
                    </BaseLayer>

                    {/* Capa satelital con calles (Satellite Streets) */}
                    <BaseLayer name="Satellite Streets">
                        <TileLayer
                            url="https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiamRtYW4iLCJhIjoiY20xNGpkN2d0MHlsejJ4cTA1Z3lwdGNhMCJ9.O5UY8q02UPPoCCMrobQ0sA"
                            attribution='&copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                            maxZoom={19}
                            tileSize={512}
                            zoomOffset={-1}
                            updateWhenIdle={true}
                            keepBuffer={3}
                        />
                    </BaseLayer>

                    {/* Capa ligera (Light Map) */}
                    <BaseLayer name="Light Map">
                        <TileLayer
                            url="https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiamRtYW4iLCJhIjoiY20xNGpkN2d0MHlsejJ4cTA1Z3lwdGNhMCJ9.O5UY8q02UPPoCCMrobQ0sA"
                            attribution='&copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                            maxZoom={19}
                            tileSize={512}
                            zoomOffset={-1}
                            updateWhenIdle={true}
                            keepBuffer={3}
                        />
                    </BaseLayer>
                </LayersControl>

            </MapContainer>
        </Box>
    );

};

export default React.memo(CommonMap);