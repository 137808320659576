export const validateCosechaMecanicaData = (datosAnalisis) => ({
    nombreMaquina: datosAnalisis.nombreMaquina?.valor && datosAnalisis.nombreMaquina.valor !== '',
    areaBrutaCm: datosAnalisis.areaBrutaCm?.valor && datosAnalisis.areaBrutaCm.valor !== 0,
    horaInicio: datosAnalisis.horaInicio?.valor && datosAnalisis.horaInicio.valor !== '',
    horaFin: datosAnalisis.horaFin?.valor && datosAnalisis.horaFin.valor !== '',
    tiempoTotalActividad: datosAnalisis.tiempoTotalActividad?.valor && datosAnalisis.tiempoTotalActividad.valor !== 0,
    consumoCombustible: datosAnalisis.consumoCombustible?.valor && datosAnalisis.consumoCombustible.valor !== 0,
    calidadGps: datosAnalisis.calidadGps?.valor && datosAnalisis.calidadGps.valor !== 0,
    eficiencia: datosAnalisis.eficiencia?.valor && datosAnalisis.eficiencia.valor !== 0,
    promedioVelocidad: datosAnalisis.promedioVelocidad?.valor && datosAnalisis.promedioVelocidad.valor !== 0,
    rpm: datosAnalisis.rpm?.valor && datosAnalisis.rpm.valor !== 0,
    tch: datosAnalisis.tch?.valor && datosAnalisis.tch.valor !== 0,
    tah: datosAnalisis.tah?.valor && datosAnalisis.tah.valor !== 0,
    presionCortadorBase: datosAnalisis.presionCortadorBase?.valor && datosAnalisis.presionCortadorBase.valor !== 0
});
