// tutorialStepsConfig.js
const tutorialStepsConfig = {
    '/mapeo': [
        {
            target: '.analysis-controls',
            content: 'En este apartado debes seleccionar el tipo de análisis que se requiere hacer, debes seleccionar uno para continuar con los siguientes pasos',
        },
        {
            target: '.descargar-plantilla',
            content: 'A continuación descarga y llena el archivo con tus datos para analizar.',
        },
        {
            target: '.subir-zip',
            content: 'Sube el archivo zip con el shapefile que desees analizar.',
        },
        {
            target: '.subir-csv',
            content: 'Sube el archivo en formato excel o csv con los datos previamente ingresados.',
        },
        {
            target: '.realizar-analisis',
            content: 'Presiona el botón de realizar análisis para poder continuar.',
        },

    ],
};

export default tutorialStepsConfig;