import React, { useState, useEffect } from 'react';
import { Grid, Tabs, Tab, Box, Collapse, Button, Snackbar, Alert } from '@mui/material';
import { AiOutlineSetting, AiOutlineDashboard } from 'react-icons/ai';
import axios from 'axios';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { API_BASE_URL } from "../../utils/config";
import {
    SectionCard,
    SectionTitle,
    ExpandIcon,
    InputsContainer,
    StyledTextField
} from './IndicatorConfigStyle';
import analysisConfig from "../../utils/analysisConfig";

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const IndicatorConfig = () => {
    const [indicators, setIndicators] = useState({});
    const [selectedTab, setSelectedTab] = useState(0);
    const [expandedSection, setExpandedSection] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        const fetchAllConfigs = async () => {
            const allConfigs = {};
            for (const analysisType in analysisConfig) {
                try {
                    const response = await axios.get(`${API_BASE_URL}configuration/limites/${analysisType.toLowerCase()}`);

                    // Mapeando los datos recibidos para asegurarnos de que se estructuran correctamente
                    allConfigs[analysisType] = {
                        cardData: response.data.limites.reduce((acc, limit) => {
                            acc[limit.indicador] = {
                                max: limit.max !== null ? limit.max : '',
                                min: limit.min !== null ? limit.min : '',
                                title: limit.indicador
                            };
                            return acc;
                        }, {})
                    };
                } catch (error) {
                    console.error(`Error al obtener la configuración para ${analysisType}:`, error);
                    allConfigs[analysisType] = analysisConfig[analysisType].initialIndicators;
                }
            }
            setIndicators(allConfigs);
        };

        fetchAllConfigs();
    }, []);

    const handleInputChange = (section, analysisType, key, field, value) => {
        setIndicators({
            ...indicators,
            [analysisType]: {
                ...indicators[analysisType],
                [section]: {
                    ...indicators[analysisType][section],
                    [key]: {
                        ...indicators[analysisType][section][key],
                        [field]: value || '', // Asignar '' si el valor es null o vacío
                    },
                },
            },
        });
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        setExpandedSection(null); // Reiniciar la sección expandida al cambiar de tab
    };

    const toggleSection = (section) => {
        setExpandedSection(expandedSection === section ? null : section);
    };

    const handleSaveClick = async () => {
        try {
            // Recolectar todos los límites a enviar al backend
            for (const analysisType in indicators) {
                const limites = Object.keys(indicators[analysisType]?.cardData || {}).map(key => ({
                    indicador: key,
                    max: parseFloat(indicators[analysisType].cardData[key].max) || null,
                    min: parseFloat(indicators[analysisType].cardData[key].min) || null,
                }));

                await axios.post(`${API_BASE_URL}configuration/limites`, {
                    tipo_analisis: analysisType.toLowerCase(),
                    limites
                });

                setSnackbarMessage('Configuración guardada exitosamente');
                setSnackbarSeverity('success');
                setOpenSnackbar(true);
            }
        } catch (error) {
            console.error('Error al guardar la configuración:', error);
            setSnackbarMessage('Error al guardar la configuración');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    };

    const renderInputsForSection = (section) => {
        const analysisType = section;
        return (
            <InputsContainer>
                <Grid container spacing={2}>
                    {Object.keys(indicators[analysisType]?.cardData || {}).map(key => (
                        <Grid item xs={12} sm={6} md={4} key={key}>
                            <StyledTextField
                                label={`${indicators[analysisType].cardData[key].title} - Máximo`}
                                variant="outlined"
                                value={indicators[analysisType].cardData[key].max || ''} // Asignar '' si max es null o undefined
                                onChange={(e) => handleInputChange('cardData', analysisType, key, 'max', e.target.value)}
                            />
                            <StyledTextField
                                label={`${indicators[analysisType].cardData[key].title} - Mínimo`}
                                variant="outlined"
                                value={indicators[analysisType].cardData[key].min || ''} // Asignar '' si min es null o undefined
                                onChange={(e) => handleInputChange('cardData', analysisType, key, 'min', e.target.value)}
                            />
                        </Grid>
                    ))}
                </Grid>
            </InputsContainer>
        );
    };

    const renderAnalysisSections = () => {
        return Object.keys(analysisConfig).map((analysisType) => (
            <Box key={analysisType}>
                <SectionCard onClick={() => toggleSection(analysisType)}>
                    <SectionTitle>
                        {analysisType.replace('_', ' ')}
                    </SectionTitle>
                    <ExpandIcon>
                        {expandedSection === analysisType ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ExpandIcon>
                </SectionCard>
                <Collapse in={expandedSection === analysisType}>
                    {renderInputsForSection(analysisType)}
                </Collapse>
            </Box>
        ));
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Tabs de configuración" centered>
                <Tab label="Dashboard indicadores" icon={<AiOutlineSetting />} />
                <Tab label="Mapeo" icon={<AiOutlineDashboard />} />
            </Tabs>
            <TabPanel value={selectedTab} index={0}>
                {renderAnalysisSections()}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveClick}
                    style={{ marginTop: '20px' }}
                >
                    Guardar Configuración
                </Button>
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
                {renderAnalysisSections()}
            </TabPanel>

            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
                <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default IndicatorConfig;