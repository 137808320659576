import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText, ListItemButton, InputBase, Pagination } from '@mui/material';
import { Search } from '@mui/icons-material';
import axios from 'axios';
import { API_BASE_URL_SECURITY } from "../../../../utils/config";
import { toast } from 'react-toastify';

const CompanyForm = ({ isSidebarOpen }) => {
    const [nombreEmpresa, setNombreEmpresa] = useState('');
    const [direccion, setDireccion] = useState('');
    const [limiteHectareas, setLimiteHectareas] = useState('');
    const [empresas, setEmpresas] = useState([]);
    const [editingCompanyId, setEditingCompanyId] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogSuccess, setDialogSuccess] = useState(false);

    // Paginación y búsqueda
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);  // Empresas por página
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchEmpresas = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL_SECURITY}empresas`);
                if (response.data.length === 0) {
                    toast.info("No se encontraron empresas");
                }
                setEmpresas(response.data);
            } catch (error) {
                toast.error("Error al obtener empresas");
            }
        };

        fetchEmpresas();
    }, []);

    const handleSubmit = async () => {
        if (!nombreEmpresa || !direccion || !limiteHectareas) {
            toast.error("Todos los campos son obligatorios");
            return;
        }

        const nuevaEmpresa = { nombre: nombreEmpresa, direccion, limiteHectareas };

        try {
            if (editingCompanyId) {
                await axios.put(`${API_BASE_URL_SECURITY}empresas/${editingCompanyId}`, nuevaEmpresa);
                setDialogMessage("Empresa actualizada exitosamente");
            } else {
                await axios.post(`${API_BASE_URL_SECURITY}empresas`, nuevaEmpresa);
                setDialogMessage("Empresa creada exitosamente");
            }
            setDialogSuccess(true);
            setDialogOpen(true);
            clearForm();
        } catch (error) {
            setDialogMessage("Error al procesar la empresa.");
            setDialogSuccess(false);
            setDialogOpen(true);
        }
    };

    const clearForm = () => {
        setNombreEmpresa('');
        setDireccion('');
        setLimiteHectareas('');
        setEditingCompanyId(null);
    };

    const handleEditClick = (empresa) => {
        setEditingCompanyId(empresa._id);
        setNombreEmpresa(empresa.nombre);
        setDireccion(empresa.direccion);
        setLimiteHectareas(empresa.limiteHectareas);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reiniciar a la primera página
    };

    // Filtrar empresas basado en la búsqueda
    const filteredEmpresas = empresas.filter(empresa =>
        empresa.nombre.toLowerCase().includes(searchQuery.toLowerCase()) ||
        empresa.direccion.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Obtener las empresas de la página actual
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentEmpresas = filteredEmpresas.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <Box sx={{ display: 'flex', gap: '24px', padding: '24px', marginTop: '25px', marginLeft: isSidebarOpen ? '250px' : '160px', transition: 'margin-left 0.3s ease-in-out' }}>
            {/* Formulario */}
            <Box sx={{ flex: 1 }}>
                <Typography variant="h4" mb={2}>{editingCompanyId ? 'Editar Empresa' : 'Agregar Empresa'}</Typography>

                <TextField fullWidth label="Nombre de la Empresa" margin="normal" variant="outlined" value={nombreEmpresa} onChange={(e) => setNombreEmpresa(e.target.value)} required />
                <TextField fullWidth label="Dirección" margin="normal" variant="outlined" value={direccion} onChange={(e) => setDireccion(e.target.value)} required />
                <TextField fullWidth label="Límite de Hectáreas" margin="normal" variant="outlined" type="number" value={limiteHectareas} onChange={(e) => setLimiteHectareas(e.target.value)} required />

                <Button variant="contained" sx={{ backgroundColor: '#2C3E50', color: '#fff', marginTop: '16px', width: '100%', padding: '12px' }} onClick={handleSubmit}>
                    {editingCompanyId ? 'Actualizar Empresa' : 'Crear Empresa'}
                </Button>

                {editingCompanyId && (
                    <Button variant="outlined" sx={{ marginTop: '16px', width: '100%', padding: '12px' }} onClick={clearForm}>
                        Cancelar Edición
                    </Button>
                )}
            </Box>

            {/* Lista de Empresas */}
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', paddingBottom: '16px' }}>
                    <InputBase
                        placeholder="Buscar empresa..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        sx={{ flex: 1, padding: '6px 12px', border: '1px solid #ccc', borderRadius: '4px' }}
                        startAdornment={<Search sx={{ marginRight: '8px' }} />}
                    />
                </Box>

                <List sx={{ height: '300px', overflowY: 'auto', border: '1px solid #ccc', borderRadius: '8px' }}>
                    {currentEmpresas.map((empresa) => (
                        <ListItem key={empresa._id} disablePadding>
                            <ListItemButton onClick={() => handleEditClick(empresa)}>
                                <ListItemText primary={empresa.nombre} secondary={empresa.direccion} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>

                {/* Paginación */}
                <Pagination
                    count={Math.ceil(filteredEmpresas.length / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    sx={{ alignSelf: 'center' }}
                />
            </Box>

            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>{dialogSuccess ? "Éxito" : "Error"}</DialogTitle>
                <DialogContent><Typography variant="body1">{dialogMessage}</Typography></DialogContent>
                <DialogActions><Button onClick={handleDialogClose} color="primary">Cerrar</Button></DialogActions>
            </Dialog>
        </Box>
    );
};

export default CompanyForm;
