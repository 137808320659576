import React, { useEffect, useRef, useState } from 'react';
import Mapping from "../../Mapping/Mapping";
import AerialApplications from "../../Aplicaciones Areas/AerialApplications";
import CommonMap from "../../../components/CommonMap/CommonMap";
import PalmsCount from "../../../Mappings/PalmsCount";

const MapSection = ({
                        selectedFile,
                        selectedAnalysisType,
                        datosMapeo,
                        selectedZipFile,
                        processingFinished,
                        ultimoAnalisis,
                        nombreAnalisis,
                        idAnalisisBash,
                        activarEdicionInteractiva,
                        setAreaNetaCm,
                        setAreaBrutaCm,
                        setDiferenciaDeAreaCm,
                        setPorcentajeAreaPilotoCm,
                        setPorcentajeAreaAutoTrackerCm,
                        setPorcentajeModoCortadorBaseCm,
                        setEficienciaCm,
                        setAreaSobreAplicada,
                        setAreaAplicada,
                        setPorcentajeVariacion,
                        setAreaNoAplicada,
                        setAreaSobreCalles,
                        setAreaFueraDeLote,
                        setPromedioVelocidad,
                        setPromedioAltura,
                        activeLotes = [],
                        highlightedLote,
                        polygonsData = [],
                        onLeaveLote,
                        onSelectLote,
                        onHoverLote,
                        closeFilterDialog,
                        isFilterDialogOpen,
                        setImgLaflet,
                        imageUrl = '',
                        northWestCoords,
                        southEastCoords,
                        isAnalysisPerformed,
                        mapRef,
                        polygonsLayerRef,
                        setLabelsAndColors,
                        setOnAreasCalculatedLoader,
                        setDosisTotalRealAplicada,
                        setDiferenciaDeAreaApsKml
                    }) => {
    const [nombreAnalisisMapeo, setNombreAnalisisMapeo] = useState('');
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [commonMapElement, setCommonMapElement] = useState(null); // Guardar la instancia de CommonMap

    useEffect(() => {
        if (isFirstRender) {
            setIsFirstRender(false);
            // Renderizar y guardar la instancia de CommonMap
            setCommonMapElement(
                <CommonMap
                    mapRef={mapRef}
                    activeLotes={activeLotes}
                    highlightedLote={highlightedLote}
                    polygonsData={polygonsData}
                    onLeaveLote={onLeaveLote}
                    onSelectLote={onSelectLote}
                    onHoverLote={onHoverLote}
                    setImgLaflet={setImgLaflet}
                    polygonsLayerRef={polygonsLayerRef}
                />
            );
        }
    }, [isFirstRender]); // Este efecto solo se ejecuta una vez al inicio

    useEffect(() => {
        try {
            setNombreAnalisisMapeo(nombreAnalisis(idAnalisisBash));
        } catch (error) {
            console.error("Error en useEffect nombreAnalisisMapeo:", error);
        }
    }, [idAnalisisBash]);

    const handleAreaCalculation = (areaDentroDePoligono, areaFueraDePoligono, diferenciaDeArea, areaSobreCalles, areaFueraDeLote, dosisTotalRealAplicada) => {
        try {
            setAreaNetaCm(`${areaFueraDePoligono.toFixed(2)} H`);
            setAreaBrutaCm(`${areaDentroDePoligono.toFixed(2)} H`);
            setDiferenciaDeAreaCm(`${diferenciaDeArea.toFixed(2)} H`);
            setAreaSobreCalles(`${areaSobreCalles.toFixed(2)} H`);
            setAreaFueraDeLote(`${areaFueraDeLote.toFixed(2)} H`);
            setDosisTotalRealAplicada(`${dosisTotalRealAplicada.toFixed(2)} L/Ha`);
        } catch (error) {
            console.error("Error en handleAreaCalculation:", error);
        }
    };

    const handlePercentageCalculation = (autoTracket, autoPilot, modoCorteBase, totalEfficiency) => {
        try {
            setPorcentajeAreaPilotoCm(`${autoPilot.toFixed(2)}%`);
            setPorcentajeAreaAutoTrackerCm(`${autoTracket.toFixed(2)}%`);
            setPorcentajeModoCortadorBaseCm(`${modoCorteBase.toFixed(2)}%`);
            setEficienciaCm(`${totalEfficiency.toFixed(2)} Ha/Hora`);
        } catch (error) {
            console.error("Error en handlePercentageCalculation:", error);
        }
    };

    const handleAreasCalculated = (areas) => {
        try {
            console.log("ESTAS SON LAS ÁREAS: ", areas);
            setAreaSobreAplicada(areas.areaSobreAplicada || 0);
            setAreaAplicada(areas.areaAplicada || 0);
            setPorcentajeVariacion(`${areas.porcentajeDeVariacion || 0}%`);
            setAreaNoAplicada(areas.areaNoAplicada || 0);
            setAreaSobreCalles(areas.areaAplicadaEnCalles);
            setAreaFueraDeLote(areas.areaAplicadaFueraDelLote);
            setDiferenciaDeAreaApsKml(areas.dosisTotalDiferencia || 0);
            setDosisTotalRealAplicada(areas.dosisTotalRealAplicada || 0);
        } catch (error) {
            console.error("Error en handleAreasCalculated:", error);
        }
    };

    const handlePromediosCalculados = (promedios) => {
        try {
            setPromedioVelocidad(promedios.promedioVelocidad || 0);
            setPromedioAltura(promedios.promedioAltura || 0);
        } catch (error) {
            console.error("Error en handlePromediosCalculados:", error);
        }
    };

    return (
        <section className="map-section">
            {isAnalysisPerformed && selectedFile && selectedAnalysisType === 'COSECHA_MECANICA' ? (
                <Mapping
                    csvData={datosMapeo}
                    zipFile={selectedZipFile}
                    progressFinish={processingFinished}
                    idAnalisis={ultimoAnalisis}
                    tipoAnalisis={nombreAnalisisMapeo}
                    onAreaCalculated={handleAreaCalculation}
                    percentageAutoPilot={handlePercentageCalculation}
                    activeLotes={activeLotes}
                    highlightedLote={highlightedLote}
                    polygonsData={polygonsData}
                    onLeaveLote={onLeaveLote}
                    onSelectLote={onSelectLote}
                    onHoverLote={onHoverLote}
                    closeFilterDialog={closeFilterDialog}
                    isFilterDialogOpen={isFilterDialogOpen}
                    setImgLaflet={setImgLaflet}
                    mapRef={mapRef}
                    polygonsLayerRef={polygonsLayerRef}
                    setLabelsAndColors={setLabelsAndColors}
                />
            ) : isAnalysisPerformed && selectedZipFile && selectedFile && selectedAnalysisType === 'APLICACIONES_AEREAS' ? (
                <AerialApplications
                    csvData={datosMapeo}
                    zipFile={selectedZipFile}
                    progressFinish={processingFinished}
                    idAnalisis={ultimoAnalisis}
                    tipoAnalisis={nombreAnalisis(idAnalisisBash)}
                    onAreasCalculated={handleAreasCalculated}
                    onPromediosCalculated={handlePromediosCalculados}
                    activarEdicionInteractiva={activarEdicionInteractiva}
                    activeLotes={activeLotes}
                    highlightedLote={highlightedLote}
                    polygonsData={polygonsData}
                    onLeaveLote={onLeaveLote}
                    onSelectLote={onSelectLote}
                    onHoverLote={onHoverLote}
                    closeFilterDialog={closeFilterDialog}
                    isFilterDialogOpen={isFilterDialogOpen}
                    setImgLaflet={setImgLaflet}
                    mapRef={mapRef}
                    polygonsLayerRef={polygonsLayerRef}
                    setLabelsAndColors={setLabelsAndColors}
                    setOnAreasCalculatedLoader={setOnAreasCalculatedLoader}
                />
            ) : isAnalysisPerformed && selectedZipFile && selectedAnalysisType === 'CONTEO_PALMA' ? (
                <PalmsCount
                    imageUrl={imageUrl}
                    activeLotes={activeLotes}
                    polygonsData={polygonsData}
                    northWestCoords={northWestCoords}
                    southEastCoords={southEastCoords}
                    setImgLaflet={setImgLaflet}
                    onSelectLote={onSelectLote}
                    onHoverLote={onHoverLote}
                    mapRef={mapRef}
                    polygonsLayerRef={polygonsLayerRef}
                    setLabelsAndColors={setLabelsAndColors}

                />
            ) : commonMapElement ? (
                <>
                    {commonMapElement}
                </>
            ) : null}
        </section>
    );
};

export default MapSection;
