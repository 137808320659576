// src/analysis/cosechaMecanica/cosechaMecanicaProcess.jsx

import axios from 'axios';
import pako from 'pako';
import { toast } from 'react-toastify';
import { API_BASE_URL, API_BASE_PYTHON_SERVICE } from "../../utils/config";

// Función principal para ejecutar el proceso de Cosecha Mecánica con archivo
export const ejecutarProcesoCosechaMecanica = async ({
                                                         selectedFile,
                                                         idMax,
                                                         setProcessingFinished,
                                                         socketSessionID,
                                                         setShowProgressBar,
                                                         setProgress,
                                                         setTitleLoader,
                                                         setLoadingProgress
                                                     }) => {
    // Inicialización de la barra de progreso y mensajes de carga
    setProgress(0);
    setTitleLoader("Inicio de carga de datos de Cosecha Mecánica...");
    setShowProgressBar(true);

    try {
        // Leer el contenido del archivo como un ArrayBuffer
        const fileBuffer = await selectedFile.arrayBuffer();

        // Comprimir el contenido del archivo usando pako
        const compressedData = pako.gzip(new Uint8Array(fileBuffer), { level: 9 });

        // Crear un Blob con los datos comprimidos para el FormData
        const compressedFile = new Blob([compressedData], { type: 'application/gzip' });

        // Crear el FormData y agregar el archivo comprimido
        const formData = new FormData();
        formData.append('file', compressedFile, `${selectedFile.name}.gz`);
        formData.append('collection_name', 'cosecha_mecanica');
        formData.append('session_id', socketSessionID);

        // Enviar la solicitud para insertar el archivo comprimido
        const insertResponse = await axios.post(`${API_BASE_PYTHON_SERVICE}mongo/insert_csv`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });

        // Verificar si la respuesta indica un error
        if (insertResponse.data.status === "error") {
            throw new Error(insertResponse.data.message);
        }

        setProgress(20);
        setTitleLoader("Datos cargados, procesando análisis...");

        // Ahora hacemos una única petición para procesar todos los datos
        const formDataAnalisis = new FormData();
        formDataAnalisis.append('id_analisis', idMax);
        formDataAnalisis.append('coleccion', 'cosecha_mecanica');
        formDataAnalisis.append('session_id', socketSessionID);
        formDataAnalisis.append('generar_lineas', false);

        const analisisResponse = await axios.post(`${API_BASE_PYTHON_SERVICE}mapping/cosecha_mecanica`, formDataAnalisis, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });

        // Verificar si la respuesta indica un error
        if (analisisResponse.data.status === "error") {
            throw new Error(analisisResponse.data.message);
        }

        setProgress(80);
        setTitleLoader("Proceso finalizado, cargando datos...");

        // Mostrar notificación de éxito
        setProcessingFinished(true);
        toast.success('Datos cargados exitosamente.', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        setProgress(100);
        setTitleLoader("Proceso completado.");
        setShowProgressBar(false);

    } catch (error) {
        console.error("Error durante el proceso de Cosecha Mecánica:", error);
        const errorMessage = error.message || 'Ocurrió un error, consulta con tu administrador.';
        toast.error(errorMessage, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
        setShowProgressBar(false);
    }
};

// Función auxiliar para obtener el número de líneas del archivo con manejo de errores
export const obtenerNumeroLineasArchivo = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                const content = e.target.result;
                const lines = content.split(/\r\n|\n/).length;
                resolve(lines);
            } catch (error) {
                console.error("Error al contar las líneas del archivo:", error);
                toast.error('Ocurrió un error, consulta con tu administrador.', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                reject(error);
            }
        };
        reader.onerror = (error) => {
            console.error("Error al leer el archivo:", error);
            toast.error('Ocurrió un error, consulta con tu administrador.', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            reject(error);
        };
        reader.readAsText(file);
    });
};

// Función para ejecutar el proceso de Cosecha Mecánica sin archivo
export const ejecutarProcesoSinArchivoCosechaMecanica = async ({
                                                                   idMax,
                                                                   idUsuario,
                                                                   setProcessingFinished,
                                                                   setShowProgressBar
                                                               }) => {
    const formData = new FormData();
    formData.append('esPrimeraIteracion', 'true');

    setShowProgressBar(true);

    try {
        const response = await axios.post(`${API_BASE_URL}dashboard/execBash/${idUsuario}/2/${idMax}/0/ok/0`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        if (response.data.status === "error") {
            throw new Error(response.data.message);
        }

        // Mostrar notificación de éxito
        toast.success('Proceso ejecutado exitosamente.', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        setProcessingFinished(true);
        setShowProgressBar(false);

    } catch (error) {
        console.error("Error al procesar el lote sin archivo de Cosecha Mecánica:", error);
        const errorMessage = error.message || 'Ocurrió un error, consulta con tu administrador.';
        toast.error(errorMessage, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
        setShowProgressBar(false);
    }
};
