// ActivitiesComponentStyle.js

import { styled, useTheme } from '@mui/system';
import { List, ListItem } from '@mui/material';
import { darken } from '@mui/material/styles';

export const HorizontalActivityList = styled(List)(({ isSidebarOpen }) => ({
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#fff',
    padding: '8px 16px',
    position: 'fixed',
    top: '120px',
    left: isSidebarOpen ? '250px' : '160px',
    right: 0,
    zIndex: 999,
    transition: 'left 0.5s ease, padding 0.5s ease',
    height: '100px',
    boxSizing: 'border-box',
    gap: '16px',
    overflowX: 'hidden',
}));

export const HorizontalActivityItem = styled(ListItem)(({ bgColor }) => ({
    flex: '1 1 0',
    borderRadius: '8px',
    boxSizing: 'border-box',
    backgroundColor: bgColor,
    '&:hover': {
        backgroundColor: darken(bgColor, 0.1),
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px',
    minWidth: '0',
    textAlign: 'center',
}));

export const SelectedHorizontalActivityItem = styled(ListItem)(({ bgColor }) => ({
    flex: '1 1 0',
    borderRadius: '8px',
    backgroundColor: darken(bgColor, 0.2),
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px',
    minWidth: '0',
    textAlign: 'center',
    color: '#ffffff',

    '& .MuiListItemIcon-root': {
        color: '#ffffff',
    },
    '& .MuiListItemText-primary': {
        color: '#ffffff',
    },
}));
